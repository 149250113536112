import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import EditProfile from "../component/RegisterComponent/EditProfile/EditProfile";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class EditProfilePage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Edit Profile - The Soul Company"
          description="Edit user profile of The Soul Company"
          keywords="user profile edit, user profile update"
          og_title="Edit Profile - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Edit user profile of The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <EditProfile />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default EditProfilePage;
