const Blogsdata = [
	{
		id: 1,
		imageUrl: require("../images/blogs/blog1.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmalltitle: "Food",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 2,
		imageUrl: require("../images/blogs/blog2.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmalltitle: "Food",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 3,
		imageUrl: require("../images/blogs/blog3.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmalltitle: "Food",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 4,
		imageUrl: require("../images/blogs/blog4.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmalltitle: "Art",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 5,
		imageUrl: require("../images/blogs/blog5.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmalltitle: "Art",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 6,
		imageUrl: require("../images/blogs/blog6.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith. ",
		blogsmalltitle: "Art",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 7,
		imageUrl: require("../images/blogs/blog7.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmalltitle: "Wildlife",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 8,
		imageUrl: require("../images/blogs/blog8.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmalltitle: "Wildlife",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	},
	{
		id: 9,
		imageUrl: require("../images/blogs/blog8.jpg"),
		title:
			"Lorem ipsum of our church is to bring about God’s existence and faith. ",
		blogsmalltitle: "Wildlife",
		blogdate: "31-Oct-2019",
		blogby: "Lorem ipsum ",
		blogcomment: "15 Comments"
	}
];

export default Blogsdata;
