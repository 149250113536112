import { createStore } from "redux";
import devToolsEnhancer from "remote-redux-devtools";
import axios from "axios";
import { APIbaseURL } from "./config";
import { trackPromise } from "react-promise-tracker";

export const action_tps = {
  SET_BOOK_ORDER: "SET_BOOK_ORDER",
  CLEAR_BOOK_ORDER: "CLEAR_BOOK_ORDER",
  LOCAL_TO_STORE: "LOCAL_TO_STORE"
};

let initialState = {
  book_order: {
    user_id: "",
    user_info: "",
    experience_id: "",
    route_url: "",
    experience_name: "",
    exp_short_desc: "",
    selectedGuests: { id: "0", name: "Guests" },
    no_of_person: "",
    book_dates: [],
    price: "",
    group_price: "",
    total_price: "",
    price_request: "",
    four_group_price: ""
  }
};

const reducer = (state = initialState, action) => {
  let { book_order } = state;
  switch (action.type) {
    case action_tps.SET_BOOK_ORDER:
      return { book_order: { ...book_order, ...action.payload } };
    case action_tps.CLEAR_BOOK_ORDER:
      return { ...initialState };
    case action_tps.LOCAL_TO_STORE:
      let local_tsc = localStorage.getItem("tsc_data")
        ? JSON.parse(localStorage.getItem("tsc_data"))
        : {};
      return { book_order: { ...book_order, ...local_tsc.book_order } };
    default:
      return { ...initialState };
  }
};

const store = createStore(reducer, devToolsEnhancer());
store.dispatch({ type: action_tps.LOCAL_TO_STORE });
export default store;

export const actions = {
  setBookOrderStore,
  clearBookOrderStore,
  setLocalStorageBookOrder,
  clearLocalStorageBookOrder
};

function setBookOrderStore(dispatch, payload) {
  trackPromise(
    axios
      .get(APIbaseURL + "/get_user_details/" + payload.user_id)
      .then(response => {
        const { data } = response;
        switch (data.code) {
          case 200:
            payload.user_info = data.data[0];
            dispatch({ type: action_tps.SET_BOOK_ORDER, payload });
            break;
          case 400:
            console.log("Book_order_store User profile 400: ", data.message);
            break;
          default:
            console.log(
              "Default block in book_order_store User profile: ",
              data.message
            );
        }
      })
      .catch(function(error) {
        console.error("Experiences List error: ", error);
      })
  );
}

function clearBookOrderStore(dispatch) {
  dispatch({ type: action_tps.CLEAR_BOOK_ORDER });
}

function getLocalTSCBookOrder() {
  let local_tsc = localStorage.getItem("tsc_data")
    ? JSON.parse(localStorage.getItem("tsc_data"))
    : {};
  local_tsc["book_order"] = local_tsc.book_order || {};
  return local_tsc;
}

function setLocalTSCBookOrder(local_tsc, dispatch) {
  localStorage.setItem("tsc_data", JSON.stringify(local_tsc));
  dispatch({ type: action_tps.LOCAL_TO_STORE });
}

function setLocalStorageBookOrder(dispatch, payload) {
  let local_tsc = getLocalTSCBookOrder();
  local_tsc.book_order = { ...local_tsc.book_order, ...payload };
  setLocalTSCBookOrder(local_tsc, dispatch);
}

function clearLocalStorageBookOrder(dispatch) {
  let local_tsc = getLocalTSCBookOrder();
  local_tsc.book_order = { ...initialState.book_order };
  setLocalTSCBookOrder(local_tsc, dispatch);
}
