import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import "./PlanExperience.css";
import { APIbaseURL } from "../../../stores/config";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

const GROUP_SIZE = 50;

class RegisterComponent extends Component {
  constructor() {
    super();
    this.initialState = {
      fname: {
        value: "",
        validate: true,
        isValid: false,
        error: "Please provide First Name"
      },
      lname: {
        value: "",
        validate: true,
        isValid: false,
        error: "Please provide Last Name"
      },
      email: {
        value: "",
        validate: true,
        isValid: false,
        error: "Please provide Email"
      },
      mobile: {
        value: "",
        validate: true,
        isValid: false,
        error: "Please provide Mobile Number"
      },
      verticals: {
        list: [
          { id: "food", name: "Food", isChecked: false },
          { id: "art", name: "Art", isChecked: false },
          { id: "heritage", name: "Heritage", isChecked: false },
          { id: "wellness", name: "Wellness & Spirituality", isChecked: false }
        ],
        selected: [],
        validate: true,
        isValid: false,
        error: "Please select at least one item"
      },
      city: {
        list: [],
        selected: { id: "0", name: "Choose your city" },
        validate: true,
        isValid: false,
        error: "Please select an option"
      },
      otherCity: {
        value: "",
        validate: true,
        isValid: false,
        error: "City name is required"
      },
      timeAvailable: {
        list: [
          { id: "couple_hrs", name: "A couple of hours", isChecked: false },
          { id: "half_day", name: "Half day", isChecked: false },
          { id: "full_day", name: "Full day", isChecked: false },
          { id: "more_days", name: "More than a few days", isChecked: false }
        ],
        selected: [],
        validate: true,
        isValid: false,
        error: "Please select at least one item"
      },
      groupSize: {
        list: this.generateGroupSizeList(),
        selected: { id: "0", name: "Select group size" },
        validate: true,
        isValid: false,
        error: "Please select an option"
      },
      budget_sense: {
        value: "",
        validate: true,
        isValid: false,
        error: "Field is required"
      },
      request_expectation: {
        value: "",
        validate: true,
        isValid: true,
        error: "Max 150 characters allowed"
      },
      form: {
        isSubmitted: false,
        isValid: false,
        error: "Please populate required fields marked by *"
      }
    };
    this.state = JSON.parse(JSON.stringify(this.initialState));
    this.handleChange = this.handleChange.bind(this);
    this.onCheckboxChanged = this.onCheckboxChanged.bind(this);
    this.dropdownSelected = this.dropdownSelected.bind(this);
    this.formSubmitted = this.formSubmitted.bind(this);
  }

  componentDidMount() {
    this.getCityList();
  }

  getCityList() {
    trackPromise(
      axios
        .get(APIbaseURL + "/get_experiences_cities")
        .then(response => {
          switch (response.data.code) {
            case 200:
              this.setState({
                city: {
                  ...this.state.city,
                  list: [
                    ...response.data.data,
                    { id: "-1", name: "Other City" }
                  ]
                },
                form: { ...this.state.form, isSubmitted: false }
              });
              break;
            case 400:
              this.setState({
                form: {
                  ...this.state.form,
                  isSubmitted: true,
                  error: response.data.data
                }
              });
              break;
            default:
              console.log("Default case in Get city list");
          }
        })
        .catch(function(error) {
          console.error("Get city list error: ", error);
        })
    );
  }

  generateGroupSizeList() {
    let groupSizeArr = [];
    for (let i = 0; i < GROUP_SIZE; i++) {
      groupSizeArr.push({ id: i + 1, name: `${i + 1}` });
    }
    return groupSizeArr;
  }

  handleChange(e, field) {
    let key = this.state[field];
    this.setState({ [field]: { ...key, value: e.target.value } }, () => {
      if (key.validate) this.validateField(field);
    });
  }

  validateField(field) {
    let key = this.state[field];
    if (key.value) {
      switch (field) {
        case "email":
          if (key.value.length < 255) {
            if (key.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
              this.setState({ [field]: { ...key, isValid: true, error: "" } });
            } else {
              this.setState({
                [field]: {
                  ...key,
                  isValid: false,
                  error: "Please enter valid email ID"
                }
              });
            }
          } else {
            this.setState({
              [field]: {
                ...key,
                isValid: false,
                error: "Max 255 characters allowed"
              }
            });
          }
          break;
        case "mobile":
          if (key.value.match(/^[0-9\b]+$/)) {
            if (key.value.length > 9 && key.value.length < 11) {
              this.setState({ [field]: { ...key, isValid: true, error: "" } });
            } else {
              this.setState({
                [field]: {
                  ...key,
                  isValid: false,
                  error: "Enter 10 digits Mobile Number"
                }
              });
            }
          } else {
            this.setState({
              [field]: {
                ...key,
                isValid: false,
                error: "Only numbers allowed"
              }
            });
          }
          break;
        case "budget_sense":
          if (key.value.length <= 150) {
            this.setState({ [field]: { ...key, isValid: true, error: "" } });
          } else {
            this.setState({
              [field]: {
                ...key,
                isValid: false,
                error: "Max 150 characters allowed"
              }
            });
          }
          break;
        case "request_expectation":
          if (key.value.length <= 150) {
            this.setState({ [field]: { ...key, isValid: true, error: "" } });
          } else {
            this.setState({
              [field]: {
                ...key,
                isValid: false,
                error: this.initialState.request_expectation.error
              }
            });
          }
          break;
        default:
          this.setState({ [field]: { ...key, isValid: true, error: "" } });
      }
    } else {
      switch (field) {
        case "request_expectation":
          break;
        default:
          this.setState({
            [field]: { ...key, isValid: false, error: "No blank allowed" }
          });
      }
    }
  }

  onCheckboxChanged(e, field) {
    let key = this.state[field];
    let chkIndx = null;
    key.list.forEach(item => {
      if (item.id === e.target.value) {
        item.isChecked = e.target.checked;
        chkIndx = key.selected.indexOf(item.id);
        if (chkIndx === -1) key.selected.push(item.id);
        else key.selected.splice(chkIndx, 1);
      }
      return item;
    });
    if (key.selected.length) {
      key.isValid = true;
      key.error = "";
    } else {
      key.isValid = false;
      key.error = this.initialState.verticals.error;
    }
    this.setState({ field: { ...field, ...key } });
  }

  dropdownSelected(selected, field) {
    let selectedObj = JSON.parse(selected);
    let key = this.state[field];
    key.selected = selectedObj;
    key.isValid = selectedObj.id === "-1" ? false : true;
    key.error = selectedObj.id === "-1" ? "" : this.initialState.city.error;
    this.setState({
      field: { ...field, ...key }
    });
  }

  formSubmitted(e) {
    e.preventDefault();
    this.setState({ form: { ...this.state.form, isSubmitted: true } });
    if (this.isFormValid()) {
      this.sendRequest();
    }
  }

  isFormValid() {
    const {
      fname,
      lname,
      email,
      mobile,
      verticals,
      city,
      otherCity,
      timeAvailable,
      groupSize,
      budget_sense,
      request_expectation,
      form
    } = this.state;
    let isValid =
      fname.isValid &&
      lname.isValid &&
      email.isValid &&
      mobile.isValid &&
      verticals.isValid &&
      (city.isValid || otherCity.isValid) &&
      timeAvailable.isValid &&
      groupSize.isValid &&
      budget_sense.isValid &&
      request_expectation.isValid;
    if (isValid) this.setState({ ...form, isValid, error: "" });
    else
      this.setState({ ...form, isValid, error: this.initialState.form.error });
    return isValid;
  }

  sendRequest() {
    let payload = {
      first_name: this.state.fname.value,
      last_name: this.state.lname.value,
      email_id: this.state.email.value,
      mobile_no: this.state.mobile.value,
      liked_experiences: this.state.verticals.selected.join(","),
      city_selected:
        this.state.city.selected.id === "-1"
          ? this.state.otherCity.value
          : this.state.city.selected.name,
      time_selected: this.state.timeAvailable.selected.join(","),
      large_group: this.state.groupSize.selected.id,
      sense_of_budget: this.state.budget_sense.value,
      other_request: this.state.request_expectation.value
    };

    trackPromise(
      axios
        .post(APIbaseURL + "/plan_experience", JSON.stringify(payload))
        .then(response => {
          switch (response.data.code) {
            case 200:
              this.setState({
                form: {
                  isSubmitted: false,
                  isValid: false,
                  error: this.initialState.form.error
                }
              });
              this.props.redirectToPage("./PlanExperienceThankyou");
              break;
            case 400:
              this.setState({
                form: {
                  isSubmitted: true,
                  isValid: false,
                  error: response.data.data
                }
              });
              break;
            default:
              console.log("Default case in Plan Experience");
          }
        })
        .catch(function(error) {
          console.error("Plan Experience error: ", error);
        })
    );
  }

  render() {
    const {
      fname,
      lname,
      email,
      mobile,
      verticals,
      city,
      otherCity,
      timeAvailable,
      groupSize,
      budget_sense,
      request_expectation,
      form
    } = this.state;
    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space plan-experience">
            <Col className=" message-box" md={10} sm={12}>
              <Col className="mx-auto register-form" md={10} sm={12}>
                <h3 className="txt-orange">
                  Plan an Experience With The Soul Company
                </h3>
                <Form
                  className="planexperienceform"
                  onSubmit={this.formSubmitted}
                  noValidate
                >
                  <Form.Row>
                    <Form.Group className="col-12 col-lg-6" controlId="f_name">
                      <Form.Control
                        type="text"
                        placeholder="* First Name"
                        onChange={e => this.handleChange(e, "fname")}
                        value={fname.value}
                      />
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={fname.isValid}
                        error={fname.error}
                      />
                    </Form.Group>
                    <Form.Group className="col-12 col-lg-6" controlId="l_name">
                      <Form.Control
                        type="text"
                        placeholder="* Last Name"
                        onChange={e => this.handleChange(e, "lname")}
                        value={lname.value}
                      />
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={lname.isValid}
                        error={lname.error}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group className="col-12 col-lg-6" controlId="u_email">
                      <Form.Control
                        type="text"
                        placeholder="* Email"
                        onChange={e => this.handleChange(e, "email")}
                        value={email.value}
                      />
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={email.isValid}
                        error={email.error}
                      />
                    </Form.Group>
                    <Form.Group className="col-12 col-lg-6" controlId="mobile_no">
                      <Form.Control
                        type="text"
                        placeholder="* Mobile Number"
                        onChange={e => this.handleChange(e, "mobile")}
                        value={mobile.value}
                      />
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={mobile.isValid}
                        error={mobile.error}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        What would you like to experience?
                      </Form.Label>
                      {verticals.list.map((item, i) => (
                        <Form.Check
                          type="checkbox"
                          className="multicheckbox"
                          key={item.id}
                          label={item.name}
                          id={`vertical_${i}`}
                          value={item.id}
                          onChange={e => this.onCheckboxChanged(e, "verticals")}
                        />
                      ))}
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={verticals.isValid}
                        error={verticals.error}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="city_chosen">
                      <Form.Label>
                        India is our playground, where would you like your
                        experiences to be played out?
                      </Form.Label>
                      <DropdownButton
                        title={city.selected.name}
                        className="icon-angle-down"
                        onSelect={obj => this.dropdownSelected(obj, "city")}
                      >
                        {city.list.map((item, i) => {
                          return (
                            <Dropdown.Item
                              key={item.id}
                              eventKey={JSON.stringify(item)}
                            >
                              {item.name}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={city.isValid}
                        error={city.error}
                      />
                      {city.selected.id === "-1" && (
                        <div>
                          <Form.Control
                            type="text"
                            placeholder="* Please enter the city name"
                            value={otherCity.value}
                            onChange={e => this.handleChange(e, "otherCity")}
                          />
                          <ErrorBlock
                            isSubmitted={form.isSubmitted}
                            isValid={otherCity.isValid}
                            error={otherCity.error}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>How much time do you have?</Form.Label>
                      {timeAvailable.list.map((item, i) => (
                        <Form.Check
                          type="checkbox"
                          className="multicheckbox"
                          key={item.id}
                          label={item.name}
                          id={`timeAvailable_${i}`}
                          value={item.id}
                          checked={item.isChecked}
                          onChange={e =>
                            this.onCheckboxChanged(e, "timeAvailable")
                          }
                        />
                      ))}
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={timeAvailable.isValid}
                        error={timeAvailable.error}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="group_size">
                      <Form.Label className="nospace">
                        How large is your group?
                      </Form.Label>
                      <DropdownButton
                        title={groupSize.selected.name}
                        className="icon-angle-down"
                        onSelect={obj =>
                          this.dropdownSelected(obj, "groupSize")
                        }
                      >
                        {groupSize.list.map((item, i) => {
                          return (
                            <Dropdown.Item
                              key={item.id}
                              eventKey={JSON.stringify(item)}
                            >
                              {item.name}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={groupSize.isValid}
                        error={groupSize.error}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="budg_sens">
                      <Form.Control
                        type="text"
                        placeholder="* Give us a sense of the budgets you have in mind (per head) (Max 150 letters)"
                        value={budget_sense.value}
                        onChange={e => this.handleChange(e, "budget_sense")}
                      />
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={budget_sense.isValid}
                        error={budget_sense.error}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="req_expt">
                      <Form.Control
                        type="text"
                        placeholder="Any other requests or specific expectations you may have (Max 150 letters)"
                        value={request_expectation.value}
                        onChange={e =>
                          this.handleChange(e, "request_expectation")
                        }
                      />
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={request_expectation.isValid}
                        error={request_expectation.error}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Button className="cmn-btn" type="submit">
                        Submit
                      </Button>
                      <ErrorBlock
                        isSubmitted={form.isSubmitted}
                        isValid={form.isValid}
                        error={form.error}
                      />
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default RegisterComponent;

function ErrorBlock({ isSubmitted, isValid, error }) {
  return isSubmitted && !isValid ? <div className="errorMsg">{error}</div> : "";
}
