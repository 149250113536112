import React, { Component, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPrint,
  faCalendarAlt,
  faPhoneAlt,
  faEnvelope,
  faAngleLeft
} from "@fortawesome/pro-light-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import "./ExpOrderReview.css";
import {
  numberToINR,
  formatDateObject,
  RenderStringWithEntities
} from "../../../common.functions";
import axios from "axios";
import { APIbaseURL } from "../../../stores/config";
import { trackPromise } from "react-promise-tracker";
import store, { actions } from "../../../stores/book_order.store";
import { Redirect } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ReactGA from "react-ga";

class ExpOrderReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book_order: null,
      price: 0,
      status: "",
      msg: "",
      redirect: false
    };
    this.setTotalMRP = this.setTotalMRP.bind(this);
    this.bookOrder = this.bookOrder.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ book_order: store.getState().book_order });
    this.unsubscribe = store.subscribe(() => {
      this.setState({ book_order: store.getState().book_order });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  setTotalMRP(tmrp) {
    this.setState({ price: tmrp });
  }

  bookOrder() {
    const {
      book_order: {
        user_id,
        experience_id,
        route_url,
        no_of_person,
        book_dates,
        total_price
      }
    } = this.state;

    const payload = {
      user_id,
      experience_id,
      no_of_person,
      book_dates: book_dates
        .map(date =>
          formatDateObject(new Date(date), {
            dateStyle: "long",
            timeZone: "Asia/Kolkata"
          })
        )
        .join(),
      price: total_price
    };

    trackPromise(
      axios
        .post(APIbaseURL + "/booking", JSON.stringify({ ...payload }))
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              actions.clearBookOrderStore(store.dispatch);
              this.setState({
                ...this.state,
                status: "success",
                msg: "Congrats! Experience has been booked successfully",
                redirect: true
              });
              break;

            case 400:
              this.setState({
                ...this.state,
                status: "failure",
                msg: "Oops! Booking failed"
              });
              break;
            case 409:
              this.setState({
                ...this.state,
                status: "failure",
                msg: data.message
              });
              break;
            default:
              console.log("Default case in Experience Booking");
          }
        })
        .catch(function(error) {
          console.error("Experience Booking error: ", error);
        })
    );

    ReactGA.event({
      category: "cart_page",
      action: "cart_details",
      label: "cart"
    });
  }

  render() {
    const { book_order: bk, status, msg } = this.state;
    const { user_info: uInfo } = bk;

    if (this.state.redirect) return <Redirect to="/BookingThankyou" />;

    return (
      <React.Fragment>
        <Container fluid={true}>
          <Row className="orderreviewedit">
            <Col sm="12">
              <h2>Review And Complete Reservation</h2>
              <ScrollAnimation animateIn="line" delay={200} duration={3}>
                <span></span>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="go-back-link">
            <Col sm="12">
              <Link
                to={{
                  pathname: `/Expdetail/${bk.route_url}`,
                  state: { exp_id: bk.experience_id }
                }}
                className="link"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="left-angle" />
                <span className="text">Edit Order</span>
              </Link>
            </Col>
          </Row>
          <Row
            className="text-left message-box equal-space mt-0"
            ref={element => {
              this.printElement = element;
            }}
          >
            <Col sm={12} md={5} className="">
              <ReactToPrint
                trigger={() => (
                  <h5 className="txt-orange text-right print-btn d-block d-md-none">
                    Print_ <FontAwesomeIcon icon={faPrint} />
                  </h5>
                )}
                content={() => this.printElement}
              />
            </Col>
            <Col
              sm={12}
              md={4}
              lg={{ span: 3, offset: 1 }}
              className="cancel-wrapper mb-3 mb-md-0 no-print"
            ></Col>
            <Col
              sm={12}
              md={3}
              lg={3}
              className="d-flex align-items-center justify-content-end"
            >
              <ReactToPrint
                trigger={() => (
                  <h5 className="txt-orange text-right print-btn d-none d-md-block">
                    Print <FontAwesomeIcon icon={faPrint} />
                  </h5>
                )}
                content={() => this.printElement}
              />
            </Col>
            <div className="orderdetaillist mb-md-4">
              <Col sm={12} md={6} lg={4} className="print-inline-blk">
                <ul className="booking-contact">
                  <li className="title">Contact Details:</li>
                  <li>
                    <FontAwesomeIcon icon={faPhoneAlt} /> {uInfo.mobile}
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} /> {uInfo.email}
                  </li>
                </ul>
              </Col>
              <Col sm={12} md={6} lg={4} className="print-inline-blk">
                <ul className="booking-contact">
                  <li className="title">Billing Address: </li>
                  <li>{`${uInfo.address1} ${uInfo.address2} ${uInfo.city_name} ${uInfo.state_name} ${uInfo.pincode}`}</li>
                </ul>
              </Col>
              <Col
                sm={12}
                md={{ span: 6, offset: 3 }}
                lg={{ span: 4, offset: 0 }}
                className="order-summary print-inline-blk"
              >
                <h3 className="txt-orange">Order Summary</h3>
                {/* <div className="total-order-summary">
                  <div className="total-detail">Total MRP</div>
                  <div className="total-mrp">{numberToINR(bk.total_price)}</div>
                </div>
                <div className="total-order-summary">
                  <div className="total-detail">Tax</div>
                  <div className="total-mrp">Rs. 2,160</div>
                </div> */}
                <div className="total-summary total-order-summary">
                  <div className="total-detail txt-orange">Total</div>
                  <div className="total-mrp">
                    {bk.price_request || numberToINR(bk.total_price)}
                  </div>
                </div>
              </Col>
            </div>
            <Col sm={12} md={12} className="order-booking"></Col>
            <Col sm={12} md={12} className="card-booking no-space">
              <h5 className="txt-orange">Items in this Order: </h5>
              <h3>{bk.experience_name}</h3>
              <p>
                <RenderStringWithEntities input={bk.exp_short_desc} />
              </p>
              <div>
                <p className="info-breakdown">
                  <span className="icon-wrapper">
                    <FontAwesomeIcon icon={faUser} className="solidicon" />
                  </span>
                  <span className="info-wrapper">
                    {bk.no_of_person +
                      " " +
                      (bk.no_of_person > 1 ? "Guests" : "Guest")}
                    {/* <PriceBreakdown
											data={{
												persons: bk.no_of_person,
												price: bk.price,
												price_4gp: bk.four_group_price,
												price_request: bk.price_request
											}}
											setTotalMRP={this.setTotalMRP}
										/> */}
                  </span>
                </p>
                <p className="info-breakdown">
                  <span className="icon-wrapper">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="solidicon"
                    />
                  </span>
                  <span className="info-wrapper">
                    {bk.book_dates.map((date, indx) => (
                      <span className="info-item" key={"bkdt" + indx}>
                        {formatDateObject(new Date(date))}
                      </span>
                    ))}
                  </span>
                </p>
              </div>
            </Col>
            <Col sm={12} md={12}>
              <Button
                className="cmn-btn no-print send-order"
                disabled={status === "loading"}
                onClick={this.bookOrder}
              >
                Send Order
              </Button>
              {status === "success" && <div className="suc_text">{msg}</div>}
              {status === "failure" && <div className="err_text">{msg}</div>}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default ExpOrderReview;

const PriceBreakdown = ({
  data: { persons, price, price_4gp, price_request },
  setTotalMRP
}) => {
  let people = Number(persons);
  let elem = [];
  let i = 1;
  let t_mrp = 0;

  while (people >= 4) {
    t_mrp += Number(price_4gp);
    elem.push(
      <span className="info-item" key={"pbk" + i}>
        4 Guests ({price_request || numberToINR(price_4gp)}/- Per Group)
      </span>
    );
    people -= 4;
    i++;
  }

  if (people) {
    t_mrp += Number(price) * people;
    let wrd = people > 1 ? "Guests" : "Guest";
    elem.push(
      <span className="info-item" key={"pbk" + i}>
        {people +
          " " +
          wrd +
          "(" +
          (price_request || numberToINR(price)) +
          "/- Per Head)"}
      </span>
    );
  }

  useEffect(() => {
    setTotalMRP(t_mrp);
  }, [t_mrp, setTotalMRP]);

  return elem;
};
