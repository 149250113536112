import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import "./BillingAddress.css";
class BillingAddress extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row className="equal-space">
						<Col className="message-box register-form" md={12}>
							<Row>
								<Col md={12} xl={8}>
									<h3 className="txt-orange">Billing Address</h3>
									<Form>
										<Form.Row>
											<Form.Group as={Col} controlId="formGridName">
												<Form.Label>Name*</Form.Label>
												<Form.Control placeholder="Enter name" />
											</Form.Group>

											<Form.Group as={Col} controlId="formGridTel">
												<Form.Label>Mobile Number*</Form.Label>
												<Form.Control placeholder="Enter mobile number" />
											</Form.Group>
										</Form.Row>
										<Form.Group controlId="formGridAddress1">
											<Form.Label>Address Line 1*</Form.Label>
											<Form.Control placeholder="Enter address" />
										</Form.Group>
										<Form.Group controlId="formGridAddress2">
											<Form.Label>Address Line 2*</Form.Label>
											<Form.Control placeholder="Enter address" />
										</Form.Group>
										<Form.Row>
											<Form.Group as={Col} controlId="formGridCountry">
												<Form.Label>Country*</Form.Label>
												<Form.Control as="select">
													<option>Choose Country</option>
													<option value="Afganistan">Afghanistan</option>
													<option value="Albania">Albania</option>
													<option value="Algeria">Algeria</option>
													<option value="American Samoa">American Samoa</option>
													<option value="Andorra">Andorra</option>
													<option value="Angola">Angola</option>
													<option value="Anguilla">Anguilla</option>
													<option value="Antigua & Barbuda">
														Antigua & Barbuda
													</option>
													<option value="Argentina">Argentina</option>
													<option value="Armenia">Armenia</option>
													<option value="Aruba">Aruba</option>
												</Form.Control>
											</Form.Group>

											<Form.Group as={Col} controlId="formGridState">
												<Form.Label>State* </Form.Label>
												<Form.Control as="select">
													<option>Choose State </option>
													<option value="">Select 1</option>
													<option value="">Select 2</option>
													<option value="">Select 3</option>
												</Form.Control>
											</Form.Group>
										</Form.Row>
										<Form.Row>
											<Form.Group as={Col} controlId="formGridCity">
												<Form.Label>City*</Form.Label>
												<Form.Control as="select">
													<option>Choose City</option>
													<option value="">Select 1</option>
													<option value="">Select 2</option>
													<option value="">Select 3</option>
												</Form.Control>
											</Form.Group>

											<Form.Group as={Col} controlId="formGridState">
												<Form.Label>Postal Code*</Form.Label>
												<Form.Control placeholder="Select postal code" />
											</Form.Group>
										</Form.Row>
										<Button className="cmn-btn">Make Payment</Button>
									</Form>
								</Col>
								<Col className="order-summary" md={4} sm={12}>
									<h3 className="txt-orange">Order Summary</h3>
									<div className="total-order-summary">
										<div className="total-detail">Total MRP</div>
										<div className="total-mrp">Rs. 12,000</div>
									</div>
									<div className="total-order-summary">
										<div className="total-detail">Tax</div>
										<div className="total-mrp">Rs. 2,160</div>
									</div>
									<div className="total-summary total-order-summary">
										<div className="total-detail txt-orange">Total</div>
										<div className="total-mrp"> Rs. 14,160</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default BillingAddress;
