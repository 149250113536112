import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import "./ForgotPasswordPhone.css";

class ForgotPasswordPhone extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row className="equal-space forgotpasswordpage">
						<Col className=" message-box mx-auto" md={10} sm={12}>
							<div className=" mx-auto col-md-7 col-sm-12">
								<h3 className="txt-orange">Forgot Password?</h3>
								<p>
									Please provide your email address / Phone Number and
									<span className="d-block"></span> we will send you a link so
									that you can reset your password
								</p>

								<Form className="register-form registerpage">
									<Form.Row>
										<Form.Group as={Col} controlId="formGridphn">
											<Form.Control
												type="number"
												placeholder="Your Phone Number"
											/>
										</Form.Group>
									</Form.Row>
									<Form.Row>
										<Form.Group id="formOtp" className="otp-no">
											<Form.Label>Enter Your OTP</Form.Label>
											<div className="otpinput">
												<OtpInput
													onChange={otp => console.log(otp)}
													numInputs={6}
													separator={<span></span>}
												/>
											</div>
											<a href="/"> Resend OTP </a>
										</Form.Group>
									</Form.Row>
								</Form>
								<Link to="/" className="cmn-btn">
									Reset Password
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default ForgotPasswordPhone;
