import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import SubscribeThankYou from "../component/CommonComponent/Subscribe/SubscribeThankYou/SubscribeThankYou";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { APIbaseURL } from "../stores/config";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { TSCmeta } from "../common.functions";

class SubscribeThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.state = {
      isSubscribed: false,
      message: ""
    };
    this.randomToken = this.props.match.params.randomToken;
  }

  fetchUserDetailsByToken(tokenid) {
    trackPromise(
      axios
        .post(APIbaseURL + "/check_newsletter_token", { token: tokenid })
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                isSubscribed: true,
                message: data.message
              });
              break;
            case 400:
              this.setState({
                isSubscribed: false,
                message: "Error occurred in Newsletter subscription"
              });
              break;
            default:
              console.log("Default block in User details");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  componentDidMount() {
    this.fetchUserDetailsByToken(this.randomToken);
  }

  setState_success(data) {}

  render() {
    const { message } = this.state;

    return (
      <React.Fragment>
        <TSCmeta
          title="Subscribe Thank You - The Soul Company"
          description="Subscribe Thank You of The Soul Company"
          keywords="thanks for subscribing, subscription success"
          og_title="Subscribe Thank You - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Subscribe Thank You of The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <SubscribeThankYou
          data={{
            message
          }}
        />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default SubscribeThankYouPage;
