import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import ContactUs from "../component/ContactUs/ContactUs";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class ContactUspage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="The Soul Company: Travel Experiences"
          description="The Soul Company provides transformative experiences across verticals of culture with top notch experts and thoughtfully designed experiences."
          keywords="Travel Experiences, Cultural Experiences, Authentic Experience, Premier Luxury Lifestyle Experience, Art Expert, Food Expert"
          og_title="The Soul Company: Travel Experiences"
          og_url={this.PAGE_URL}
          og_description="The Soul Company provides transformative experiences across verticals of culture with top notch experts and thoughtfully designed experiences."
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <ContactUs />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default ContactUspage;
