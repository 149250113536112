import React from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./Faq.css";

class Faq extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row>
						<Col sm="12">
							<h2>Frequently asked questions</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row className="message-box equal-space termspolicieswrap">
						<Col className="termspolicies faq" sm="12">
							<h4>
								We are new to this, so for the sake of full transparency, we are
								calling this as (Not So) Frequently Asked Questions. In time,
								with your support and patronage, we hope they become just,
								Frequently Asked Questions. Feel free to write to us at{" "}
								<a href="maito:hello@thesoulcompany.in">
									hello@thesoulcompany.in
								</a>{" "}
								for more questions that you don’t find answers to here.
							</h4>
						</Col>
						<Col className="faqaccordian ac-qstn" sm="12">
							<h3 className="txt-orange">GENERAL QUESTIONS</h3>
							<Accordion defaultActiveKey="0">
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="0">
										<a
											data-toggle="collapse"
											aria-expanded="true"
											aria-controls="collapseOne"
											href="#collapseOne"
										>
											Who is the Soul Company?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="0" id="collapseOne">
										<Card.Body>
											<p>
												The Soul Company has been designed and created to
												deliver experiences that are transformative and show you
												a path to the depths of culture through the eyes of
												experts across a plethora of disciplines.
											</p>
											<p>
												We intend to launch an online platform that allows a
												carefully selected set of experts to create experiences
												for our consumers. These experiences will unfold in the
												spaces defined by the expert and can span between a few
												hours to a couple of days. We give the experts a blank
												canvas to tell their story, and we find the loose ends
												to tie them together while adding context and texture to
												the experience, before offering it to our final
												customer. Our experiences are across Food, Wildlife,
												Royalty, Art, Wellness and Heritage.
											</p>
											<p>
												We are adding experiences across verticals every month
												and you must keep rteurning to see what's new. So yes,
												bookmark us!
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="1">
										<a
											className="collapsed"
											data-toggle="collapse"
											aria-expanded="false"
											aria-controls="collapseTwo"
											href="#collapseTwo"
										>
											Why do you exist?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="1" id="collapseTwo">
										<Card.Body>
											<p>
												We believe that a cultural curiosity is important. For
												an individual, society and the world. Through the
												experts on our platform, their stories and their crft we
												hope to bridge the gap between our culture and your
												curiosity for it. We exist very simply, so you may
												embark on a journey you never knew was possible
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="2">
										<a
											className="collapsed"
											data-toggle="collapse"
											aria-expanded="false"
											aria-controls="collapseThree"
											href="#collapseThree"
										>
											How are you different from all the other brands out there
											offering experiences? Aren’t they all just the same, but
											with different names?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="2" id="collapseThree">
										<Card.Body>
											<p>It's fairly simple and can be captured in 3 points:</p>
											<ul>
												<li>
													1) The experts cannot list on the platform. They
													participate by invitation only.
												</li>
												<li>
													2) They have to be undeniably recognised both
													nationally and internationally. as being a leader and
													path breaker in their field.
												</li>
												<li>
													3) There are no templates. Each experience is
													different and we expect clients to approach it with a
													spirit of surrender.
												</li>
											</ul>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="3">
										<a
											className="collapsed"
											data-toggle="collapse"
											aria-expanded="false"
											aria-controls="collapseFour"
											href="#collapseFour"
										>
											If you were to choose an experience, which one would you
											do?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="3" id="collapseFour">
										<Card.Body>
											<p>
												It's like asking a parent who is their favourite child.
												But we do have an answer and it really depends on who we
												are doing the experience with. If you need help on
												deciding which experience best suits you ; Please write
												to us at hello@thesoulcompany.in and we will help you
												make this impossible decision.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="4">
										<a
											className="collapsed"
											data-toggle="collapse"
											aria-expanded="false"
											aria-controls="collapseFive"
											href="#collapseFive"
										>
											How do I go about booking an experience?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="4" id="collapseFive">
										<Card.Body>
											<p>
												Once you are convinced about an experience you would
												like to do, choose a date and the number of guests who
												would be a part of the experience. Select a date and
												click, 'Reserve'. Onc eyou do that we will get down to
												confirming the dates with our expert and get back to you
												with a confirmations, some more details on the
												experience, the final price for your group size and a
												payment link. Upon completing the payment, you will
												recieve further details on the experience and we will
												guide you through everything you nee dto know in the
												days leading up to your special date with our expert.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="5">
										<a
											className="collapsed"
											data-toggle="collapse"
											aria-expanded="false"
											aria-controls="collapseSix"
											href="#collapseSix"
										>
											Why can I only Reserve, and not book?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="5" id="collapseSix">
										<Card.Body>
											<p>
												A booking means an instant confimation upon payment. But
												at The Soul Company we allow only for a reseravtion and
												a confirmation is given by us, to you only post
												recieving a confirmation from the expert on the dates
												you have reserved the experience for. Please do
												understand that these are handcrafted expriences and the
												experts at the centre of it must be available for the
												same. We do our best to ensure you always get the
												experience and the dates you desire or in case we aren't
												able to service that we will always be at hand to help
												you with an alternate date or a relevant and equally
												interesting expert for the same date.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="6">
										<a
											className="collapsed"
											data-toggle="collapse"
											aria-expanded="false"
											aria-controls="collapseSeven"
											href="#collapseSeven"
										>
											I love a good meal, a safari, some art... I just want it
											all. Can't you plan something for me that can combine a
											bit of everything?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="6" id="collapseSeven">
										<Card.Body>
											<p>
												We love that you have this question! And we love
												creating itineraries like these. Just hit us up at{" "}
												<a href="mailto:hello@thesoulcompany.in">
													hello@thesoulcompany.in
												</a>{" "}
												and let us work our magic for you.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="7">
										<a
											className="collapsed"
											data-toggle="collapse"
											aria-expanded="false"
											aria-controls="collapseEight"
											href="#collapseEight"
										>
											How do you decide on the experts you onboard?
										</a>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="7" id="collapseEight">
										<Card.Body>
											<p>
												Across the fields of culture we focus on, we work with
												multiple advisors to be able to define who are the best
												in their domain at their craft, and as storytellers. Our
												experts are on our platform purely on an invite basis
												and no experience we list would be something any of us
												would not want to participate in. Every expert is a bona
												fide leader in their space and creating something
												special that allows them the breadth to be able to tell
												a story that will excite you.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</Col>
						<Col className="account-qstn faqaccordian" sm="12">
							<h3 className="txt-orange">ACCOUNT QUESTIONS</h3>
							<Card>
								<Card.Header>Why do I need to create an account?</Card.Header>
								<Card.Body>
									<p>
										Creating an account helps you with quicker checkouts, to be
										able to keep a tab on your orders and their status and as a
										business, it helps us know you are real and that we are
										connecting our amazing experts with people who are
										interested in meeting them. It also helps us to keep you
										informed on everything around the experience you have booked
										as there is some amount of coordination involved in each
										experience, depending on what you select.
									</p>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									Does creating an account mean I am going to be spammed with
									volumes of marketing material?
								</Card.Header>
								<Card.Body>
									<p>
										Believe it or not, but we hate spam as much as you. We will
										rarely do newsletters and when we do, we promise they will
										be an interesting read or talk about new experts who we have
										brought on-board. If at any point, you do not enjoy the
										newsletters, you can always unsubscribe without any changes
										to your account.
									</p>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									Why do you ask for my address? Are you planning to visit me?
								</Card.Header>
								<Card.Body>
									<p>
										We do hope to meet some day, but maybe over a meal or one of
										our experiences. For now, we just need your address to help
										in processing invoices and for our own compliance
										requirements.
									</p>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									Where can I see my orders and status on existing orders?
								</Card.Header>
								<Card.Body>
									<p>
										That's the beauty of creating an account with us. Your
										profile page gives you all the details you need on your past
										orders and existing in terms of booking and payment status.
									</p>
								</Card.Body>
							</Card>
						</Col>
						<Col className="account-qstn faqaccordian refund" sm="12">
							<h3 className="txt-orange">Payment and Refund Questions</h3>
							<Card>
								<Card.Header>
									What does the price of each experience include?
								</Card.Header>
								<Card.Body>
									<p>
										As each experience is different, what is included changes
										too. But rest assured, if a meal is mentioned in the
										experience then you will be having one and if any logistics
										of any kind is involved in case of experiences that involve
										travel during the course of the experience, then we will be
										taking care of it. Before making a payment, you are free to
										get in touch with us for any other details you may require.
										GST is included in the pricing, to ensure a clear picture of
										what you are paying.
									</p>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									If The Soul Company cancels an experience after I have paid
									for it, what are the next steps?
								</Card.Header>
								<Card.Body>
									<p>
										We would rarely do that to you, but in some circumstances
										which involve non-fulfillment on part of us or the expert,
										we will fully refund your entire value of the experience. No
										questions asked.
									</p>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									If I cancel an experience after confirming and making a
									payment, do I get a refund?
								</Card.Header>
								<Card.Body>
									<p>
										If the date of the cancellation is within 15 days of the
										experience, we will cut a service fees which is to ensure
										our expert is not inconvenienced by the cancellation as a
										lot of blocking of dates, preparation and just general
										excitement goes into building out our experiences and we
										will have to compensate for the same.
									</p>
									<p>
										If the cancellation is within 7 days of the experience, we
										will not be able to refund any amount as all the logistics,
										expert's time and their team's will already be booked.
									</p>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									What if I choose to do a different experience after booking
									another experience?
								</Card.Header>
								<Card.Body>
									<p>
										We can understand a change of heart and we are here to help
										you through that, but the cancellations rules will apply if
										it is within 15 days of the original planned experience. But
										beyond that, we will manage this on a case to case basis and
										ensure a smooth experience for you.
									</p>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default Faq;
