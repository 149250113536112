import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import ChangePassword from "../component/RegisterComponent/ChangePassword/ChangePassword";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";
import Cookies from "js-cookie";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { APIbaseURL } from "../stores/config";
import { Redirect } from "react-router";
import BOstore, { actions as BOactions } from "../stores/book_order.store";

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.state = {
      redirect: ""
    };
    this.userId = Cookies.get("userid");
    this.logOutUser = this.logOutUser.bind(this);
  }

  logOutUser() {
    trackPromise(
      axios.get(APIbaseURL + "/logout").then(res => {
        const { code } = res.data;
        switch (code) {
          case 200:
            Cookies.remove("userid");
            Cookies.remove("username");
            Cookies.remove("user_name");
            Cookies.remove("pyramids_social_token");
            BOactions.clearBookOrderStore(BOstore.dispatch);
            this.setState({
              redirect: true
            });
            break;
          case 400:
            break;
          default:
            console.log("Default block in Logout request");
        }
      })
    );
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/ChangepasswordSuccessful" />;
    }

    return (
      <React.Fragment>
        <TSCmeta
          title="Change Password - The Soul Company"
          description="Password Change of The Soul Company"
          keywords="change password, reset password, regenerate password"
          og_title="Change Password - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Password Change of The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <ChangePassword userId={this.userId} logOutUser={this.logOutUser} />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default ChangePasswordPage;
