import React, { Component } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  ListGroup,
  DropdownButton,
  Dropdown,
  Button
} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarAlt,
  faArrowRight
} from "@fortawesome/pro-light-svg-icons";
import "./OrdersList.css";
import { MediaBaseURL } from "../../../stores/config";
import {
  numberToINR,
  formatDateObject,
  RenderStringWithEntities
} from "../../../common.functions";

class OrdersList extends Component {
  render() {
    const {
      orderStatusList,
      selectedStatus,
      statusSelected,
      sortByDateList,
      selectedSortDate,
      sortSelected,
      totalItems,
      itemsPerRow,
      orderList,
      showText,
      handleShow,
      noDataMsg,
      cancelOrder
    } = this.props;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col sm="12">
              <h2>Orders</h2>
              <ScrollAnimation
                animateIn="line"
                delay={100}
                duration={3}
                animateOnce={true}
              >
                <span></span>
              </ScrollAnimation>
            </Col>
          </Row>

          <Row>
            <Col sm="12" className="d-flex justify-content-end mb-3">
              <div className="searchfilter customDropdown mr-4">
                <span className="labelText">Filter By</span>
                <DropdownButton
                  title={selectedStatus.name}
                  className="icon-angle-down no-scroll"
                  onSelect={statusSelected}
                >
                  {orderStatusList.map(item => (
                    <Dropdown.Item
                      key={item.id}
                      eventKey={JSON.stringify(item)}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div className="searchfilter customDropdown">
                <span className="labelText">Sort By</span>
                <DropdownButton
                  title={selectedSortDate.name}
                  className="icon-angle-down no-scroll"
                  onSelect={sortSelected}
                >
                  {sortByDateList.map(item => (
                    <Dropdown.Item
                      key={item.id}
                      eventKey={JSON.stringify(item)}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            </Col>
          </Row>

          <Row className="text-left message-box equal-space mt-0">
            {orderList.length ? (
              orderList.map(item => {
                return (
                  <OrderItem
                    item={item}
                    key={item.order_id}
                    cancelOrder={cancelOrder}
                  />
                );
              })
            ) : (
              <div className="NoOrderToShow">{noDataMsg}</div>
            )}
          </Row>

          {totalItems > itemsPerRow ? (
            <Row>
              <Button
                variant=""
                className="show-more mr-auto ml-auto"
                onClick={handleShow}
              >
                {showText}
                <FontAwesomeIcon icon={faArrowRight} className="arrorright" />
              </Button>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </React.Fragment>
    );
  }
}
export default OrdersList;

const OrderItem = props => {
  const { item, cancelOrder } = props;
  const exp_dates = item.experience_dates.split(",");
  return (
    <div className="order-list-box">
      <Col sm={12} md={5}>
        <img
          src={MediaBaseURL + item.img_path}
          className="img-fluid"
          alt="Experience"
        ></img>
      </Col>
      <Col sm={12} md={7} className="order-text">
        <h5 className="txt-orange">
          Order No: <span>{item.order_number}</span>
        </h5>
        <Card>
          <Card.Title>{item.title}</Card.Title>
          <Card.Text>
            <RenderStringWithEntities input={item.short_desc} />
          </Card.Text>
          <ListGroup variant="flush">
            <ListGroup.Item className="exp-infor pr-0">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faUser} className="solidicon" />
              </div>
              <div className="breakdown-wrapper">
                {item.no_of_person + ' ' + (item.no_of_person > 1 ? 'Guests' : 'Guest')}
                {/* <PriceBreakdown
                  data={{
                    persons: item.no_of_person,
                    price: item.experience_price,
                    price_4gp: item.four_group_price,
                    price_request: item.price_request
                  }}
                /> */}
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="exp-infor pr-0">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faCalendarAlt} className="solidicon" />
              </div>
              <div className="breakdown-wrapper">
                {exp_dates.map((date, i) => (
                  <p key={"expdate-" + i}>{formatDateObject(new Date(date))}</p>
                ))}
              </div>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup variant="flush" className="bookinfinfo">
            <ListGroup.Item>
              <b className="txt-orange">
                Total <span>{item.price_request || numberToINR(item.price)}</span>
              </b>
            </ListGroup.Item>
            <ListGroup.Item>
              <b>
                Booking Date:{" "}
                <span>{formatDateObject(new Date(item.booking_date))}</span>
              </b>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup variant="flush" className="order-status-info">
            <ListGroup.Item>
              <div className="status-txt">Rervation Status :</div>
              <div className="status-val">{item.order_status_name}</div>
              {item.hold_status === "1" ? <div className="status-subval">(On Hold)</div> : ""}
            </ListGroup.Item>
          </ListGroup>
          <Card.Body className="action-wrapper">
            <Card.Link
              href={`/OrderDetails/${item.order_id}`}
              className="cmn-btn"
            >
              View Details
            </Card.Link>
            <Button
              type="button"
              className="cmn-btn-inverse"
              disabled={item.disableCancel}
              onClick={() => {
                cancelOrder(item.order_id, item.order_number);
              }}
            >
              Cancel Order
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

const PriceBreakdown = ({ data: { persons, price, price_4gp, price_request } }) => {
  let people = Number(persons);
  let elem = [];
  let i = 1;
  while (people >= 4) {
    elem.push(
      <p key={"pbk" + i}>4 Guests ({price_request || numberToINR(
        price_4gp
      )}/- Per Group)</p>
    );
    people -= 4;
    i++;
  }
  if (people) {
    let wrd = people > 1 ? "Guests" : "Guest";
    elem.push(
      <p key={"pbk" + i}>{people + " " + wrd + "(" + (price_request || numberToINR(price)) + "/- Per Head)"}</p>
    );
  }
  return elem;
};
