import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./BookingThankyou.css";

class BookingThankyou extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row className="equal-space">
						<Col className="text-center message-box regthankyou" sm={12}>
							<h2 className="txt-orange">Thank You </h2>
							<h3>For reserving an experience with us!</h3>
							<h3>Please expect an email from us on next steps.</h3>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default BookingThankyou;
