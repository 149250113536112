import { createStore } from "redux";
import { APIbaseURL } from "./config";
import axios from "axios";
import devToolsEnhancer from "remote-redux-devtools";
import { trackPromise } from "react-promise-tracker";

export const types = {
  GET_VERTICALS: "GET_VERTICALS",
  GET_EXPERIENCES: "GET_EXPERIENCES",
  GET_EXPERIENCES_BY_VERTICAL: "GET_EXPERIENCES_BY_VERTICAL",
  TOGGLE_ALERT: "TOGGLE_ALERT",
  ADD_EXP_TO_WISHLIST: "ADD_EXP_TO_WISHLIST"
};

let initialState = {
  verticals: [],
  experiences: [],
  alert_show: false,
  alert_message: "",
  alert_variant: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_VERTICALS:
      return { ...state, verticals: action.payload };
    case types.GET_EXPERIENCES:
      return { ...state, experiences: action.payload };
    case types.ADD_EXP_TO_WISHLIST:
      return {
        ...state,
        experiences: changeWishlistedFlag(state.experiences, action.expId),
        alert_show: true,
        alert_message: action.message,
        alert_variant: "suces"
      };
    case types.TOGGLE_ALERT:
      return {
        ...state,
        alert_show: !state.alert_show,
        alert_message: action.message,
        alert_variant: action.variant
      };
    case types.GET_EXPERIENCES_BY_VERTICAL:
      return { ...state, experiences: action.payload };
    default:
      return state;
  }
};

export default createStore(reducer, devToolsEnhancer());

function changeWishlistedFlag(list, expId) {
  let temp_list = list.map(item => {
    if (Number(item.id) === Number(expId)) {
      item.iswishlisted = "1";
    }
    return item;
  });
  return temp_list;
}

export const asynchActions = {
  getVerticals,
  getFeaturedExp,
  addExpToWishList,
  getFeaturedExpByVertical
};

function getVerticals(dispatch) {
  trackPromise(
    axios
      .get(APIbaseURL + "/verticals")
      .then(response => {
        const { data } = response;
        switch (data.code) {
          case 200:
            dispatch({ type: types.GET_VERTICALS, payload: data.data });
            break;
          case 400:
            dispatch({
              type: types.GET_VERTICALS,
              payload: [],
              message: data.message
            });
            break;
          default:
            console.log("Default block in Veritcals");
        }
      })
      .catch(function(error) {
        console.error("Verticals error: ", error);
      })
  );
}

function getFeaturedExp(dispatch, userId) {
  let temp_url = userId
    ? APIbaseURL + "/experiences/" + userId
    : APIbaseURL + "/experiences";
  trackPromise(
    axios
      .get(temp_url)
      .then(response => {
        const { data } = response;
        switch (data.code) {
          case 200:
            dispatch({ type: types.GET_EXPERIENCES, payload: data.data });
            break;
          case 400:
            dispatch({
              type: types.GET_EXPERIENCES,
              payload: [],
              message: data.message
            });
            break;
          default:
            console.log("Default block in Experiences");
        }
      })
      .catch(function(error) {
        console.error("Experiences error: ", error);
      })
  );
}

function addExpToWishList(dispatch, userId, expId) {
  const payload = { exp_id: expId, user_id: userId };
  trackPromise(
    axios
      .post(APIbaseURL + "/add_wishlist", JSON.stringify(payload))
      .then(response => {
        const { data } = response;
        switch (data.code) {
          case 200:
            dispatch({
              type: types.ADD_EXP_TO_WISHLIST,
              expId,
              message: data.data
            });
            break;
          case 400:
            dispatch({
              type: types.TOGGLE_ALERT,
              message: data.data,
              variant: "failr"
            });
            break;
          case 409:
            dispatch({
              type: types.TOGGLE_ALERT,
              message: data.message,
              variant: "failr"
            });
            break;
          default:
            console.log("Default case in Add Experience to Wishlist");
        }
      })
      .catch(function(error) {
        console.error("Add to wishlist error: ", error);
      })
  );
}

function getFeaturedExpByVertical(dispatch, id) {
  trackPromise(
    axios
      .get(APIbaseURL + "/experiences_by_vertical/" + id)
      .then(response => {
        const { data } = response;
        switch (data.code) {
          case 200:
            dispatch({
              type: types.GET_EXPERIENCES_BY_VERTICAL,
              payload: data.data
            });
            break;
          case 400:
            dispatch({
              type: types.GET_EXPERIENCES_BY_VERTICAL,
              payload: [],
              message: data.message
            });
            break;
          default:
            console.log("Default block in Experiences By Vertical");
        }
      })
      .catch(function(error) {
        console.error("Experiences By Vertical error: ", error);
      })
  );
}
