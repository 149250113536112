import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./ForgotPassword.css";
import { APIbaseURL } from "../../../stores/config";
import axios from "axios";
import { Redirect } from "react-router";
import { trackPromise } from "react-promise-tracker";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.formSubmitted = this.formSubmitted.bind(this);
    this.state = {
      email: {
        value: "",
        isValid: false,
        error: ""
      },
      form: {
        isSubmitted: false
      },
      suc_msg: "",
      err_msg: "",
      redirect: false
    };
  }

  handleChange(e, field) {
    let { email } = this.state;
    switch (field) {
      case "email":
        this.setState({ email: { ...email, value: e.target.value } }, () => {
          this.validateField(field);
        });
        break;
      default:
        console.log("Default case in handleChange");
    }
  }

  validateField(field) {
    let { email } = this.state;
    switch (field) {
      case "email":
        if (email.value) {
          if (email.value.length < 255) {
            if (email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
              this.setState({ email: { ...email, isValid: true, error: "" } });
            } else {
              this.setState({
                email: {
                  ...email,
                  isValid: false,
                  error: "Please enter valid email ID"
                }
              });
            }
          } else {
            this.setState({
              email: {
                ...email,
                isValid: false,
                error: "Max 255 characters allowed"
              }
            });
          }
        } else {
          this.setState({
            email: { ...email, isValid: false, error: "No blank allowed" }
          });
        }
        break;
      default:
        console.log("Default case in validateField");
    }
  }

  formSubmitted(e) {
    e.preventDefault();
    this.setState({ form: { isSubmitted: true } });
    if (this.state.email.isValid) {
      this.sendRequest(this.state.email.value);
    }
  }

  sendRequest(email) {
    trackPromise(
      axios
        .post(APIbaseURL + "/forgot_password", JSON.stringify({ email: email }))
        .then(response => {
          switch (response.data.code) {
            case 200:
              this.setState({
                suc_msg: response.data.message,
                err_msg: "",
                form: { isSubmitted: false },
                redirect: true
              });
              break;
            case 400:
              this.setState({
                suc_msg: "",
                err_msg: response.data.message,
                form: { isSubmitted: false }
              });
              break;
            default:
              console.log("Default case in Post Email");
          }
        })
        .catch(function(error) {
          console.error("Subscribe to Newsletter error: ", error);
        })
    );
  }

  render() {
    const { email, form, suc_msg, err_msg, redirect } = this.state;
    if (redirect) {
      return <Redirect to="/ForgotpasswordThankyou" />;
    }
    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space forgotpasswordpage">
            <Col className=" message-box mx-auto" md={10} sm={12}>
              <div className=" mx-auto col-md-7 col-sm-12">
                <h3 className="txt-orange">Forgot Password?</h3>
                <p>
                  Please provide your email address and{" "}
                  <span className="d-block"></span> we will send you a link so
                  that you can reset your password
                </p>

                <Form
                  className="register-form registerpage"
                  onSubmit={this.formSubmitted}
                  noValidate
                >
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Control
                        type="email"
                        placeholder="Your email address"
                        value={email.value}
                        onChange={e => this.handleChange(e, "email")}
                      />
                      {form.isSubmitted && !email.isValid ? (
                        <Form.Text className="err_text">
                          {email.error || "Please enter valid email"}
                        </Form.Text>
                      ) : (
                        ""
                      )}
                      {!form.isSubmitted &&
                        ((suc_msg && (
                          <Form.Text className="suc_text">{suc_msg}</Form.Text>
                        )) ||
                          (err_msg && (
                            <Form.Text className="err_text">
                              {err_msg}
                            </Form.Text>
                          )))}
                    </Form.Group>
                  </Form.Row>
                  <Button className="cmn-btn" type="submit">
                    Reset Password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default ForgotPassword;
