import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import Blogs from "../component/Blogs/Blogs";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Blogs - The Soul Company"
          description="Blogs relating to The Soul Company"
          keywords="blogs"
          og_title="Blogs - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Blogs relating to The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <Blogs />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Blog;
