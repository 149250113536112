import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPhoneAlt,
	faEnvelope,
	faMapMarkerAlt
} from "@fortawesome/pro-light-svg-icons";
import "./ContactUs.css";

class ContactUs extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row>
						<Col sm="12">
							<h2>Contact Us</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row className="equal-space">
						<Col className="text-left message-box" sm={12}>
							<p>
								We would love to hear from you. We do not have a dedicated phone
								line as of yet but as we are a super tight team, we respond to
								emails pretty fast. Also, we love the beauty of written word and
								will call you immediately if we believe your issue or concern
								cannot be solved with an email.
							</p>
							<br />
							<p>
								<a href="mailto:hello@thesoulcompany.in">
									<FontAwesomeIcon
										icon={faEnvelope}
										className="txt-orange contact-icons"
									/>
									hello@thesoulcompany.in
								</a>
							</p>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default ContactUs;
