import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./ResetPassword.css";
import { APIbaseURL } from "../../../stores/config";
import axios from "axios";
import { Redirect } from "react-router";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmpassword: "",
      redirect: false,
      disableResendotp: true,
      booking: {
        msg: ""
      },
      resend_otp: {
        status: "",
        data: "",
        msg: ""
      }
    };
    this.onSubmit = this.onSubmit.bind(this);

    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassowrd = this.onChangeConfirmPassowrd.bind(this);
    this.onChangeOtp = this.onChangeOtp.bind(this);
    this.userid = this.props.data.userid;
    console.log("this.userid: ", this.userid);
  }

  onChangeConfirmPassowrd(e) {
    this.setState({
      confirmpassword: e.target.value
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeOtp(e) {
    this.setState({
      otp: e
    });
  }

  resendOTP(id) {
    const obj = {
      id,
	  type:1
    };
    trackPromise(
      axios
        .post(APIbaseURL + "/resend_otp", obj)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                ...this.state,
                resend_otp: {
                  status: "success",
                  msg: data.message
                },
                disableResendotp: false
              });
              break;
            case 400:
              this.setState({
                ...this.state,
                resend_otp: {
                  status: "failure",
                  msg: data.message
                }
              });
              break;
            default:
              console.log("Default block in Resend OTP");
          }
        })
        .catch(function(error) {
          console.error("Resend OTP error: ", error);
        })
    );
  }

  onSubmit(e) {
    e.preventDefault();

    if (!this.state.password) {
      this.setState({
        booking: {
          ...this.state.booking,
          status: "failure",
          msg: "Please enter password."
        }
      });
    } else if (this.state.password !== this.state.confirmpassword) {
      this.setState({
        booking: {
          ...this.state.booking,
          status: "failure",
          msg: "Password and Confirm password not matching."
        }
      });
    } else if (!this.state.otp) {
      this.setState({
        booking: {
          ...this.state.booking,
          status: "failure",
          msg: "Please enter OTP."
        }
      });
    } else {
      const paylaod = {
        id: this.userid,
        password: this.state.password,
        otp: this.state.otp
      };

      trackPromise(
        axios
          .post(APIbaseURL + "/reset_password", JSON.stringify({ ...paylaod }))
          .then(response => {
            const { data } = response;
            switch (data.code) {
              case 200:
                this.setState({
                  ...this.initialState,
                  booking: {
                    ...this.state.booking,
                    status: "success",
                    msg: "Your password has been reset."
                  },
                  redirect: true
                });
                break;
              case 400:
                this.setState({
                  booking: {
                    ...this.state.booking,
                    status: "failure",
                    msg: data.message
                  }
                });
                break;
              case 409:
                this.setState({
                  booking: {
                    ...this.state.booking,
                    status: "failure",
                    msg: data.message
                  }
                });
                break;
              default:
                console.log("Default case in Reset Password");
            }
          })
          .catch(function(error) {
            console.error("Experience Booking error: ", error);
          })
      );
    }
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/ForgotpasswordSuccessful" />;
    }
    const { resend_otp: rotp } = this.state;
    const { userid } = this.props.data;

    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space forgotpasswordpage">
            <Col className=" message-box mx-auto" md={10} sm={12}>
              <div className=" mx-auto col-md-7 col-sm-12">
                <h3 className="txt-orange">Reset Password</h3>
                <Form
                  className="register-form registerpage"
                  onSubmit={this.onSubmit}
                >
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Control
                        type="password"
                        value={this.state.confirmpassword}
                        onChange={this.onChangeConfirmPassowrd}
                        placeholder="Confirm password"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group id="formOtp" className="otp-no">
                      <Form.Label>Enter Your OTP</Form.Label>
                      <div className="otpinput">
                        <OtpInput
                          numInputs={6}
                          separator={<span></span>}
                          value={this.state.otp}
                          onChange={this.onChangeOtp}
                        />
                      </div>
                      {this.state.disableResendotp && (
                        <>
                          <Link
                            to="#"
                            onClick={() => {
                              this.resendOTP(userid);
                            }}
                          >
                            {" "}
                            Resend OTP{" "}
                          </Link>
                        </>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <div className="dBlock">
                    <div id="unavailable_error">
                      {rotp.status === "success" && (
                        <Form.Text className="suc_text">{rotp.msg}</Form.Text>
                      )}
                      {rotp.status === "failure" && (
                        <Form.Text className="err_text">{rotp.msg}</Form.Text>
                      )}
                    </div>
                    <div className="errorMsg">{this.state.booking.msg}</div>
                  </div>
                  <Form.Row>
                    <Form.Group>
                      <Button className="cmn-btn top-space" type="submit">
                        Reset Password
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default ResetPassword;
