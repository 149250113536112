export let APIbaseURL = "";
export let MediaBaseURL = "";

if (process.env.NODE_ENV === "production") {
  // Production
  APIbaseURL = "https://www.thesoulcompany.in/tsc/index.php/api";
  MediaBaseURL = "https://www.thesoulcompany.in/tsc/";
 //APIbaseURL = "http://167.71.228.13/tsc/index.php/api";
 //MediaBaseURL = "http://167.71.228.13/tsc/";
} else {
  // Development
  APIbaseURL = "http://167.71.228.13/tsc/index.php/api";
  MediaBaseURL = "http://167.71.228.13/tsc/";
  // APIbaseURL = "http://localhost/tsc/tsc_admin/index.php/api";
  // MediaBaseURL = "http://localhost/tsc/tsc_admin/";
  // APIbaseURL = 'http://peractoinfotech.in/tsc/api';
  // MediaBaseURL = 'http://peractoinfotech.in/tsc/';
}
