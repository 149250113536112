import React, { Component } from "react";
import {
  Navbar,
  Nav,
  // Form,
  // FormControl,
  // Button,
  Container,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/TSC_logo.svg";
import "./Mainnavbar.css";
import store from "../../../stores/common.store";
import { asynchActions } from "../../../stores/common.store";
import { APIbaseURL } from "../../../stores/config";
import BOstore, {
  actions as BOactions
} from "../../../stores/book_order.store";
import axios from "axios";
import Cookies from "js-cookie";
import { Redirect } from "react-router";
import { trackPromise } from "react-promise-tracker";
import SVG from "react-inlinesvg";

class Mainnavbar extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.formSubmitted = this.formSubmitted.bind(this);
    this.logOutUser = this.logOutUser.bind(this);
    this.state = {
      search: "",
      isSubmitted: false,
      userid: Cookies.get("userid"),
      user_name: Cookies.get("user_name"),
      redirect: false,
      divoutheight: null,
      navExpanded: false
    };
  }

  handleChange(e) {
    this.setState({ search: e.target.value });
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      if (e.target.classList.contains("navbar-toggler-icon")) {
        this.setState(prevState => ({
          navExpanded: !prevState.navExpanded
        }));
      }
    } else {
      this.setState(prevState => ({
        navExpanded: false
      }));
    }
    console.log(e.target.classList.contains("navbar-toggler-icon"));
  };

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  formSubmitted(e) {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    if (e.currentTarget.checkValidity()) {
      asynchActions.getExperiencesBySearch(store.dispatch, this.state.search);
    }
  }

  logOutUser() {
    trackPromise(
      axios.get(APIbaseURL + "/logout").then(res => {
        const { code } = res.data;
        switch (code) {
          case 200:
            Cookies.remove("userid");
            Cookies.remove("username");
            Cookies.remove("user_name");
            Cookies.remove("pyramids_social_token");
            BOactions.clearBookOrderStore(BOstore.dispatch);
            this.setState({
              redirect: true
            });
            break;
          case 400:
            break;
          default:
            console.log("Default block in Logout request");
        }
      })
    );
  }

  componentDidMount() {
    this.setState({
      divoutheight: this.divElement.clientHeight
    });
  }

  render() {
    //let { search, isSubmitted, userid, redirect, user_name } = this.state;
    let { userid, redirect, user_name } = this.state;
    if (redirect) {
      return <Redirect to="/logout" />;
    }

    return (
      <React.Fragment>
        <Container
          fluid={true}
          className="container-grid"
          ref={node => (this.node = node)}
        >
          <Navbar
            className="navbar-default"
            expand="lg"
            fixed="top"
            ref={divel => (this.divElement = divel)}
            expanded={this.state.navExpanded}
          >
            <Navbar.Brand href="/">
              <SVG
                baseURL="/"
                cacheRequests={true}
                description="The Soul Company"
                loader={<span>Loading...</span>}
                onError={error => console.log(error.message)}
                onLoad={(src, hasCache) => {}}
                preProcessor={code =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={logo}
                title="The Soul Company"
                uniqueHash="a1f8d1"
                uniquifyIDs={true}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="navbar-left menu">
              <Nav className="mr-auto left-menu">
                <Link
                  to="/"
                  className={
                    this.props.history.location.pathname === "/" ? "active" : ""
                  }
                >
                  Home
                </Link>
                <Link
                  to="/Experiences"
                  className={
                    this.props.history.location.pathname === "/Experiences"
                      ? "active"
                      : ""
                  }
                >
                  Experiences
                </Link>
                <Link
                  to="/AboutUs"
                  className={
                    this.props.history.location.pathname === "/AboutUs"
                      ? "active"
                      : ""
                  }
                >
                  About Us
                </Link>
                {/* <Link
                  to="/Blogs"
                  className={
                    this.props.history.location.pathname === "/Blogs"
                      ? "active"
                      : ""
                  }
                >
                  Blog
                </Link> */}
                <Link
                  to="/ContactUs"
                  className={
                    this.props.history.location.pathname === "/ContactUs"
                      ? "active"
                      : ""
                  }
                >
                  Contact Us
                </Link>
                <Link
                  to="/PlanExperience"
                  className={
                    this.props.history.location.pathname === "/PlanExperience"
                      ? "active"
                      : ""
                  }
                >
                  Plan Experience
                </Link>
              </Nav>
              {/* <Form
								inline
								id="topsearch"
								className="d-xs-none d-sm-none d-md-block"
								noValidate
								validated={isSubmitted}
								onSubmit={this.formSubmitted}
							>
								<FormControl
									type="text"
									placeholder="Search"
									className="form-control"
									value={search}
									onChange={this.handleChange}
									required
									minLength="3"
								/>
								<Form.Control.Feedback type="invalid">
									Min 3 characters required
								</Form.Control.Feedback>
								<Button
									className="submit-btn"
									name="submit"
									id="submit"
									type="submit"
								>
									<FontAwesomeIcon icon={faSearch} className="arrorright" />
								</Button>
							</Form> */}

              <Nav className="logreg">
                {!userid && (
                  <>
                    <Link
                      to="/Login"
                      className={
                        this.props.history.location.pathname === "/Login"
                          ? "active"
                          : "loginbtn"
                      }
                    >
                      <span>Login</span>
                    </Link>
                    <Link
                      to="/Register"
                      className={
                        this.props.history.location.pathname === "/Register"
                          ? "active reg"
                          : "reg"
                      }
                    >
                      <span>Register</span>
                    </Link>
                  </>
                )}
                {userid > 0 && (
                  <>
                    <Nav className="logreg">
                      <DropdownButton
                        title={user_name ? user_name : ""}
                        className="icon-angle-down profile-dropdown"
                        id="testid"
                      >
                        <Dropdown.Item href="/EditProfile">
                          Edit Profile
                        </Dropdown.Item>
                        <Dropdown.Item href="/Wishlist">Wishlist</Dropdown.Item>
                        <Dropdown.Item href="/Orders">Orders</Dropdown.Item>
                        <Dropdown.Item onSelect={this.logOutUser}>
                          Logout
                        </Dropdown.Item>
                        <Dropdown.Item href="/ChangePassword">
                          Change Password
                        </Dropdown.Item>
                      </DropdownButton>
                    </Nav>
                  </>
                )}
              </Nav>
              {/* <Form
								inline
								id="topsearchphn"
								className="d-xs-block d-md-none"
								noValidate
								validated={isSubmitted}
								onSubmit={this.formSubmitted}
							>
								<FormControl
									type="text"
									placeholder="Search"
									className="form-control"
									value={search}
									onChange={this.handleChange}
									required
									minLength="3"
								/>
								<Form.Control.Feedback type="invalid">
									Min 3 characters required
								</Form.Control.Feedback>
								<Button
									className="submit-btn"
									name="submit"
									id="submit"
									type="submit"
								>
									<FontAwesomeIcon icon={faSearch} className="arrorright" />
								</Button>
							</Form> */}
            </Navbar.Collapse>
          </Navbar>
          {this.state.divoutheight ? (
            <div
              className="afterHeader"
              style={{ height: this.state.divoutheight }}
            ></div>
          ) : (
            ""
          )}
        </Container>
      </React.Fragment>
    );
  }
}
export default withRouter(Mainnavbar);
