import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ChangepasswordSuccessful.css";

class ChangepasswordSuccessful extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space logoutpage">
            <Col className=" message-box mx-auto" md={10} sm={12}>
              <div className=" mx-auto col-md-7 col-sm-12">
                <h3 className="txt-orange">Thank You</h3>
                <p>Your password has been Change successfully!</p>
                <Link to="/Login" className="cmn-btn">
                  Login
                </Link>
                <Link to="/" className="cmn-btn">
                  Home
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default ChangepasswordSuccessful;
