import React, { Component } from "react";
import { Parallax } from "react-parallax";
import "./Expdetailtopimg.css";
import { MediaBaseURL } from "../../../stores/config";

class Expdetailtopimg extends Component {
  render() {
    const { data: dt } = this.props;
    return (
      <React.Fragment>
        <Parallax
          bgImage={MediaBaseURL + dt.banner_img_path}
          bgImageAlt="Background"
          strength={200}
          className="expdetailtopimg"
        ></Parallax>
      </React.Fragment>
    );
  }
}
export default Expdetailtopimg;
