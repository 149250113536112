import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import OtpInput from "react-otp-input";
import "./RegisterComponent.css";
import axios from "axios";
import { APIbaseURL } from "../../../stores/config";
import PasswordStrengthMeter from "../../../PasswordStrengthMeter";
import { trackPromise } from "react-promise-tracker";
import cancel from "./../../images/cancel.png";
import check from "./../../images/checkmark.png";

// Update
class RegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOtp: false,
      redirect: false,
      disabled: true,
      disabledcity: true,
      disableregister: false,
      disableName: false,
      disableEmail: false,
      disablePassword: false,
      disableConfirmPass: false,
      disableMobile: false,
      disableAddress1: false,
      disableAddress2: false,
      disableCountry: false,
      disablePincode: false,
      disableAgree: false,
      disableHaveAcct: false,
      disableResendotp: true,
      countries: [],
      states: [],
      cities: [],
      pincodes: [],
      selectedCountry: { id: "0", country_name: "Select Country" },
      selectedState: { id: "0", state_name: "Select State" },
      selectedCity: { id: "0", city_name: "Select City" },
      selectedPincode: { id: "0", pincode: "Select Pincode" },
      ck_mobile: {
        status: "",
        data: "",
        msg: ""
      },
      ck_email: {
        status: "",
        data: "",
        msg: ""
      },
      resend_otp: {
        status: "",
        data: "",
        msg: ""
      },
      nameError: "",
      emailError: "",
      passwordError: "",
      confirmPassError: "",
      mobileError: "",
      //address1Error: "",
      //countryError: "",
      //stateError: "",
      //cityError: "",
      agreeError: "",
      password: ""
      //confirmPassword: "",
      //pinError: ""
    };
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.countrySelected = this.countrySelected.bind(this);
    this.stateSelected = this.stateSelected.bind(this);
    this.citySelected = this.citySelected.bind(this);
    this.pincodeSelected = this.pincodeSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.onChangeAddress1 = this.onChangeAddress1.bind(this);
    this.onChangeAddress2 = this.onChangeAddress2.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeAgree = this.onChangeAgree.bind(this);
    this.onChangePincode = this.onChangePincode.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.checkMobile = this.checkMobile.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.onChangeOtp = this.onChangeOtp.bind(this);
    this.resendOTP = this.resendOTP.bind(this);
  }

  handleToggleClick = e => {
    this.onSubmit(e);
    e.preventDefault();
  };

  componentDidMount() {
    this.fetchCountryList();
  }

  countrySelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({ selectedCountry: selectedObj }, () => {
      console.log("data country: ", this.state);
      const id = this.state.selectedCountry.id;
      this.fetchStateByCountryList(id);
      this.setState({
        selectedState: { id: "0", state_name: "Select State" }
      });
      this.setState({
        selectedCity: { id: "0", city_name: "Select City" }
      });
    });
  }

  stateSelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({ selectedState: selectedObj }, () => {
      console.log("data state: ", this.state);
      const id = this.state.selectedState.id;
      this.fetchCityByStateList(id);
      this.setState({
        selectedCity: { id: "0", city_name: "Select City" }
      });
    });
  }

  citySelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({ selectedCity: selectedObj }, () => {
      console.log("data city: ", this.state);
      const id = this.state.selectedCity.id;
      this.fetchPinByCityList(id);
    });
  }

  pincodeSelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({ selectedPincode: selectedObj }, () => {
      console.log("data pincode: ", this.state);
    });
  }

  fetchCountryList() {
    trackPromise(
      axios
        .get(APIbaseURL + "/countries")
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                countries: data.data
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in Countries List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  fetchStateByCountryList(selectedCountry) {
    trackPromise(
      axios
        .get(APIbaseURL + "/country_states/" + selectedCountry)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                states: data.data,
                disabled: false
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in States List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  fetchCityByStateList(selectedState) {
    trackPromise(
      axios
        .get(APIbaseURL + "/state_cities/" + selectedState)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                cities: data.data,
                disabledcity: false
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in Cities List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  fetchPinByCityList(selectedCity) {
    trackPromise(
      axios
        .get(APIbaseURL + "/city_pincodes/" + selectedCity)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                pincodes: data.data
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in Pincodes List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  onChangeUserName(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }
  onChangeConfirmPassword(e) {
    this.setState({
      confirmpassword: e.target.value
    });
  }
  onChangeMobile(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        mobile: e.target.value
      });
    }
  }
  onChangeAddress1(e) {
    this.setState({
      address1: e.target.value
    });
  }
  onChangeAddress2(e) {
    this.setState({
      address2: e.target.value
    });
  }
  onChangeCountry(e) {
    this.setState({
      id: e.target.value
    });
  }
  onChangeState(e) {
    this.setState({
      id: e.target.value
    });
  }
  onChangeCity(e) {
    this.setState({
      id: e.target.value
    });
  }
  onChangePincode(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        pincode: e.target.value
      });
    }
  }
  onChangeAgree(e) {
    this.setState({
      agree: e.target.value
    });
  }

  resendOTP() {
    const obj = {
      id: this.state.id
    };
    trackPromise(
      axios
        .post(APIbaseURL + "/resend_otp", obj) //JSON.stringify({ data: obj })
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                //id: data.id,
                showOtp: true,
                resend_otp: {
                  ...this.state.id,
                  status: "success",
                  msg: "A new OTP has been Resend"
                },
                disableResendotp: false
              });
              break;
            case 400:
              this.setState({
                resend_otp: {
                  ...this.state.id,
                  status: "failure",
                  msg: data.message
                }
              });
              break;
            default:
              console.log("Default block in User details");
          }
        })
        .catch(function(error) {
          console.error("Register Form error: ", error);
        })
    );
  }

  validate = () => {
    let nameError = "";
    let emailError = "";
    let passwordError = "";
    let confirmPassError = "";
    let mobileError = "";
    /*let address1Error = "";
    let countryError = "";
    let stateError = "";
    let cityError = "";
    let pinError = "";*/
    let agreeError = "";

    if (!this.state.username) {
      nameError = "Name is required";
    } else if (this.state.username.length > 50) {
      nameError = "Name exceeds 50 character";
    }

    if (!this.state.email) {
      emailError = "Email is required";
    } else if (!this.state.email.includes("@")) {
      emailError = "Invalid email";
    } else if (
      !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      emailError = "Invalid email";
    }

    if (!this.state.password) {
      passwordError = "Password is required";
    } else if (this.state.password.length > 50) {
      passwordError = "Password exceeds 50 character";
    }

    if (!this.state.mobile) {
      mobileError = "Mobile is required";
    } else if (this.state.mobile.length > 10) {
      mobileError = "Mobile exceeds 10 character";
    } else if (this.state.mobile.length < 10) {
      mobileError = "Mobile should have 10 character";
    }

    /*
    if (!this.state.address1) {
      address1Error = "Address is required";
    } else if (this.state.address1.length > 100) {
      address1Error = "Address1 exceeds 100 character";
    }

    if (this.state.selectedCountry.id <= 0) {
      countryError = "Country is required";
    }

    if (this.state.selectedState.id <= 0) {
      stateError = "State is required";
    }

    if (this.state.selectedCity.id <= 0) {
      cityError = "City is required";
    }

    if (!this.state.pincode) {
      pinError = "Pincode is required";
    } else if (this.state.pincode.length > 6) {
      pinError = "Pincode accepts only 6 digit";
    } else if (this.state.pincode.length < 6) {
      pinError = "Pincode requires 6 digits";
    }
	*/

    if (!this.state.agree) {
      agreeError = "Please Agree to our Terms of Use";
    }

    //const { password, confirmPassword } = this.state;

    if (!this.state.confirmpassword) {
      confirmPassError = "Confirm Password required";
    } else if (this.state.password !== this.state.confirmpassword) {
      confirmPassError = "Confirm Password does not match";
    } else {
      confirmPassError = "";
    }

    if (
      emailError ||
      nameError ||
      passwordError ||
      confirmPassError ||
      mobileError ||
      //address1Error ||
      //countryError ||
      //stateError ||
      //cityError ||
      agreeError //||
      // pinError
    ) {
      this.setState({
        emailError,
        nameError,
        passwordError,
        confirmPassError,
        mobileError,
        //address1Error,
        //countryError,
        //stateError,
        //cityError,
        agreeError
        // pinError
      });
      return false;
    }
    this.setState({
      emailError,
      nameError,
      passwordError,
      confirmPassError,
      mobileError,
      //address1Error,
      //countryError,
      //stateError,
      //cityError,
      agreeError
      // pinError
    });
    return true;
  };

  onSubmit(e) {
    e.preventDefault();
    const isValid = this.validate();

    if (isValid) {
      const obj = {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        confirmpassword: this.state.confirmpassword,
        mobile: this.state.mobile,
        address1: this.state.address1,
        address2: this.state.address2,
        countryid: this.state.selectedCountry.id,
        stateid: this.state.selectedState.id,
        cityid: this.state.selectedCity.id,
        pincode: this.state.pincode
      };
      trackPromise(
        axios
          .post(APIbaseURL + "/register", obj) //JSON.stringify({ data: obj })
          .then(response => {
            const { data } = response;
            switch (data.code) {
              case 200:
                this.setState({
                  id: data.id,
                  showOtp: true,
                  disableregister: true,
                  disableName: true,
                  disableEmail: true,
                  disablePassword: true,
                  disableConfirmPass: true,
                  disableMobile: true,
                  disableAddress1: true,
                  disableAddress2: true,
                  disableCountry: true,
                  disabled: true,
                  disabledcity: true,
                  disablePincode: true,
                  disableAgree: true,
                  disableHaveAcct: true
                });
                break;
              case 400:
                break;
              default:
                console.log("Default block in User details");
            }
          })
          .catch(function(error) {
            console.error("Experiences List error: ", error);
          })
      );
    }
  }

  checkEmail(e) {
    e.preventDefault();

    if (e.target.value.length < 150) {
      if (e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        const obj = {
          email: this.state.email
        };
        trackPromise(
          axios.post(APIbaseURL + "/check_email", obj).then(res => {
            const { data } = res;
            switch (data.code) {
              case 200:
                this.setState({
                  focus: true,
                  ck_email: {
                    ...this.state.email,
                    status: "success",
                    msg: data.message
                  },
                  emailError: ""
                });
                break;
              case 400:
                this.setState({
                  focus: false,
                  //email: "",
                  ck_email: {
                    ...this.state.email,
                    status: "failure",
                    msg: data.message
                  },
                  emailError: ""
                });
                break;
              default:
                console.log("Default block in Experience details");
            }
          })
        );
      } else {
        this.setState({
          emailError: "Invalid Email",
          ck_email: {
            ...this.state.email,
            status: "failure",
            msg: ""
          }
        });
      }
    } else {
      this.setState({
        emailError: "Email Exceeds 150 character"
      });
    }
  }

  checkMobile(e) {
    e.preventDefault();
    if (e.target.value.length === 10) {
      const obj = {
        mobile: this.state.mobile
      };
      trackPromise(
        axios
          .post(APIbaseURL + "/check_mobile", obj) //JSON.stringify({ data: obj })
          .then(res => {
            const { data } = res;
            switch (data.code) {
              case 200:
                this.setState({
                  ck_mobile: {
                    ...this.state.mobile,
                    status: "success",
                    msg: data.message
                  },
                  mobileError: ""
                });
                break;
              case 400:
                this.setState({
                  //mobile: "",
                  ck_mobile: {
                    ...this.state.mobile,
                    status: "failure",
                    msg: data.message
                  },
                  mobileError: ""
                });

                break;
              default:
                console.log("Default block in Experience details");
            }
          })
      );
    } else if (e.target.value.length < 10) {
      this.setState({
        mobileError: "Mobile should have 10 character",
        ck_mobile: {
          ...this.state.mobile,
          status: "failure",
          msg: ""
        }
      });
    } else if (e.target.value.length > 10) {
      this.setState({
        mobileError: "Only 10 digit accepted",
        ck_mobile: {
          ...this.state.mobile,
          status: "failure",
          msg: ""
        }
      });
    }
  }

  onChangeOtp(e) {
    this.setState({
      otp: e
    });
  }

  onChangeUserid(e) {
    this.setState({
      id: e.target.value
    });
  }

  verifyOtp(e) {
    e.preventDefault();
    if (this.state.otp && this.state.otp !== "") {
      this.setState({
        otperror: ""
      });
      const obj = {
        id: this.state.id,
        otp: this.state.otp
      };
      trackPromise(
        axios
          .put(APIbaseURL + "/verify_otp", obj)
          .then(response => {
            const { data } = response;
            switch (data.code) {
              case 200:
                this.setState({
                  redirect: true
                });
                break;
              case 400:
                this.setState({
                  otperror: "Invalid OTP, Please enter correct OTP.",
                  resend_otp: ""
                });
                break;
              default:
                console.log("Default block in User details");
            }
          })
          .catch(function(error) {
            console.error("Experiences List error: ", error);
          })
      );
    } else {
      this.setState({
        otperror: "Please Enter OTP."
      });
    }
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/RegThankyou" />;
    }

    const { ck_mobile: mob } = this.state;
    const { ck_email: em } = this.state;
    const { resend_otp: rotp } = this.state;

    const { password } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space">
            <Col className=" message-box" md={10} sm={12}>
              <Col className="mx-auto register-form" md={10} sm={12}>
                <h3 className="txt-orange">Register With The Soul Company</h3>
                <Form className="regform" onSubmit={this.onSubmit}>
                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridName">
                        <Form.Control
                          type="text"
                          placeholder="*Your Name"
                          value={this.state.username || ""}
                          onChange={this.onChangeUserName}
                          onBlur={this.nameLength}
                          maxLength="50"
                          disabled={this.state.disableName}
                        />
                        <div className="errorMsg">{this.state.nameError}</div>
                      </Form.Group>
                    </Form.Row>
                    <div className="d-flex-phn">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Control
                          type="email"
                          placeholder="*Your email address"
                          value={this.state.email || ""}
                          onChange={this.onChangeEmail}
                          onBlur={this.checkEmail}
                          disabled={this.state.disableEmail}
                        />
                        <div className="errorMsg">{this.state.emailError}</div>
                      </Form.Group>
                    </Form.Row>
                    <div className="d-flex">
                      <div id="unavailable_error">
                        {em.status === "success" && (
                          <Form.Text className="avai_text">
                            {/* {em.msg} */}
                            <img
                              src={check}
                              alt="Logo"
                              className="check-image"
                            />
                          </Form.Text>
                        )}
                        {em.status === "failure" && (
                          <Form.Text className="err_text">
                            {/* {em.msg} */}
                            <img
                              src={cancel}
                              alt="Logo"
                              className="cancel-image"
                            />
                          </Form.Text>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Control
                          type="password"
                          placeholder="*Choose password"
                          value={this.state.password || ""}
                          onChange={this.onChangePassword}
                          maxLength="50"
                          disabled={this.state.disablePassword}
                        />
                        <div className="errorMsg">
                          {this.state.passwordError}
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridConfPassword">
                        <Form.Control
                          type="password"
                          placeholder="*Confirm password"
                          value={this.state.confirmpassword || ""}
                          onChange={this.onChangeConfirmPassword}
                          maxLength="50"
                          disabled={this.state.disableConfirmPass}
                        />
                        <div className="errorMsg">
                          {this.state.confirmPassError}
                        </div>
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <div className="fieldError">
                    {/* <ProgressBar variant="danger" now={30} />
                    <div className="errormsg">Too Weak</div> */}
                    <PasswordStrengthMeter password={password} />
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridMobile">
                        <Form.Control
                          type="text"
                          placeholder="*Mobile number"
                          value={this.state.mobile || ""}
                          onChange={this.onChangeMobile}
                          onBlur={this.checkMobile}
                          maxLength="10"
                          minLength="10"
                          disabled={this.state.disableMobile}
                        />
                        <div className="errorMsg">{this.state.mobileError}</div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <div id="unavailable_error">
                        {mob.status === "success" && (
                          <Form.Text className="avai_text">
                            {/* {mob.msg} */}
                            <img
                              src={check}
                              alt="Logo"
                              className="check-image"
                            />
                          </Form.Text>
                        )}
                        {mob.status === "failure" && (
                          <Form.Text className="err_text">
                            {/* {mob.msg} */}
                            <img
                              src={cancel}
                              alt="Logo"
                              className="cancel-image"
                            />
                          </Form.Text>
                        )}
                      </div>
                    </Form.Row>
                  </div>
                  {/* <div className="d-flex">
                    
                  </div> */}
                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridaddress1">
                        <Form.Control
                          type="text"
                          placeholder="Address Line 1"
                          value={this.state.address1 || ""}
                          onChange={this.onChangeAddress1}
                          maxLength="100"
                          disabled={this.state.disableAddress1}
                        />
                        <div className="errorMsg">
                          {this.state.address1Error}
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridaddress2">
                        <Form.Control
                          type="text"
                          placeholder="Address Line 2"
                          value={this.state.address2 || ""}
                          onChange={this.onChangeAddress2}
                          maxLength="100"
                          disabled={this.state.disableAddress2}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <DropdownButton
                        title={this.state.selectedCountry.country_name}
                        className="icon-angle-down regdrpdwn"
                        onSelect={this.countrySelected}
                        value={this.state.selectedCountry.id}
                        onChange={this.onChangeCountry}
                        disabled={this.state.disableCountry}
                      >
                        {this.state.countries.map(item => (
                          <Dropdown.Item
                            key={item.id}
                            eventKey={JSON.stringify(item)}
                          >
                            {item.country_name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <div className="errorMsg">{this.state.countryError}</div>
                    </Form.Row>
                    <Form.Row>
                      <DropdownButton
                        title={this.state.selectedState.state_name}
                        className="icon-angle-down regdrpdwn"
                        onSelect={this.stateSelected}
                        value={this.state.selectedState.id}
                        onChange={this.onChangeState}
                        disabled={this.state.disabled}
                      >
                        {this.state.states.map(item => (
                          <Dropdown.Item
                            key={item.id}
                            eventKey={JSON.stringify(item)}
                          >
                            {item.state_name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <div className="errorMsg">{this.state.stateError}</div>
                    </Form.Row>
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <DropdownButton
                        title={this.state.selectedCity.city_name}
                        className="icon-angle-down regdrpdwn"
                        onSelect={this.citySelected}
                        value={this.state.selectedCity.id}
                        onChange={this.onChangeCity}
                        disabled={this.state.disabledcity}
                      >
                        {this.state.cities.map(item => (
                          <Dropdown.Item
                            key={item.id}
                            eventKey={JSON.stringify(item)}
                          >
                            {item.city_name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <div className="errorMsg">{this.state.cityError}</div>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        controlId="formGridMobile"
                        className="pincodeinput"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Pincode"
                          value={this.state.pincode || ""}
                          onChange={this.onChangePincode}
                          maxLength="6"
                          minLength="6"
                          disabled={this.state.disablePincode}
                        />
                        <div className="errorMsg">{this.state.pinError}</div>
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group>
                        <Link to="TermsofUse" target="_blank">
                          <Form.Check
                            type="checkbox"
                            label="I Agree to the Terms of Use "
                            onChange={this.onChangeAgree}
                            disabled={this.state.disableAgree}
                          />
                        </Link>
                        <div className="errorMsg">{this.state.agreeError}</div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group className="regac">
                        Already Have An Account?
                        <Link
                          to="/login"
                          className="txt-orange"
                          disabled={this.state.disableHaveAcct}
                          style={
                            this.state.disableHaveAcct
                              ? { pointerEvents: "none" }
                              : null
                          }
                        >
                          Login!
                        </Link>
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <Form.Row>
                    <Form.Group>
                      <Button
                        className="cmn-btn"
                        type="submit"
                        onClick={this.handleToggleClick}
                        disabled={this.state.disableregister}
                      >
                        Register
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form>

                {this.state.showOtp && (
                  <>
                    <Form className="regform" onSubmit={this.verifyOtp}>
                      <Form.Row>
                        <Form.Group id="formOtp" className="otp-no">
                          <Form.Label>Enter Your OTP</Form.Label>
                          <div className="otpinput">
                            <OtpInput
                              numInputs={6}
                              separator={<span></span>}
                              value={this.state.otp}
                              onChange={this.onChangeOtp}
                            />
                          </div>
                          {this.state.disableResendotp && (
                            <>
                              <Link to="#" onClick={this.resendOTP}>
                                {" "}
                                Resend OTP{" "}
                              </Link>
                            </>
                          )}
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <div className="d-flex">
                          <div id="unavailable_error">
                            {em.status === "success" && (
                              <Form.Text className="avai_text">
                                {rotp.msg}
                              </Form.Text>
                            )}
                            {em.status === "failure" && (
                              <Form.Text className="err_text">
                                {rotp.msg}
                              </Form.Text>
                            )}
                          </div>
                        </div>
                        <div className="errorMsg">{this.state.otperror}</div>
                        <div className="f-flex">
                          <Form.Group>
                            <Button className="cmn-btn" type="submit">
                              Submit
                            </Button>
                          </Form.Group>
                        </div>
                      </Form.Row>
                    </Form>
                  </>
                )}
              </Col>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default RegisterComponent;
