import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import PrivacyPolicy from "../component/TermsAndPolicies/PrivacyPolicy/PrivacyPolicy";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class PrivacyPolicypage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <div>
        <TSCmeta
          title="Privacy Policy - The Soul Company"
          description="Privacy policy of The Soul Company"
          keywords="privacy policy"
          og_title="Privacy Policy - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Privacy policy of The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <PrivacyPolicy />
        <Subscribe />
        <Footer />
      </div>
    );
  }
}
export default PrivacyPolicypage;
