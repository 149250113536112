import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./PrivacyPolicy.css";
// import { format } from "util";

class PrivacyPolicy extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row>
						<Col sm="12">
							<h2>Privacy Policy</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row className="message-box equal-space termspolicieswrap">
						<ScrollAnimation
							animateIn="fadeIn"
							delay={100}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<p>
									We value the trust you place in us. That's why we insist upon
									the highest standards for secure transactions and customer
									information privacy. Please read the following statement to
									learn about our information gathering and dissemination
									practices. Note: Our privacy policy is subject to change at
									any time without notice. To make sure you are aware of any
									changes, please review this policy periodically.
								</p>
								<p>
									By visiting this Website you agree to be bound by the terms
									and conditions of this Privacy Policy. If you do not agree
									please do not use or access our Website.
								</p>
								<p>
									By mere use of the Website, you expressly consent to our use
									and disclosure of your personal information in accordance with
									this Privacy Policy. This Privacy Policy is incorporated into
									and subject to the Terms of Use.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={200}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies top-space" sm="12">
								<h3 className="txt-orange">
									1. Collection of Personally Identifiable Information and other
									Information
								</h3>
								<ul>
									<li>
										When you use our Website, we collect and store your personal
										information which is provided by you from time to time. Our
										primary goal in doing so is to provide you a safe,
										efficient, smooth and customized experience. This allows us
										to provide services and features that most likely meet your
										needs, and to customize our Website to make your experience
										safer and easier. More importantly, while doing so we
										collect personal information from you that we consider
										necessary for achieving this purpose.
									</li>
									<li>
										In general, you can browse the Website without telling us
										who you are or revealing any personal information about
										yourself. Once you give us your personal information, you
										are not anonymous to us. Where possible, we indicate which
										fields are required and which fields are optional. You
										always have the option to not provide information by
										choosing not to use a particular service or feature on the
										Website. We may automatically track certain information
										about you based upon your behaviour on our Website. We use
										this information to do internal research on our users'
										demographics, interests, and behaviour to better understand,
										protect and serve our users. This information is compiled
										and analysed on an aggregated basis. This information may
										include the URL that you just came from (whether this URL is
										on our Website or not), which URL you next go to (whether
										this URL is on our Website or not), your computer browser
										information, and your IP address.
									</li>
									<li>
										We use data collection devices such as "cookies" on certain
										pages of the Website to help analyse our web page flow,
										measure promotional effectiveness, and promote trust and
										safety. "Cookies" are small files placed on your hard drive
										that assist us in providing our services. We offer certain
										features that are only available through the use of a
										"cookie".
									</li>
									<li>
										We also use cookies to allow you to enter your password less
										frequently during a session. Cookies can also help us
										provide information that is targeted to your interests. Most
										cookies are "session cookies," meaning that they are
										automatically deleted from your hard drive at the end of a
										session. You are always free to decline our cookies if your
										browser permits, although in that case you may not be able
										to use certain features on the Website and you may be
										required to re-enter your password more frequently during a
										session.
									</li>
									<li>
										Additionally, you may encounter "cookies" or other similar
										devices on certain pages of the Website that are placed by
										third parties. We do not control the use of cookies by third
										parties.
									</li>
									<li>
										If you choose to buy on the Website, we collect information
										about your buying behaviour.
									</li>
									<li>
										If you transact with us, we collect some additional
										information, such as a billing address, a credit / debit
										card number and a credit / debit card expiration date and/
										or other payment instrument details and tracking information
										from cheques or money orders.
									</li>
									<li>
										If you choose to post messages on our message boards, chat
										rooms or other message areas or leave feedback, we will
										collect that information you provide to us. We retain this
										information as necessary to resolve disputes, provide
										customer support and troubleshoot problems as permitted by
										law.
									</li>
									<li>
										If you send us personal correspondence, such as emails or
										letters, or if other users or third parties send us
										correspondence about your activities or postings on the
										Website, we may collect such information into a file
										specific to you.
									</li>
									<li>
										We collect personally identifiable information (email
										address, name, phone number, credit card / debit card /
										other payment instrument details, etc.) from you when you
										set up a free account with us. While you can browse some
										sections of our Website without being a registered member,
										certain activities (such as placing an order) do require
										registration. We do use your contact information to send you
										offers based on your previous orders and your interests.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={300}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">
									2. Use of Demographic / Profile Data / Your Information
								</h3>
								<ul>
									<li>
										We use personal information to provide the services you
										request. To the extent we use your personal information to
										market to you, we will provide you the ability to opt-out of
										such uses. We use your personal information to resolve
										disputes; troubleshoot problems; help promote a safe
										service; collect money; measure consumer interest in our
										products and services, inform you about online and offline
										offers, products, services, and updates; customize your
										experience; detect and protect us against error, fraud and
										other criminal activity; enforce our terms and conditions;
										and as otherwise described to you at the time of collection.
									</li>
									<li>
										In our efforts to continually improve our product and
										service offerings, we collect and analyse demographic and
										profile data about our users' activity on our Website.
									</li>
									<li>
										We identify and use your IP address to help diagnose
										problems with our server, and to administer our Website.
										Your IP address is also used to help identify you and to
										gather broad demographic information.
									</li>
									<li>
										We will occasionally ask you to complete optional online
										surveys. These surveys may ask you for contact information
										and demographic information (like zip code, age, or income
										level). We use this data to tailor your experience at our
										Website, providing you with content that we think you might
										be interested in and to display content according to your
										preferences.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={400}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Cookies</h3>
								<ul>
									<li>
										A "cookie" is a small piece of information stored by a web
										server on a web browser so it can be later read back from
										that browser. Cookies are useful for enabling the browser to
										remember information specific to a given user. We place both
										permanent and temporary cookies in your computer's hard
										drive. The cookies do not contain any of your personally
										identifiable information.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={500}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">
									3. Sharing of personal information
								</h3>
								<ul>
									<li>
										We may share personal information with our other corporate
										entities and affiliates. These entities and affiliates may
										market to you as a result of such sharing unless you
										explicitly opt-out.
									</li>
									<li>
										We may disclose personal information if required to do so by
										law or in the good faith belief that such disclosure is
										reasonably necessary to respond to subpoenas, court orders,
										or other legal process. We may disclose personal information
										to law enforcement offices, third party rights owners, or
										others in the good faith belief that such disclosure is
										reasonably necessary to: enforce our Terms or Privacy
										Policy; respond to claims that an advertisement, posting or
										other content violates the rights of a third party; or
										protect the rights, property or personal safety of our users
										or the general public.
									</li>
									<li>
										We and our affiliates will share / sell some or all of your
										personal information with another business entity should we
										(or our assets) plan to merge with, or be acquired by that
										business entity, or re-organization, amalgamation,
										restructuring of business. Should such a transaction occur
										that other business entity (or the new combined entity) will
										be required to follow this privacy policy with respect to
										your personal information.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={600}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">4. Links to Other Sites</h3>
								<ul>
									<li>
										Our Website links to other websites that may collect
										personally identifiable information about you
										thesoulcompany.in is not responsible for the privacy
										practices or the content of those linked websites.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={700}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">5. Security Precautions</h3>
								<ul>
									<li>
										Our Website has stringent security measures in place to
										protect the loss, misuse, and alteration of the information
										under our control. Whenever you change or access your
										account information, we offer the use of a secure server.
										Once your information is in our possession we adhere to
										strict security guidelines, protecting it against
										unauthorized access.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={800}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">6. Choice/Opt-Out</h3>
								<ul>
									<li>
										We provide all users with the opportunity to opt-out of
										receiving non-essential (promotional, marketing-related)
										communications from us on behalf of our partners, and from
										us in general, after setting up an account.
									</li>
									<li>
										If you want to remove your contact information from all
										thesoulcompany.in lists and newsletters, please click on
										unsubscribe in the email you receive.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={900}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">
									7. Advertisements on thesoulcompany.in
								</h3>
								<ul>
									<li>
										We may use third-party advertising companies to serve ads
										when you visit our Website. These companies may use
										information (not including your name, address, email
										address, or telephone number) about your visits to this and
										other websites in order to provide advertisements about
										goods and services of interest to you.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={1000}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">8. Your Consent</h3>
								<ul>
									<li>
										By using the Website and/ or by providing your information,
										you consent to the collection and use of the information you
										disclose on the Website in accordance with this Privacy
										Policy, including but not limited to Your consent for
										sharing your information as per this privacy policy.
									</li>
									<li>
										If we decide to change our privacy policy, we will post
										those changes on this page so that you are always aware of
										what information we collect, how we use it, and under what
										circumstances we disclose it.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={1100}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">9. Grievance Officer</h3>
								<ul>
									<li>
										In accordance with Information Technology Act 2000 and rules
										made there under, the name and contact details of the
										Grievance Officer are provided below:
									</li>
									<li>
										Mr. PM Somanna
										<br />
										Soul Leisure Pvt. Ltd
										<br />
										No. 111, Jal Vayu Vihar
										<br />
										Kacharakanahalli, Bengaluru
										<br />
										Karnataka - 560043
										<br />
									</li>
									<li>
										Phone: &nbsp;<a href="tel:91 8861395567">+91 8861395567</a>
										<br />
										Email:&nbsp;
										<a href="mailto:hello@thesoulcompany.in" target="_blank" rel="noopener noreferrer">
											hello@thesoulcompany.in
										</a>
										<br />
										Time: Mon - Fri (11:00 - 18:00)
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={1200}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">10. Questions?</h3>
								<ul>
									<li>
										Please contact us regarding any questions regarding this
										statement.
									</li>
								</ul>
							</Col>
						</ScrollAnimation>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default PrivacyPolicy;
