import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import PlanExperienceThankyou from "../component/RegisterComponent/PlanExperienceThankyou/PlanExperienceThankyou";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class PlanExperienceThankyouPage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Plan Experience Thank You - The Soul Company"
          description="thank you or success page for sharing experience plan"
          keywords="plan experience thank you, plan trip success, plan vacation thank you"
          og_title="Plan Experience Thank You - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="thank you or success page for sharing experience plan"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <PlanExperienceThankyou />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default PlanExperienceThankyouPage;
