import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Login.css";
import axios from "axios";
import { APIbaseURL } from "../../../stores/config";
import Cookies from "js-cookie";
import { Redirect } from "react-router";
import { trackPromise } from "react-promise-tracker";
import { createBrowserHistory } from "history";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      redirect_home: false,
      redirect_path: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRememberMe = this.onChangeRememberMe.bind(this);
    this.routeHistory = createBrowserHistory();
    this.userid = Cookies.get("userid");
    this.pathToRedirect = "";
  }

  onChangeUserName(e) {
    this.setState({
      email: e.target.value
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeRememberMe() {
    this.setState(
      {
        rememberMe: !this.state.rememberMe
      },
      () => {
        let local_tsc = localStorage.getItem("tsc_data")
          ? JSON.parse(localStorage.getItem("tsc_data"))
          : {};
        let local_tsc_updated = {};
        if (this.state.rememberMe) {
          local_tsc_updated = { ...local_tsc, remember_me: this.state.email };
          localStorage.setItem("tsc_data", JSON.stringify(local_tsc_updated));
        } else {
          local_tsc_updated = { ...local_tsc, remember_me: "" };
          localStorage.setItem("tsc_data", JSON.stringify(local_tsc_updated));
        }
      }
    );
  }

  verifyLoginStatus() {
    if (this.userid) this.redirectToHome();
  }

  verifyRememberMe() {
    let local_tsc = localStorage.getItem("tsc_data")
      ? JSON.parse(localStorage.getItem("tsc_data"))
      : {};
    if (local_tsc.remember_me) {
      this.setState({
        rememberMe: true,
        email: local_tsc.remember_me
      });
    }
  }

  componentDidMount() {
    this.verifyLoginStatus();
    this.verifyRememberMe();
  }

  redirectToHome() {
    this.setState({
      redirect_home: true
    });
  }

  redirectToPath() {
    this.setState({
      redirect_path: true
    });
  }

  redirectRoutes() {
    const { location: ln } = this.routeHistory;
    if (ln.state) {
      if (ln.state.redirectTo) {
        this.pathToRedirect = ln.state.redirectTo;
        this.redirectToPath();
      }
    } else this.redirectToHome();
  }

  onSubmit(e) {
    e.preventDefault();

    if (!this.state.email) {
      this.setState({
        validationError: "Please enter email."
      });
    } else if (!this.state.password) {
      this.setState({
        validationError: "Please enter password."
      });
    } else {
      const obj = {
        username: this.state.email,
        password: this.state.password
      };
      trackPromise(
        axios.post(APIbaseURL + "/login", JSON.stringify(obj)).then(res => {
          const { data } = res;
          this.getUserInfo(data.id);
          Cookies.set("userid", data.id);
          Cookies.set("username", data.email);
          switch (data.code) {
            case 400:
            case 403:
              this.setState({
                validationError: data.message
              });
              Cookies.remove("userid");
              Cookies.remove("username");
              Cookies.remove("user_name");
              Cookies.remove("pyramids_social_token");
              break;
            default:
              console.log("Default block in User Info");
          }
        })
      );
    }
  }

  getUserInfo(id) {
    trackPromise(
      axios.get(APIbaseURL + "/get_user_info/" + id).then(res => {
        const { code, data } = res.data;
        switch (code) {
          case 200:
            Cookies.set("user_name", data[0].name);
            this.redirectRoutes();
            break;
          case 400:
            break;
          default:
            console.log("Default block in User Info");
        }
      })
    );
  }

  render() {
    const { redirect_home, redirect_path } = this.state;
    if (redirect_home) {
      return <Redirect to="/" />;
    } else if (redirect_path) {
      return (
        <Redirect
          to={{
            pathname: this.pathToRedirect.pathname,
            state: this.pathToRedirect.state
          }}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Container>
            <Row className="equal-space loginpage">
              <Col className=" message-box" md={10} sm={12}>
                <div className=" mx-auto col-md-7 col-sm-12">
                  <h3 className="txt-orange">Login</h3>
                  <Form
                    className="register-form registerpage"
                    onSubmit={this.onSubmit}
                  >
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Control
                          type="email"
                          placeholder="Your email address"
                          value={this.state.email}
                          onChange={this.onChangeUserName}
                          maxLength="150"
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Control
                          type="password"
                          placeholder="Choose password"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                          maxLength="50"
                        />
                        <div className="err_text">
                          {this.state.validationError}
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <div className="d-flex">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Remember me"
                          checked={this.state.rememberMe}
                          disabled={!this.state.email}
                          onChange={this.onChangeRememberMe}
                        />
                      </Form.Group>
                      <Form.Group className="regac">
                        Forgot Your
                        <Link
                          to="/ForgotPassword"
                          className="txt-orange forgotpasswordbtn"
                        >
                          Password?
                        </Link>
                      </Form.Group>
                    </div>
                    <Form.Row>
                      <Form.Group>
                        <Button className="cmn-btn" type="submit">
                          Login
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  {/* <div className="d-flex">
                    <Form.Group>
                      <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <Form.Group className="regac">
                      Forgot Your
                      <Link
                        to="/ForgotPassword"
                        className="txt-orange forgotpasswordbtn"
                      >
                        Password?
                      </Link>
                    </Form.Group>
                  </div> */}
                  {/* <Link to="/login" className="cmn-btn login-btn">
                    Login
                  </Link> */}
                </div>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}
export default Login;
