import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./Explistingtitle.css";

class Explistingtitle extends Component {
    render () {
        return (
            <React.Fragment>
                <Container fluid={true} className="explistingtitle">
                    <Row className="justify-content-md-center">
                        <Col xs="12" >
                            <h2>Explore Experiences</h2>
                            <ScrollAnimation animateIn="line" delay={100} duration={3}>
                                <span></span>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
export default Explistingtitle;