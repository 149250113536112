import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import ExpOrderReview from "../component/ExpComponent/ExpOrderReview/ExpOrderReview";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class ExpOrderReviewPage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Experience Order Review"
          description="Review of experience booking order"
          keywords="booking, order, review"
          og_title="Experience Order Review"
          og_url={this.PAGE_URL}
          og_description="Review of experience booking order"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <ExpOrderReview />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default ExpOrderReviewPage;
