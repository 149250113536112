import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import OrderDetailsList from "../component/RegisterComponent/OrderDetailsList/OrderDetailsList";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";
import Cookies from "js-cookie";
import { createBrowserHistory } from "history";
import axios from "axios";
import { APIbaseURL } from "../stores/config";
import { trackPromise } from "react-promise-tracker";
import { CustomAlert, CustomConfirmBox } from "../common.functions";

class OrderDetailspage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.routeHistory = createBrowserHistory();
    this.userId = Cookies.get("userid");
    if (!this.userId) {
      this.routeHistory.replace({ pathname: "/login" });
      this.routeHistory.go(0);
    }
    this.orderId = this.props.match.params.orderId;
    this.state = {
      orderDetails: {},
      price_request: "",
      disableCancel: false,
      alert_show: false,
      alert_message: "",
      alert_variant: "",
      confirm_show: false,
      confirm_message: "",
      confirm_data: null
    };
    this.cancelOrder = this.cancelOrder.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.confirmCancelOrder = this.confirmCancelOrder.bind(this);
  }

  toggleAlert(alert_show, alert_message = "", alert_variant = "") {
    this.setState((prevState, prevProps) => {
      alert_show = alert_show ? alert_show : !prevState.alert_show;
      return { alert_show, alert_message, alert_variant };
    });
  }

  toggleConfirm(e) {
    let confirm_message = e ? `Do you want to cancel the order?` : "";
    this.setState((prevState, prevProps) => {
      return {
        confirm_show: !prevState.confirm_show,
        confirm_message
      };
    });
  }

  confirmCancelOrder() {
    this.toggleConfirm();
    this.cancelOrder();
  }

  componentDidMount() {
    this.fetchOrderDetails();
  }

  fetchOrderDetails() {
    trackPromise(
      axios
        .get(APIbaseURL + "/order_details/" + this.orderId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState_success(data.data[0]);
              break;
            case 400:
              break;
            default:
              console.log("Default block in Order details");
          }
        })
        .catch(function(error) {
          console.error("Order details error: ", error);
        })
    );
  }

  setState_success(data) {
    let disableCancel = null;
    // let price_request = Number(data.price_onrequest) ? 'Price on Request' : '';
    let price_request = "";
    switch (data.order_status) {
      case "2":
      case "3":
      case "6":
      case "7":
        disableCancel = true;
        break;
      case "4":
        disableCancel = false;
        data.order_status_name = "Approved/Payment Pending";
        break;
      default:
        disableCancel = false;
    }

    this.setState({
      orderDetails: data,
      disableCancel,
      price_request
    });
  }

  cancelOrder() {
    trackPromise(
      axios
        .get(APIbaseURL + "/cancel_order/" + this.orderId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState(
                {
                  orderDetails: {
                    ...this.state.orderDetails,
                    order_status: "2",
                    order_status_name: "Canceled"
                  },
                  disableCancel: true
                },
                () => {
                  this.toggleAlert(true, data.message, "suces");
                }
              );
              break;
            case 400:
              this.toggleAlert(true, data.data, "failr");
              break;
            case 409:
              this.toggleAlert(true, data.message, "failr");
              break;
            default:
              console.log("Default block in Order Cancel");
          }
        })
        .catch(function(error) {
          console.error("Order Cancel error: ", error);
        })
    );
  }

  render() {
    const {
      orderDetails,
      price_request,
      disableCancel,
      alert_show,
      alert_message,
      alert_variant,
      confirm_show,
      confirm_message
    } = this.state;
    return (
      <React.Fragment>
        <TSCmeta
          title="OrderDetails - The Soul Company"
          description="Order details page of The Soul Company"
          keywords="order details, booking details"
          og_title="OrderDetails - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Order details page of The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <CustomAlert
          show={alert_show}
          message={alert_message}
          closeAlert={this.toggleAlert}
          variant={alert_variant}
        />
        <CustomConfirmBox
          m_show={confirm_show}
          m_title={"Confirm Cancel"}
          m_message={confirm_message}
          m_close={this.toggleConfirm}
          m_confirm={this.confirmCancelOrder}
        />
        <OrderDetailsList
          userId={this.userId}
          orderId={this.orderId}
          details={orderDetails}
          price_request={price_request}
          disableCancel={disableCancel}
          cancelOrder={this.toggleConfirm}
        />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default OrderDetailspage;
