import { createStore } from "redux";
import { APIbaseURL } from "./config";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

export const types = {
  GET_EXPERIENCES_BY_SEARCH: "GET_EXPERIENCES_BY_SEARCH"
};

let initialState = {
  search_exp: {
    value: "",
    isInvalid: true,
    isLoading: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXPERIENCES_BY_SEARCH:
      return {
        ...state,
        search_exp: { ...state.search_exp, isPending: false, successMsg: "" }
      };
    default:
      return state;
  }
};

export default createStore(reducer);

export const asynchActions = {
  getExperiencesBySearch
};

function getExperiencesBySearch(dispatch, search) {
  trackPromise(
    axios
      .get(APIbaseURL + "/experiences_by_search/" + search)
      .then(response => {
        dispatch({
          type: types.GET_EXPERIENCES_BY_SEARCH,
          payload: response.data
        });
      })
      .catch(function(error) {
        console.error("Experiences by search error: ", error);
      })
  );
}
