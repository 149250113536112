import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import Explistingtitle from "../component/Explistingpage/Explistingtitle/Explistingtitle";
import Searchfilter from "../component/ExpComponent/Searchfilter/Searchfilter";
import Explistingpage from "../component/Explistingpage/Explistingpage";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import axios from "axios";
import { APIbaseURL } from "../stores/config";
import { trackPromise } from "react-promise-tracker";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import { CustomAlert } from "../common.functions";
import { TSCmeta } from "../common.functions";

const ITEMS_PER_ROW = 3;

class Explisting extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.experiences = [];
    this.filtersCache = {};
    this.initialState = {
      searchedExps: [],
      expSearch: {
        categories: [],
        cities: [],
        guests: [],
        experts: [],
        selected_cat: { id: "0", name: "Category" },
        selected_cty: { id: "0", name: "City" },
        selected_gst: { id: "0", name: "Guests" },
        selected_exprt: { id: "0", name: "Expert" },
        filterSelected: true
      },
      expList: this.initExpList([]),
      userId: Cookies.get("userid"),
      alert_show: false,
      alert_message: "",
      alert_variant: ""
    };
    this.state = { ...this.initialState };
    this.expSearchFn = {
      catSelected: this.catSelected.bind(this),
      citySelected: this.citySelected.bind(this),
      guestSelected: this.guestSelected.bind(this),
      expertSelected: this.expertSelected.bind(this),
      searchClicked: this.searchClicked.bind(this)
    };
    this.expListFn = {
      handleShow: this.handleShow.bind(this),
      handleSort: this.handleSort.bind(this),
      resetExperiences: this.resetExperiences.bind(this),
      updateToWishList: this.updateToWishList.bind(this)
    };
    this.routeHistory = createBrowserHistory();
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  initExpList(data) {
    return {
      showItems: ITEMS_PER_ROW,
      showList: data.slice(0, ITEMS_PER_ROW),
      showText: "Show More"
    };
  }

  toggleAlert(alert_show, alert_message = "", alert_variant) {
    this.setState((prevState, prevProps) => {
      alert_show = alert_show ? alert_show : !prevState.alert_show;
      return { alert_show, alert_message, alert_variant };
    });
  }

  resetExperiences(e) {
    e.preventDefault();
    this.routeHistory.replace({ state: null });
    this.setState({
      ...this.state,
      searchedExps: this.experiences,
      expSearch: {
        ...this.initialState.expSearch,
        ...this.filtersCache
      },
      expList: this.initExpList(this.experiences)
    });
  }

  handleShow(e) {
    e.preventDefault();
    if (this.state.expList.showText === "Show More") {
      this.setState(
        {
          expList: {
            ...this.state.expList,
            showItems:
              this.state.expList.showItems >= this.state.searchedExps.length
                ? this.state.expList.showItems
                : this.state.expList.showItems + ITEMS_PER_ROW
          }
        },
        () => {
          this.setState(
            {
              expList: {
                ...this.state.expList,
                showText:
                  this.state.expList.showItems >= this.state.searchedExps.length
                    ? "Show Less"
                    : "Show More"
              }
            },
            () => {
              this.setState({
                expList: {
                  ...this.state.expList,
                  showList: this.state.searchedExps.slice(
                    0,
                    this.state.expList.showItems
                  )
                }
              });
            }
          );
        }
      );
    } else {
      this.setState({
        expList: this.initExpList(this.state.searchedExps)
      });
    }
  }

  handleSort() {
    let newlist = this.state.expList;
    newlist.sort((a, b) => {
      return a.popularity > b.popularity ? 1 : -1;
    });

    this.setState({
      expList: newlist
    });
  }

  getDistinctValues(result, list) {
    let ct_map = new Map(),
      min_val = list[0].min_people,
      max_val = list[0].max_people,
      expt_map = new Map();
    for (let item of list) {
      this.pushDistinctObjects(
        ct_map,
        item.cityid,
        item.city_name,
        result.cities
      );
      this.pushDistinctObjects(
        expt_map,
        item.expertid,
        item.expert_name,
        result.experts
      );
      if (Number(item.min_people) < Number(min_val))
        min_val = Number(item.min_people);
      if (Number(item.max_people) > Number(max_val))
        max_val = Number(item.max_people);
    }
    for (let i = min_val; i <= max_val; i++) {
      result.guests.push({ id: i.toString(), name: i.toString() });
    }
    this.filtersCache = result;
    return result;
  }

  pushDistinctObjects(map, id, name, arr) {
    if (!map.has(id)) {
      map.set(id, true);
      arr.push({ id, name });
    }
  }

  setState_success(exps, cats) {
    let preSelectCat = {};
    let preFilterExps = [];

    this.experiences = exps;
    if (this.routeHistory.location.state) {
      let catId = this.routeHistory.location.state.categoryId;
      preSelectCat = cats.find(item => item.id === catId);
      preFilterExps = this.experiences.filter(
        item => item.vertical_id === catId
      );
    } else {
      preSelectCat = this.state.expSearch.selected_cat;
      preFilterExps = this.experiences;
    }
    let result = { categories: cats, cities: [], guests: [], experts: [] };
    this.setState({
      searchedExps: preFilterExps,
      expSearch: {
        ...this.state.expSearch,
        ...this.getDistinctValues(result, preFilterExps),
        selected_cat: preSelectCat
      },
      expList: this.initExpList(preFilterExps)
    });
  }

  fetchExpList() {
    let temp_url = this.state.userId
      ? APIbaseURL + "/all_experiences/" + this.state.userId
      : APIbaseURL + "/all_experiences";
    return axios.get(temp_url);
  }

  fetchCategories() {
    return axios.get(APIbaseURL + "/verticals");
  }

  fetchAllData() {
    trackPromise(
      axios
        .all([this.fetchExpList(), this.fetchCategories()])
        .then(
          axios.spread((exp, cat) => {
            const { data: experiences } = exp;
            const { data: categories } = cat;
            if (experiences.code === 200 && categories.code === 200) {
              this.setState_success(experiences.data, categories.data);
            } else {
              console.log("Default block in Experiences List");
            }
          })
        )
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  componentDidMount() {
    this.fetchAllData();
  }

  catSelected(selected) {
    let selectedObj = JSON.parse(selected);
    let list = this.experiences.filter(
      item => item.vertical_id === selectedObj.id
    );
    let ct_map = new Map(),
      ct_list = [],
      min_val = list[0].min_people,
      max_val = list[0].max_people,
      guests = [],
      exprt_map = new Map(),
      exprt_list = [];
    for (let item of list) {
      this.pushDistinctObjects(ct_map, item.cityid, item.city_name, ct_list);
      this.pushDistinctObjects(
        exprt_map,
        item.expertid,
        item.expert_name,
        exprt_list
      );
      if (Number(item.min_people) < Number(min_val))
        min_val = Number(item.min_people);
      if (Number(item.max_people) > Number(max_val))
        max_val = Number(item.max_people);
    }
    for (let i = min_val; i <= max_val; i++) {
      guests.push({ id: i.toString(), name: i.toString() });
    }
    this.setState({
      expSearch: {
        ...this.state.expSearch,
        cities: ct_list,
        guests,
        experts: exprt_list,
        selected_cat: selectedObj,
        selected_cty: this.initialState.expSearch.selected_cty,
        selected_gst: this.initialState.expSearch.selected_gst,
        selected_exprt: this.initialState.expSearch.selected_exprt
      }
    });
  }

  citySelected(selected) {
    let selectedObj = JSON.parse(selected);
    let list = this.experiences.filter(item => item.cityid === selectedObj.id);
    let min_val = list[0].min_people,
      max_val = list[0].max_people,
      guests = [],
      exprt_map = new Map(),
      exprt_list = [];
    for (let item of list) {
      this.pushDistinctObjects(
        exprt_map,
        item.expertid,
        item.expert_name,
        exprt_list
      );
      if (Number(item.min_people) < Number(min_val))
        min_val = Number(item.min_people);
      if (Number(item.max_people) > Number(max_val))
        max_val = Number(item.max_people);
    }
    for (let i = min_val; i <= max_val; i++) {
      guests.push({ id: i.toString(), name: i.toString() });
    }
    this.setState({
      expSearch: {
        ...this.state.expSearch,
        guests,
        experts: exprt_list,
        selected_cty: selectedObj,
        selected_gst: this.initialState.expSearch.selected_gst,
        selected_exprt: this.initialState.expSearch.selected_exprt
      }
    });
  }

  guestSelected(selected) {
    let selectedObj = JSON.parse(selected);
    let list = this.experiences.filter(
      item =>
        Number(item.min_people) <= Number(selectedObj.id) &&
        Number(item.max_people) >= Number(selectedObj.id)
    );
    let exprt_map = new Map(),
      exprt_list = [];
    for (let item of list) {
      this.pushDistinctObjects(
        exprt_map,
        item.expertid,
        item.expert_name,
        exprt_list
      );
    }
    this.setState({
      expSearch: {
        ...this.state.expSearch,
        experts: exprt_list,
        selected_gst: selectedObj,
        selected_exprt: this.initialState.expSearch.selected_exprt
      }
    });
  }

  expertSelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({
      expSearch: {
        ...this.state.expSearch,
        selected_exprt: selectedObj
      }
    });
  }

  searchClicked() {
    let catId = Number(this.state.expSearch.selected_cat.id);
    let ctyId = Number(this.state.expSearch.selected_cty.id);
    let gstId = Number(this.state.expSearch.selected_gst.id);
    let exprtId = Number(this.state.expSearch.selected_exprt.id);
    if (catId === 0 && ctyId === 0 && gstId === 0 && exprtId === 0) {
      this.setState({
        ...this.state,
        expSearch: {
          ...this.state.expSearch,
          filterSelected: false
        }
      });
    } else {
      let filterExp = this.experiences;
      if (catId !== 0) {
        filterExp = filterExp.filter(item => {
          return Number(item.vertical_id) === catId;
        });
      }
      if (ctyId !== 0) {
        filterExp = filterExp.filter(item => {
          return Number(item.cityid) === ctyId;
        });
      }
      if (gstId !== 0) {
        filterExp = filterExp.filter(item => {
          return (
            Number(item.min_people) <= Number(gstId) &&
            Number(item.max_people) >= Number(gstId)
          );
        });
      }
      if (exprtId !== 0) {
        filterExp = filterExp.filter(item => {
          return Number(item.expertid) === exprtId;
        });
      }
      this.setState({
        ...this.state,
        searchedExps: filterExp,
        expList: this.initExpList(filterExp),
        expSearch: {
          ...this.state.expSearch,
          filterSelected: true
        }
      });
    }
  }

  updateToWishList(expId) {
    this.addWishList(expId);
  }

  addWishList(expId) {
    const payload = { exp_id: expId, user_id: this.state.userId };
    trackPromise(
      axios
        .post(APIbaseURL + "/add_wishlist", JSON.stringify(payload))
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.addWishlist_success(expId, data.data);
              break;
            case 400:
              this.toggleAlert(true, data.data, "failr");
              break;
            case 409:
              this.toggleAlert(true, data.message, "failr");
              break;
            default:
              console.log("Default case in Experience Booking");
          }
        })
        .catch(function(error) {
          console.error("Add to wishlist error: ", error);
        })
    );
  }

  addWishlist_success(expId, message) {
    this.experiences = this.changeWishlistedFlag(this.experiences, expId);
    this.setState(
      {
        searchedExps: this.changeWishlistedFlag(this.state.searchedExps, expId),
        expList: {
          ...this.state.expList,
          showList: this.changeWishlistedFlag(
            this.state.expList.showList,
            expId
          )
        }
      },
      () => {
        this.toggleAlert(true, message, "suces");
      }
    );
  }

  changeWishlistedFlag(list, expId) {
    let temp_list = list.map(item => {
      if (Number(item.id) === Number(expId)) {
        item.iswishlisted = "1";
      }
      return item;
    });
    return temp_list;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Cultural Travel Experiences | The Soul Company"
          description="The Soul Company provides Premier Luxury Lifestyle Experience to help you make the most of it by creating your perfect premier experience. Discover our premier offering at: thesoulcompany.in"
          keywords="Premier Luxury Lifestyle Experience, Art Expert, Food Expert, Cultural Travel Experiences, Authentic Experience"
          og_title="Cultural Travel Experiences | The Soul Company"
          og_url={this.PAGE_URL}
          og_description="The Soul Company provides Premier Luxury Lifestyle Experience to help you make the most of it by creating your perfect premier experience. Discover our premier offering at: thesoulcompany.in"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <Explistingtitle />
        <Searchfilter data={this.state.expSearch} fns={this.expSearchFn} />
        <CustomAlert
          show={this.state.alert_show}
          message={this.state.alert_message}
          closeAlert={this.toggleAlert}
          variant={this.state.alert_variant}
        />
        <Explistingpage
          data={this.state.expList}
          fns={this.expListFn}
          searchCount={this.state.searchedExps.length}
          showCount={ITEMS_PER_ROW}
          userId={this.state.userId}
        />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Explisting;
