import React, { Component } from "react";
import {
	Navbar,
	Nav,
	Container,
	DropdownButton,
	Dropdown
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import logo from "../../images/logo.png";
import "./MyprofileNav.css";
class MyprofileNav extends Component {
	render() {
		return (
			<React.Fragment>
				<Container fluid={true} className="container-grid">
					<Navbar className="navbar-default" expand="lg" fixed="top">
						<Navbar.Brand href="/">
							<img
								src={logo}
								className="img-responsive"
								alt="The Soul Company"
							/>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav" className="navbar-left menu">
							<Nav className="mr-auto left-menu">
								<Link
									to="/"
									className={
										this.props.history.location.pathname === "/" ? "active" : ""
									}
								>
									Home
								</Link>
								<Link
									to="/Experiences"
									className={
										this.props.history.location.pathname === "/Experiences"
											? "active"
											: ""
									}
								>
									Experiences
								</Link>
								<Link
									to="/AboutUs"
									className={
										this.props.history.location.pathname === "/AboutUs"
											? "active"
											: ""
									}
								>
									About Us
								</Link>
								{/* <Link
									to="/Blogs"
									className={
										this.props.history.location.pathname === "/Blogs"
											? "active"
											: ""
									}
								>
									Blog
								</Link> */}
								<Link
									to="/ContactUs"
									className={
										this.props.history.location.pathname === "/ContactUs"
											? "active"
											: ""
									}
								>
									Contact Us
								</Link>
							</Nav>
							{/* <Form
								inline
								id="topsearch"
								className="d-xs-none d-sm-none d-md-block"
							>
								<FormControl
									type="text"
									placeholder="Search"
									className="form-control"
								/>
								<Button className="submit-btn" name="submit" id="submit">
									<FontAwesomeIcon icon={faSearch} className="arrorright" />
								</Button>
							</Form> */}
							<Nav className="logreg">
								<DropdownButton
									title="Dharmesh"
									className="icon-angle-down profile-dropdown"
								>
									<Dropdown.Item href="#/action-1">Wishlist</Dropdown.Item>
									<Dropdown.Item href="#/action-2">Orders</Dropdown.Item>
									<Dropdown.Item href="#/action-3">Edit Profile</Dropdown.Item>
									<Dropdown.Item href="#/action-4">Address</Dropdown.Item>
								</DropdownButton>
							</Nav>
							{/* <Form inline id="topsearchphn" className="d-xs-block d-md-none">
								<FormControl
									type="text"
									placeholder="Search"
									className="form-control"
								/>
								<Button className="submit-btn" name="submit" id="submit">
									<FontAwesomeIcon icon={faSearch} className="arrorright" />
								</Button>
							</Form> */}
						</Navbar.Collapse>
					</Navbar>
				</Container>
			</React.Fragment>
		);
	}
}
export default withRouter(MyprofileNav);
