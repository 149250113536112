import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import Faq from "../component/TermsAndPolicies/Faq/Faq";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class Faqpage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <div>
        <TSCmeta
          title="FAQ - The Soul Company"
          description="frequently asked questions"
          keywords="faq, frequently asked questions, queries, doubts"
          og_title="FAQ - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="frequently asked questions"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <Faq />
        <Subscribe />
        <Footer />
      </div>
    );
  }
}
export default Faqpage;
