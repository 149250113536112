import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faArrowRight } from "@fortawesome/pro-light-svg-icons";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./Blogs.css";
import BlogsData from "./Blogsdata";

const BlogsDataComponents = BlogsData.map(BlogsData => {
  return (
    <Col xs="12" md="4" key={BlogsData.id} className="blog">
      <Link to="">
        <Card className="dash-box">
          <div className="imgwrap">
            <Card.Img variant="top" src={BlogsData.imageUrl} />
          </div>
          <Card.Body>
            <div className="date-tite">
              <Card.Text>{BlogsData.blogsmalltitle}</Card.Text>
              <Card.Text>{BlogsData.blogdate}</Card.Text>
            </div>
            <Card.Title>{BlogsData.title}</Card.Title>
            <div className="blogcomment">
              <Card.Text className="blogBy">{BlogsData.blogby}</Card.Text>
              <Card.Text className="txt-orange">
                {BlogsData.blogcomment}
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
});

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: [],
      ftime: true,
      showDate: false,
      modalShow: false
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleDayClick(day, { selected }) {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      if (this.state.ftime) {
        selectedDays.push(new Date(day.getTime()));
        selectedDays.push(new Date(day.setDate(day.getDate() + 1)));
        selectedDays.push(new Date(day.setDate(day.getDate() + 1)));
        this.setState({ ftime: false });
      } else {
        selectedDays.push(new Date(day.getTime()));
      }
    }
    this.setState({ selectedDays });
  }
  handleSelect() {
    this.setState(prevState => ({
      showDate: !prevState.showDate
    }));
    console.log("REached here");
  }
  render() {
    let dateHtml = "";
    if (this.state.showDate) {
      dateHtml = (
        <DayPicker
          initialMonth={new Date(2019, 8)}
          disabledDays={[new Date(2019, 8, 12), new Date(2019, 8, 2)]}
          selectedDays={this.state.selectedDays}
          onDayClick={this.handleDayClick}
        />
      );
    }
    return (
      <React.Fragment>
        <Container fluid={true} className="blog-wrap container-grid">
          <Row>
            <Col sm="12">
              <h2>Blog</h2>
              <ScrollAnimation animateIn="line" delay={100} duration={3}>
                <span></span>
              </ScrollAnimation>
            </Col>
            <Col sm="12" className="sort-date">
              <h6 className="txt-orange">Sort By:</h6>
              <div className="date-selector">
                <input placeholder="Choose Date" onClick={this.handleSelect} />
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="calender-icon"
                />
                {dateHtml}
              </div>
            </Col>
          </Row>
          <Row>
            <Row className="blogsdata">{BlogsDataComponents}</Row>
          </Row>
          <Row className="showmore-blog">
            <Button variant="" className="show-more mr-auto ml-auto">
              Show More
              <FontAwesomeIcon icon={faArrowRight} className="arrorright" />
            </Button>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default Blogs;
