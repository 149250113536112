import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Parallax } from "react-parallax";
// import videoarrow from "../../images/video.png";
import "./Homevideo.css";

class Homevideo extends Component {
	render() {
		return (
			<React.Fragment>
				<Parallax
					bgImage={require("../../images/Header-image.jpg")}
					bgImageAlt="Background"
					strength={200}
					className="section-01"
				>
					<Container>
						<Row>
							<Col>
								<div className="item">
									<div className="text-holder">
										<h2>
											Embark on a journey you never knew
											<span>was possible.</span>
										</h2>
										{/* <div className="video-icon">
                      <a href="/">
                        <img src={videoarrow} alt="" />
                      </a>
                    </div> */}
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</Parallax>
			</React.Fragment>
		);
	}
}
export default Homevideo;
