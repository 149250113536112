import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import AboutUsBanner from "../component/AboutUs/AboutUsBanner/AboutUsBanner";
import AboutUsContent from "../component/AboutUs/AboutUsContent/AboutUsContent";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="The Soul Company Expert: Art Expert | Food Expert"
          description="Our experts deliver authentic experiences across Food, Art, Heritage and Wildlife and through their stories and craft you discover the unknown paintings by significant artists."
          keywords="Art Expert, Food Expert, Travel Experiences, The Cultural Experiences, Authentic Experience, Premier Luxury Lifestyle Experience"
          og_title="The Soul Company Expert: Art Expert | Food Expert"
          og_url={this.PAGE_URL}
          og_description="Our experts deliver authentic experiences across Food, Art, Heritage and Wildlife and through their stories and craft you discover the unknown paintings by significant artists."
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <AboutUsBanner />
        <AboutUsContent />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default AboutUs;
