import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import RegThankyou from "../component/RegisterComponent/RegThankyou/RegThankyou";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class RegThankyoupage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Register Thank You"
          description="Thank You page for successful user registration"
          keywords="registration success, registration feedback"
          og_title="Register Thank You"
          og_url={this.PAGE_URL}
          og_description="Thank You page for successful user registration"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <RegThankyou />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default RegThankyoupage;
