import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faHeart } from "@fortawesome/pro-light-svg-icons";
import { NavLink } from "react-router-dom";
import "./Featuredexp.css";
import { MediaBaseURL } from "../../../stores/config";

import { RenderStringWithEntities } from "../../../common.functions";

const ExperienceBox = props => {
  const { item, userId, updateToWishList } = props;
  let wish_class = "";
  let wish_disabled = false;
  if (Number(item.iswishlisted) === 1) {
    wish_class = " wishlisted";
    wish_disabled = true;
  }
  return (
    <Col xs="12" md="4" className="expcard">
      <Card>
        <div className="imgwrap expimg">
          <Link
            to={{
              pathname: `/Expdetail/${item.route_url}`,
              state: { exp_id: item.id }
            }}
          >
            <Card.Img variant="top" src={MediaBaseURL + item.img_path} />
          </Link>
          {userId && (
            <button
              type="button"
              className={"img-like" + wish_class}
              disabled={wish_disabled}
              onClick={() => {
                updateToWishList(item.id);
              }}
            >
              <FontAwesomeIcon icon={faHeart} className="like-heart" />
            </button>
          )}
        </div>
        <Card.Body>
          <Card.Title>{item.title}</Card.Title>
          <Card.Text className="limit-line-2">
            <RenderStringWithEntities input={item.short_desc} />
          </Card.Text>
          {/* <Card.Text className="price">{`${numberToINR(
						item.price
					)} per head | ${item.min_people} people`}</Card.Text> */}
          <NavLink
            to={{
              pathname: `/Expdetail/${item.route_url}`,
              state: { exp_id: item.id }
            }}
            className="border-btn"
          >
            Reserve
          </NavLink>
        </Card.Body>
      </Card>
    </Col>
  );
};

class Featuredexp extends Component {
  render() {
    const { dataList, userId, updateToWishList } = this.props;
    return (
      <React.Fragment>
        <Container fluid={true} className="featuredexphold">
          <Row className="justify-content-md-center">
            <Col xs="12">
              <h2>Featured Experience</h2>
              <ScrollAnimation
                animateIn="line"
                delay={100}
                duration={3}
                animateOnce={true}
              >
                <span></span>
              </ScrollAnimation>
            </Col>
          </Row>
          <ScrollAnimation
            animateIn="fadeIn"
            delay={200}
            duration={3}
            animateOnce={true}
          >
            <Row className="featuredexp">
              {dataList
                .filter(it => it.is_featured === "1")
                .map(item => {
                  return (
                    <ExperienceBox
                      item={item}
                      key={item.id.toString()}
                      userId={userId}
                      updateToWishList={updateToWishList}
                    />
                  );
                })}
            </Row>
            <Row>
              <NavLink
                to={"/Experiences"}
                className="show-more mr-auto ml-auto"
              >
                Show More
                <FontAwesomeIcon icon={faArrowRight} className="arrorright" />
              </NavLink>
            </Row>
          </ScrollAnimation>
        </Container>
      </React.Fragment>
    );
  }
}
export default Featuredexp;
