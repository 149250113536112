import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import Planexperiencecomponent from "../component/RegisterComponent/PlanExperience/PlanExperience";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { Redirect } from "react-router";
import { TSCmeta } from "../common.functions";

class Planexperiencepage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.initialState = {
      redirect: ""
    };
    this.state = { ...this.initialState };
    this.redirectToPage = this.redirectToPage.bind(this);
  }

  redirectToPage(redirectTo) {
    this.setState({
      redirect: redirectTo
    });
  }

  render() {
    const { redirect } = this.state;
    if (redirect) return <Redirect to={redirect} />;
    else
      return (
        <React.Fragment>
          <TSCmeta
            title="Plan Experience - The Soul Company"
            description="experience sharing and planning form for a user"
            keywords="plan experience, plan trip, plan vacation"
            og_title="Plan Experience - The Soul Company"
            og_url={this.PAGE_URL}
            og_description="experience sharing and planning form for a user"
            og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
            og_type="website"
          />
          <Mainnavbar />
          <Planexperiencecomponent redirectToPage={this.redirectToPage} />
          <Subscribe />
          <Footer />
        </React.Fragment>
      );
  }
}
export default Planexperiencepage;
