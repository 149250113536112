const GalleryComponentdata = [
	{
		id: 1,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img01.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img01.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img01.jpg")
			}
		],
		summary: "12 Images",
		popularity: 1
	},
	{
		id: 2,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img02.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img02.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img02.jpg")
			}
		],
		summary: "12 Images",
		popularity: 1
	},
	{
		id: 3,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img03.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img03.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img03.jpg")
			}
		],
		summary: "12 Images",
		popularity: 2
	},
	{
		id: 4,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img04.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img04.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img04.jpg")
			}
		],
		summary: "12 Images",
		popularity: 3
	},
	{
		id: 5,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img05.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img05.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img05.jpg")
			}
		],
		summary: "12 Images",
		popularity: 4
	},
	{
		id: 6,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img06.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img06.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img06.jpg")
			}
		],
		summary: "12 Images",
		popularity: 4
	},
	{
		id: 7,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img07.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img07.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img07.jpg")
			}
		],
		summary: "12 Images",
		popularity: 5
	},
	{
		id: 8,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img08.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img08.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img08.jpg")
			}
		],
		summary: "12 Images",
		popularity: 6
	},
	{
		id: 9,
		title: "Lorem ipsum dolor sit amet",
		imageUrl: require("../images/gallery/img09.jpg"),
		imageDataList: [
			{
				imgDataUrl: require("../images/gallery/img09.jpg")
			},
			{
				imgDataUrl: require("../images/gallery/img09.jpg")
			}
		],
		summary: "12 Images",
		popularity: 7
	}
];

export default GalleryComponentdata;
