import React, { useEffect } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import "./LoaderAsync.css";
import { usePromiseTracker } from "react-promise-tracker";

const LoaderAsync = () => {
  const { promiseInProgress } = usePromiseTracker();
  let isMounted = true;

  useEffect(() => {
    isMounted = true;
    console.log("Mounted: ", isMounted);
    return () => {
      isMounted = false;
      console.log("Unmounted: ", isMounted);
    };
  });

  return (
    isMounted &&
    promiseInProgress && (
      <div className="loader-wrapper">
        <Loader
          type="Oval"
          color="#f15b3b"
          height={70}
          width={70}
          timeout={100000}
        />
      </div>
    )
  );
};

export default LoaderAsync;
