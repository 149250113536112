import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import EditProfileThankyou from "../component/RegisterComponent/EditProfileThankyou/EditProfileThankyou";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class EditProfileThankyoupage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Profile Edit Thank You - The Soul Company"
          description="Thank You page for successful Edit Profile"
          keywords="edit success, profile feedback"
          og_title="Profile Edit Thank You - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Thank You page for successful Edit Profile"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <EditProfileThankyou />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default EditProfileThankyoupage;
