import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import "./Ourjourney.css";
import { MediaBaseURL } from "../../../stores/config";
import { RenderStringWithEntities } from "../../../common.functions";

const JourneyBox = props => {
  const { item } = props;

  return (
    <div className="part item">
      <img src={MediaBaseURL + item.img_path} alt="Our Journey Art" />
      <h3> {item.name} </h3>
      <Link
        to={{
          pathname: "/Experiences",
          state: { categoryId: item.id }
        }}
      >
        <div className="overlay">
          <span>
            <RenderStringWithEntities input={item.description} />
          </span>
        </div>
      </Link>
    </div>
  );
};

class Ourjourney extends Component {
  render() {
    const { dataList, fetchExperiences } = this.props;
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: dataList.length > 3 ? 3 : dataList.length,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: dataList.length > 3 ? 3 : dataList.length,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: dataList.length > 2 ? 2 : dataList.length,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <React.Fragment>
        <Container className="ourjourney" id="home-journey">
          <Row className="justify-content-md-center">
            <Col xs="12">
              <h2>Our Journey</h2>
              <ScrollAnimation
                animateIn="line"
                delay={200}
                duration={3}
                animateOnce={true}
              >
                <span></span>
              </ScrollAnimation>
            </Col>
          </Row>
          <ScrollAnimation
            animateIn="fadeIn"
            delay={300}
            duration={3}
            animateOnce={true}
          >
            <Row>
              <Col>
                <Slider {...settings}>
                  {dataList.map(item => {
                    return (
                      <JourneyBox
                        key={item.id.toString()}
                        item={item}
                        fetchExperiences={fetchExperiences}
                      />
                    );
                  })}
                </Slider>
              </Col>
            </Row>
          </ScrollAnimation>
        </Container>
      </React.Fragment>
    );
  }
}
export default Ourjourney;
