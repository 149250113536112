import React, { Component } from "react";
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	DropdownButton,
	Dropdown
} from "react-bootstrap";
import "./Searchfilter.css";

class Searchfilter extends Component {
	render() {
		const { data, fns } = this.props;
		return (
			<React.Fragment>
				<Container fluid={true} className="filtercont">
					<Container>
						<Row>
							<Col className="searchfilter">
								<Form className="formcont">
									<Form.Row className="justify-content-center">
										<DropdownButton
											title={data.selected_cat.name}
											className="icon-angle-down no-scroll"
											onSelect={fns.catSelected}
										>
											{data.categories.map(item => (
												<Dropdown.Item
													key={item.id}
													eventKey={JSON.stringify(item)}
												>
													{item.name}
												</Dropdown.Item>
											))}
										</DropdownButton>
										<DropdownButton
											title={data.selected_cty.name}
											className="icon-angle-down"
											onSelect={fns.citySelected}
										>
											{data.cities.map(item => (
												<Dropdown.Item
													key={item.id}
													eventKey={JSON.stringify(item)}
												>
													{item.name}
												</Dropdown.Item>
											))}
										</DropdownButton>
										<DropdownButton
											title={data.selected_gst.name}
											className="icon-angle-down"
											onSelect={fns.guestSelected}
										>
											{data.guests.map(item => (
												<Dropdown.Item
													key={item.id}
													eventKey={JSON.stringify(item)}
												>
													{item.name}
												</Dropdown.Item>
											))}
										</DropdownButton>
										<DropdownButton
											title={data.selected_exprt.name}
											className="icon-angle-down"
											onSelect={fns.expertSelected}
										>
											{data.experts.map(item => (
												<Dropdown.Item
													key={item.id}
													eventKey={JSON.stringify(item)}
												>
													{item.name}
												</Dropdown.Item>
											))}
										</DropdownButton>

										<Button
											type="button"
											className="submit-btn"
											onClick={fns.searchClicked}
										>
											Search
										</Button>
									</Form.Row>
									{!data.filterSelected && (
										<div className="text-center errorMsg">
											Please select at least one filter
										</div>
									)}
								</Form>
							</Col>
						</Row>
					</Container>
				</Container>
			</React.Fragment>
		);
	}
}
export default Searchfilter;
