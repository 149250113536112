import React, { Component } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	Button,
	Form,
	Modal,
	DropdownButton,
	Dropdown
} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import Slider from "react-slick";
import "./GalleryComponent.css";
import GalleryComponentdata from "./GalleryComponentdata";

class GalleryComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			GalleryComponentdata: GalleryComponentdata,
			expitems: GalleryComponentdata,
			showItems: 3,
			showText: "Show More",
			modalShow: false,
			galleryimagelist: []
		};
		this.handleLoad = this.handleLoad.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.setModalShow = this.setModalShow.bind(this);
	}

	handleLoad(e) {
		e.preventDefault();
		if (this.state.showText === "Show More") {
			this.setState(
				{
					showItems:
						this.state.showItems >= this.state.expitems.length
							? this.state.showItems
							: this.state.showItems + 3
				},
				() => {
					this.setState({
						showText:
							this.state.showItems >= this.state.expitems.length
								? "Show Less"
								: "Show More"
					});
				}
			);
		} else {
			this.setState({
				showItems: 3,
				showText: "Show More"
			});
		}
		//this.state.showItems >= this.state.items.length ? this.state.showItems : this.state.showItems + 3;
	}

	handleClick() {
		let newlist = this.state.GalleryComponentdata;
		newlist.sort((a, b) => {
			return a.popularity > b.popularity ? 1 : -1;
		});

		this.setState({
			GalleryComponentdata: newlist
		});
	}

	setModalShow() {
		this.setState(prevState => {
			this.setState({
				modalShow: !prevState.modalShow
			});
		});
	}

	setGalleryImageList(galid) {
		console.log(galid);
		let newimagelist = this.state.GalleryComponentdata.filter(data => {
			return data.id === galid;
		});
		//console.log(newimagelist[0].imageDataList);
		this.setState({
			galleryimagelist: newimagelist[0].imageDataList,
			modalShow: true
		});
	}

	render() {
		let imghtml;
		if (this.state.modalShow) {
			imghtml = this.state.galleryimagelist.map((gdata, index) => {
				return (
					<div key={index}>
						<img src={gdata.imgDataUrl} alt="Gallery" />
					</div>
				);
			});
		}
		const ExplistingpageDataComponentsNew = this.state.GalleryComponentdata.map(
			ExplistData => {
				return (
					<Col
						xs="12"
						md="4"
						key={ExplistData.id}
						className="expcard gallerywrap"
					>
						<ScrollAnimation animateIn="fadeIn" delay={200} duration={3}>
							<Card>
								<div className="imgwrap">
									<Card.Img
										variant="top"
										src={ExplistData.imageUrl}
										onClick={() => this.setGalleryImageList(ExplistData.id)}
									/>
								</div>
								<Card.Body>
									<Card.Title>
										{ExplistData.title}
										{/* {ExplistData.id} */}
									</Card.Title>
									<Card.Text>{ExplistData.summary}</Card.Text>
									<Card.Text className="price">{ExplistData.price}</Card.Text>
								</Card.Body>
							</Card>
						</ScrollAnimation>

						<Modal
							className="galleryalbum"
							show={this.state.modalShow}
							onHide={this.setModalShow}
							size="lg"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header closeButton>
								<Modal.Title id="contained-modal-title-vcenter">
									Lorem ipsum
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Slider
									asNavFor={this.state.nav2}
									ref={slider => (this.slider1 = slider)}
									className="sync-slick"
								>
									{imghtml}
								</Slider>
							</Modal.Body>
						</Modal>
					</Col>
				);
			}
		);

		const expitems = ExplistingpageDataComponentsNew.slice(
			0,
			this.state.showItems
		).map(item => item);

		return (
			<React.Fragment>
				<Container fluid={true} className="featuredexphold">
					<Row>
						<Col xs="12">
							<h2>Gallery</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row className="sorting">
						<Col sm={12} className="align-right">
							<h4>Sort by:</h4>
							<Form>
								<DropdownButton
									title="Popularity"
									className="icon-angle-down sort-dropdown"
								>
									<Dropdown.Item href="#/action-1">Popularity</Dropdown.Item>
									<Dropdown.Item href="#/action-2">Ratings</Dropdown.Item>
								</DropdownButton>
							</Form>
						</Col>
					</Row>

					<Row className="featuredexp">{expitems}</Row>
					<Row>
						<Button
							variant=""
							className="show-more mr-auto ml-auto"
							onClick={e => this.handleLoad(e)}
						>
							{this.state.showText}
							<FontAwesomeIcon icon={faArrowRight} className="arrorright" />
						</Button>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default GalleryComponent;
