import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faTwitter,
	faYoutube,
	faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import footerlogo from "../../images/logo_Footer.png";
import emlogo from "../../images/logo-em.jpg";
import "./Footer.css";

class Footer extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Container
					fluid={true}
					className="section-07 footer-address container-grid"
				>
					<Row>
						<Col className="f-logo">
							<img src={footerlogo} alt="The Soul Company" />
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<Navbar>
								<Nav>
									<Link
										to="/"
										className={
											this.props.history.location.pathname === "/"
												? "active"
												: ""
										}
									>
										Home
									</Link>
									<Link
										to="/Experiences"
										className={
											this.props.history.location.pathname === "/Experiences"
												? "active"
												: ""
										}
									>
										Experiences
									</Link>
									<Link
										to="/AboutUs"
										className={
											this.props.history.location.pathname === "/AboutUs"
												? "active"
												: ""
										}
									>
										About Us
									</Link>
									<Link
										to="/Faq"
										className={
											this.props.history.location.pathname === "/Faq"
												? "active"
												: ""
										}
									>
										FAQ
									</Link>
									{/* <Link
										to="/Blogs"
										className={
											this.props.history.location.pathname === "/Blogs"
												? "active"
												: ""
										}
									>
										Blogs
									</Link>
									<Link
										to="/News"
										className={
											this.props.history.location.pathname === "/News"
												? "active"
												: ""
										}
									>
										News
									</Link> */}
									<Link
										to="/ContactUs"
										className={
											this.props.history.location.pathname === "/ContactUs"
												? "active"
												: ""
										}
									>
										Contact Us
									</Link>
								</Nav>
							</Navbar>
						</Col>
						<Col md={4}>
							<Navbar className="f-social">
								<Nav>
									<Link to="#">
										<FontAwesomeIcon
											icon={faFacebookF}
											className="facebook scoial-icon"
										/>
									</Link>
									<Link to="#">
										<FontAwesomeIcon
											icon={faTwitter}
											className="twitter scoial-icon"
										/>
									</Link>
									{/* <Link to="About">
										<FontAwesomeIcon
											icon={faYoutube}
											className="youtube scoial-icon"
										/>
									</Link> */}
									{/* <Link to="Blog">
										<FontAwesomeIcon
											icon={faLinkedinIn}
											className="linkdein scoial-icon"
										/>
									</Link> */}
								</Nav>
							</Navbar>
						</Col>
					</Row>
				</Container>
				<Container fluid={true} className="bottomcopy container-grid">
					<Row>
						<Col md={4} sm={4} className="copy copy-hide">
							<p> &copy; The Soul Company 2019, All Right Reserved.</p>
						</Col>
						<Col md={8} sm={8} className="displayflex">
							<Navbar className="f-right">
								<Nav>
									<Link
										to="/PrivacyPolicy"
										className={
											this.props.history.location.pathname === "/PrivacyPolicy"
												? "active"
												: ""
										}
									>
										Privacy Policy
									</Link>
									<Link
										to="/TermsofUse"
										className={
											this.props.history.location.pathname === "/TermsofUse"
												? "active"
												: ""
										}
									>
										Terms of Use
									</Link>
									{/* <Link
										to="/Disclaimer"
										className={
											this.props.history.location.pathname === "/Disclaimer"
												? "active"
												: ""
										}
									>
										Disclaimer
									</Link> */}
								</Nav>
							</Navbar>
							<Col md={4} sm={4} className="copy copy-phn">
								<p> &copy; The Soul Company 2019, All Right Reserved.</p>
							</Col>
							<span className="hr-line"></span>
							<div className="powerby">
								Powered By
								<a href="http://www.everymedia.world/" target="_blank">
									<img src={emlogo} alt="Everymedia Technologies Pvt. Ltd." />
								</a>
							</div>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default withRouter(Footer);
