import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import "./Search.css";

class Search extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row>
						<Col sm="12">
							<h2>Search</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row className="message-box search-wrap">
						<Col sm="12">
							<span>166 Results</span>
							<div className="search-div">
								<h6>Food</h6>
								<div className="d-flex">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor laboris nisi ut aliquip ex ea commodo
										consequat.
									</p>
									<Link to="" className="txt-orange">
										<FontAwesomeIcon icon={faArrowRight} className="like-dlt" />
									</Link>
								</div>
							</div>
							<div className="search-div">
								<h6>Art</h6>
								<div className="d-flex">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor laboris nisi ut aliquip ex ea commodo
										consequat.
									</p>
									<Link to="" className="txt-orange">
										<FontAwesomeIcon icon={faArrowRight} className="like-dlt" />
									</Link>
								</div>
							</div>
							<div className="search-div">
								<h6>Wildlife</h6>
								<div className="d-flex">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor laboris nisi ut aliquip ex ea commodo
										consequat.
									</p>
									<Link to="" className="txt-orange">
										<FontAwesomeIcon icon={faArrowRight} className="like-dlt" />
									</Link>
								</div>
							</div>
							<div className="search-div">
								<h6>Blogs</h6>
								<div className="d-flex">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor laboris nisi ut aliquip ex ea commodo
										consequat.
									</p>
									<Link to="" className="txt-orange">
										<FontAwesomeIcon icon={faArrowRight} className="like-dlt" />
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default Search;
