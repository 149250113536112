import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./ChangePassword.css";
import { APIbaseURL } from "../../../stores/config";
import axios from "axios";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

const OTP_SEND = "Send OTP";
const OTP_RESEND = "Resend OTP";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmpassword: "",
      response: {
        msg: ""
      },
      otp: {
        value: "",
        isDisabled: false,
        isResend: false,
        status: "",
        data: "",
        msg: ""
      }
    };
    this.userId = this.props.userId;
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassowrd = this.onChangeConfirmPassowrd.bind(this);
    this.onChangeOtp = this.onChangeOtp.bind(this);
    this.getOTP = this.getOTP.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeConfirmPassowrd(e) {
    this.setState({
      confirmpassword: e.target.value
    });
  }

  onChangeOtp(e) {
    this.setState({
      otp: {
        ...this.state.otp,
        value: e
      }
    });
  }

  getOTP() {
    this.resendOTP();
  }

  resendOTP() {
    const obj = {
      id: this.userId,
      type: 1
    };
    trackPromise(
      axios
        .post(APIbaseURL + "/resend_otp", JSON.stringify(obj))
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState(
                {
                  ...this.state,
                  otp: {
                    status: "success",
                    msg: data.message,
                    isResend: true
                  }
                },
                () => {
                  this.disableOtpForTimeout();
                }
              );
              break;
            case 400:
              this.setState({
                ...this.state,
                otp: {
                  status: "failure",
                  msg: data.message
                }
              });
              break;
            default:
              console.log("Default block in Resend OTP");
          }
        })
        .catch(function(error) {
          console.error("Resend OTP error: ", error);
        })
    );
  }

  disableOtpForTimeout() {
    this.setState(
      {
        otp: {
          ...this.state.otp,
          isDisabled: true
        }
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            otp: {
              ...this.state.otp,
              isDisabled: false
            }
          });
        }, 30000);
      }
    );
  }

  onSubmit(e) {
    e.preventDefault();

    if (!this.state.password) {
      this.setState({
        response: {
          ...this.state.response,
          status: "failure",
          msg: "Please enter password."
        }
      });
    } else if (this.state.password !== this.state.confirmpassword) {
      this.setState({
        response: {
          ...this.state.response,
          status: "failure",
          msg: "Password and Confirm password not matching."
        }
      });
    } else if (!this.state.otp.value) {
      this.setState({
        response: {
          ...this.state.response,
          status: "failure",
          msg: "Please enter OTP."
        }
      });
    } else {
      const paylaod = {
        id: this.userId,
        password: this.state.password,
        otp: this.state.otp.value
      };

      trackPromise(
        axios
          .post(APIbaseURL + "/reset_password", JSON.stringify({ ...paylaod }))
          .then(response => {
            const { data } = response;
            switch (data.code) {
              case 200:
                this.setState(
                  {
                    ...this.initialState,
                    response: {
                      ...this.state.response,
                      status: "success",
                      msg: "Your password has been reset."
                    }
                  },
                  () => {
                    this.props.logOutUser();
                  }
                );
                break;
              case 400:
                this.setState({
                  response: {
                    ...this.state.response,
                    status: "failure",
                    msg: data.message
                  }
                });
                break;
              case 409:
                this.setState({
                  response: {
                    ...this.state.response,
                    status: "failure",
                    msg: data.message
                  }
                });
                break;
              default:
                console.log("Default case in Reset Password");
            }
          })
          .catch(function(error) {
            console.error("Reset Password error: ", error);
          })
      );
    }
  }

  render() {
    const { otp } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space forgotpasswordpage">
            <Col className=" message-box mx-auto" md={10} sm={12}>
              <div className=" mx-auto col-md-7 col-sm-12">
                <h3 className="txt-orange">Change Password</h3>
                <Form
                  className="register-form registerpage"
                  onSubmit={this.onSubmit}
                >
                  <Form.Row>
                    <Form.Group as={Col} controlId="newPassword">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="confirmPassword">
                      <Form.Control
                        type="password"
                        value={this.state.confirmpassword}
                        onChange={this.onChangeConfirmPassowrd}
                        placeholder="Confirm password"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group id="formOtp" className="otp-no">
                      <Form.Label>Enter Your OTP</Form.Label>
                      <div className="otpinput">
                        <OtpInput
                          numInputs={6}
                          separator={<span></span>}
                          value={this.state.otp.value}
                          onChange={this.onChangeOtp}
                        />
                      </div>
                      {!otp.isDisabled && (
                        <>
                          <Link
                            to="#"
                            onClick={() => {
                              this.getOTP();
                            }}
                          >
                            {otp.isResend ? OTP_RESEND : OTP_SEND}
                          </Link>
                        </>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <div className="dBlock">
                    <div id="unavailable_error">
                      {otp.status === "success" && (
                        <Form.Text className="suc_text">{otp.msg}</Form.Text>
                      )}
                      {otp.status === "failure" && (
                        <Form.Text className="err_text">{otp.msg}</Form.Text>
                      )}
                    </div>
                    <div className="errorMsg">{this.state.response.msg}</div>
                  </div>
                  <Form.Row>
                    <Form.Group>
                      <Button className="cmn-btn top-space" type="submit">
                        Update
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default ChangePassword;
