import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import "./Homewhy.css";

class Homewhy extends Component {
	render() {
		return (
			<React.Fragment>
				<Parallax
					bgImage={require("../../images/About-Us_Home.jpg")}
					bgImageAlt="Background"
					strength={50}
					className="section-04"
				>
					<Container fluid={true} id="thesoulcompany">
						<Row>
							<Col className="fullwidth">
								<div className="abt-txt">
									<h2>
										The Soul
										<br />
										Company and <br />
										its Why
									</h2>
									<p>
										We believe that people deserve to know the truest depths of
										themselves. The Soul Company’s craft lies in creating
										journeys that compel self-transformation: be it a re-look at
										something small you’ve always taken for granted, or a big
										shift that reminds you that there’s no shame in basically,
										starting over.Whether it’s just for one evening or for a
										week, we exist so that you can embark on a journey you never
										knew was possible: this could be an expert-guided expedition
										journey in to the thickest jungles of India, or a personal
										exploration of a top chef’s world. Regardless of where you
										go - to a neighborhood next door or to a town you barely
										knew existed - you’ll come back refreshed, re-invigorated,
										and transformed.
									</p>
									<Link className="border-btn" to="/AboutUs">
										Read More
									</Link>
								</div>
							</Col>
							<Col></Col>
						</Row>
					</Container>
				</Parallax>
			</React.Fragment>
		);
	}
}
export default Homewhy;
