import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";
import "./OrderDetailsList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPrint,
  faCalendarAlt,
  faPhoneAlt,
  faEnvelope,
  faAngleLeft
} from "@fortawesome/pro-light-svg-icons";
import {
  numberToINR,
  formatDateObject,
  RenderStringWithEntities
} from "../../../common.functions";
import ReactToPrint from "react-to-print";

class OrderDetailsList extends Component {
  constructor(props) {
    super(props);
    this.printElement = null;
  }

  render() {
    const { details, price_request, disableCancel, cancelOrder } = this.props;
    const exp_dates = details.experience_dates
      ? details.experience_dates.split(",")
      : [];

    return (
      <React.Fragment>
        <Container>
          <Row className="go-back-link">
            <Col sm="12">
              <Link to="/Orders" className="link">
                <FontAwesomeIcon icon={faAngleLeft} className="left-angle" />
                <span className="text">Back to Orders</span>
              </Link>
            </Col>
          </Row>
          <Row
            className="text-left message-box equal-space mt-0"
            ref={element => {
              this.printElement = element;
            }}
          >
            <Col sm={12} md={5} className="">
              <ReactToPrint
                trigger={() => (
                  <h5 className="txt-orange text-right print-btn d-block d-md-none">
                    Print_ <FontAwesomeIcon icon={faPrint} />
                  </h5>
                )}
                content={() => this.printElement}
              />
              <h5 className="txt-orange mb-3">
                Order No: <span>{details.order_number}</span>
              </h5>
            </Col>
            <Col
              sm={12}
              md={4}
              lg={{ span: 3, offset: 1 }}
              className="cancel-wrapper mb-3 mb-md-0 no-print"
            >
              <Button
                type="button"
                className="cmn-btn-inverse"
                disabled={disableCancel}
                onClick={cancelOrder}
              >
                Cancel Order
              </Button>
            </Col>
            <Col
              sm={12}
              md={3}
              lg={3}
              className="d-flex align-items-center justify-content-end"
            >
              <ReactToPrint
                trigger={() => (
                  <h5 className="txt-orange text-right print-btn d-none d-md-block">
                    Print <FontAwesomeIcon icon={faPrint} />
                  </h5>
                )}
                content={() => this.printElement}
              />
            </Col>
            <Col sm={12} md={12}>
              <b>
                Booking Date:{" "}
                <span>{formatDateObject(new Date(details.booking_date))}</span>
              </b>
            </Col>
            <div className="orderdetaillist mb-md-4">
              <Col sm={12} md={6} lg={4} className="print-inline-blk">
                <ul className="booking-contact">
                  <li className="title">Contact Details:</li>
                  <li>
                    <FontAwesomeIcon icon={faPhoneAlt} /> {details.mobile}
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} /> {details.email}
                  </li>
                </ul>
              </Col>
              <Col sm={12} md={6} lg={4} className="print-inline-blk">
                <ul className="booking-contact">
                  <li className="title">Billing Address: </li>
                  <li>{details.address}</li>
                </ul>
              </Col>
              <Col
                sm={12}
                md={{ span: 6, offset: 3 }}
                lg={{ span: 4, offset: 0 }}
                className="order-summary print-inline-blk"
              >
                <h3 className="txt-orange">Order Summary</h3>
                {/* <div className="total-order-summary">
                  <div className="total-detail">Total MRP</div>
                  <div className="total-mrp">{numberToINR(details.price)}</div>
                </div>
                <div className="total-order-summary">
                  <div className="total-detail">Tax</div>
                  <div className="total-mrp">Rs. 2,160</div>
                </div> */}
                <div className="total-summary total-order-summary">
                  <div className="total-detail txt-orange">Total</div>
                  <div className="total-mrp">{price_request || numberToINR(details.price)}</div>
                </div>
              </Col>
            </div>
            <Col sm={12} md={12} className="order-booking">
              <h5>
                Payment Mode: <span> </span>
              </h5>
              <h5>
                Reservation status: <span>{details.order_status_name}</span>
                {details.hold_status === "1" ? <span className="hold-status">(On Hold)</span> : ""}
              </h5>
            </Col>
            <Col sm={12} md={12} className="card-booking no-space">
              <h5 className="txt-orange">Items in this Order: </h5>
              <h3>{details.title}</h3>
              <p>
                <RenderStringWithEntities input={details.short_desc} />
              </p>
              <div>
                <p className="info-breakdown">
                  <span className="icon-wrapper">
                    <FontAwesomeIcon icon={faUser} className="solidicon" />
                  </span>
                  <span className="info-wrapper">
                    {details.no_of_person + ' ' + (details.no_of_person > 1 ? 'Guests' : 'Guest')}
                    {/* <PriceBreakdown
                      data={{
                        persons: details.no_of_person,
                        price: details.experience_price,
                        price_4gp: details.four_group_price,
                        price_request
                      }}
                    /> */}
                  </span>
                </p>
                <p className="info-breakdown">
                  <span className="icon-wrapper">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="solidicon"
                    />
                  </span>
                  <span className="info-wrapper">
                    {exp_dates.map((date, i) => (
                      <span className="info-item" key={"expdate-" + i}>
                        {formatDateObject(new Date(date))}
                      </span>
                    ))}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default OrderDetailsList;

const PriceBreakdown = ({ data: { persons, price, price_4gp, price_request } }) => {
  let people = Number(persons);
  let elem = [];
  let i = 1;
  while (people >= 4) {
    elem.push(
      <span className="info-item" key={"pbk" + i}>4 Guests ({price_request || numberToINR(
        price_4gp
      )}/- Per Group)</span>
    );
    people -= 4;
    i++;
  }
  if (people) {
    let wrd = people > 1 ? "Guests" : "Guest";
    elem.push(
      <span
        className="info-item"
        key={"pbk" + i}
      >{people + " " + wrd + "(" + (price_request || numberToINR(price)) + "/- Per Head)"}</span>
    );
  }
  return elem;
};
