import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import ReservationConfirmed from "../component/RegisterComponent/ReservationConfirmed/ReservationConfirmed";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class YourReservationConfirmed extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Reservation Confirmed"
          description="Confimation of the reservation"
          keywords="reservation confirmation, booking confirmation"
          og_title="Reservation Confirmed"
          og_url={this.PAGE_URL}
          og_description="Confimation of the reservation"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <ReservationConfirmed />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default YourReservationConfirmed;
