import React, { Component } from "react";
import MyprofileNav from "../component/CommonComponent/MyprofileNav/MyprofileNav";
import News from "../component/News/News";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class Newspage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="News - The Soul Company"
          description="News relating to The Soul Company"
          keywords="news"
          og_title="News - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="News relating to The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <MyprofileNav />
        <News />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Newspage;
