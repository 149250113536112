import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./RegThankyou.css";

class RegThankyou extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row className="equal-space">
						<Col className="text-center message-box regthankyou" sm={12}>
							<h2 className="txt-orange">Thank You </h2>
							<h3>For Registering With Us!</h3>
							<h3>We Have Sent You Welcome Email</h3>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default RegThankyou;
