import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from "react-ga";
import "animate.css/animate.min.css";
import ScrollToTop from "./component/CommonComponent/ScrollToTop/ScrollToTop";
import Home from "./pages/Home";
import Expdetail from "./pages/Expdetail";
import Explisting from "./pages/Explisting";
import Register from "./pages/Register";
import BillingAddress from "./pages/BillingAddress";
import RegThankyou from "./pages/RegThankyou";
import ExpOrderReview from "./pages/ExpOrderReview";
import Wishlistpage from "./pages/Wishlist";
import AboutUs from "./pages/AboutUs";
import YourReservationConfirmed from "./pages/YourReservationConfirmed";
import Blogspage from "./pages/Blogs";
import Newspage from "./pages/News";
import ContactUspage from "./pages/ContactUs";
import Searchpage from "./pages/Search";
import GalleryComponent from "./pages/Gallery";
import PrivacyPolicypage from "./pages/PrivacyPolicy";
import TermsAndConditionspage from "./pages/TermsofUse";
import Disclaimerpage from "./pages/Disclaimer";
import LoginPage from "./pages/Login";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ForgotPasswordPhonePage from "./pages/ForgotPasswordPhone";
import ResetPasswordPage from "./pages/ResetPassword";
import BookingThankyoupage from "./pages/BookingThankyou";
import LogoutPage from "./pages/Logout";
import ForgoutpasswordThankyoupage from "./pages/ForgotpasswordThankyou";
import ForgotpasswordSuccessfulPage from "./pages/ForgotpasswordSuccessful";
import EmailVerifedSuccessfulPage from "./pages/EmailVerifedSuccessful";
import EditProfilePage from "./pages/EditProfile";
import SubscribeThankYouPage from "./pages/SubscribeThankYou";
import LoaderAsync from "./component/LoaderAsync/LoaderAsync";
import Orders from "./pages/Orders";
import OrderDetails from "./pages/OrderDetails";
import ChangePasswordPage from "./pages/ChangePassword";
import ChangepasswordSuccessfulPage from "./pages/ChangepasswordSuccessful";
import EditProfileThankyou from "./pages/EditProfileThankYou";
import Faqpage from "./pages/Faq";
import { ga_Tracker } from "./common.functions";
import Planexperiencepage from "./pages/PlanExperience";
import PlanExperienceThankyouPage from "./pages/PlanExperienceThankyou";

const PRODUCTION_URL = "www.thesoulcompany.in";

class App extends Component {
  constructor(props) {
    super(props);
    if (document.location.hostname === PRODUCTION_URL) {
      ReactGA.initialize("UA-155241647-1", {
        debug: true
      });
    } else {
      window["ga-disable-UA-155241647-1"] = true;
    }
  }

  render() {
    return (
      <React.Fragment>
        <LoaderAsync />
        <Router>
          <ScrollToTop />
          <Route exact path="/" component={ga_Tracker(Home)} />
          <Route exact path="/Experiences" component={ga_Tracker(Explisting)} />
          <Route exact path="/Expdetail/:id" component={Expdetail} />
          <Route exact path="/Register" component={ga_Tracker(Register)} />
          <Route
            path="/BillingAddress"
            component={ga_Tracker(BillingAddress)}
          />
          <Route path="/RegThankyou" component={ga_Tracker(RegThankyou)} />
          <Route
            path="/ExpOrderReview"
            component={ga_Tracker(ExpOrderReview)}
          />
          <Route path="/Wishlist" component={ga_Tracker(Wishlistpage)} />
          <Route path="/AboutUs" component={ga_Tracker(AboutUs)} />
          <Route path="/Orders" component={ga_Tracker(Orders)} />
          <Route
            path="/OrderDetails/:orderId"
            component={ga_Tracker(OrderDetails)}
          />
          <Route
            path="/YourReservationConfirmed"
            component={ga_Tracker(YourReservationConfirmed)}
          />
          <Route path="/Blogs" component={ga_Tracker(Blogspage)} />
          <Route path="/News" component={ga_Tracker(Newspage)} />
          <Route path="/ContactUs" component={ga_Tracker(ContactUspage)} />
          <Route path="/Search" component={ga_Tracker(Searchpage)} />
          <Route path="/Gallery" component={ga_Tracker(GalleryComponent)} />
          <Route
            path="/PrivacyPolicy"
            component={ga_Tracker(PrivacyPolicypage)}
          />
          <Route
            path="/TermsofUse"
            component={ga_Tracker(TermsAndConditionspage)}
          />
          <Route path="/Disclaimer" component={ga_Tracker(Disclaimerpage)} />
          <Route path="/Login" component={ga_Tracker(LoginPage)} />
          <Route
            path="/ForgotPassword"
            component={ga_Tracker(ForgotPasswordPage)}
          />
          <Route
            path="/ForgotPasswordPhone"
            component={ga_Tracker(ForgotPasswordPhonePage)}
          />
          <Route
            path="/ResetPassword/:randomToken"
            component={ga_Tracker(ResetPasswordPage)}
          />
          <Route
            path="/BookingThankyou"
            component={ga_Tracker(BookingThankyoupage)}
          />
          <Route path="/Logout" component={ga_Tracker(LogoutPage)} />
          <Route
            path="/ForgotpasswordThankyou"
            component={ga_Tracker(ForgoutpasswordThankyoupage)}
          />
          <Route
            path="/ForgotpasswordSuccessful"
            component={ga_Tracker(ForgotpasswordSuccessfulPage)}
          />
          <Route
            path="/emailverification/:randomToken"
            component={ga_Tracker(EmailVerifedSuccessfulPage)}
          />
          <Route path="/EditProfile" component={ga_Tracker(EditProfilePage)} />
          <Route
            path="/confirmSubscription/:randomToken"
            component={ga_Tracker(SubscribeThankYouPage)}
          />
          <Route
            path="/ChangePassword"
            component={ga_Tracker(ChangePasswordPage)}
          />
          <Route
            path="/ChangepasswordSuccessful"
            component={ga_Tracker(ChangepasswordSuccessfulPage)}
          />
          <Route
            path="/EditProfileThankyou"
            component={ga_Tracker(EditProfileThankyou)}
          />
          <Route
            path="/PlanExperience"
            component={ga_Tracker(Planexperiencepage)}
          />
          <Route
            path="/PlanExperienceThankyou"
            component={ga_Tracker(PlanExperienceThankyouPage)}
          />
          <Route path="/Faq" component={ga_Tracker(Faqpage)} />
        </Router>
      </React.Fragment>
    );
  }
}
export default App;
