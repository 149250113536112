import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button
  // Form,
  // DropdownButton,
  // Dropdown
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faAngleRight,
  faHeart
} from "@fortawesome/pro-light-svg-icons";
import "./Explistingpage.css";
import { MediaBaseURL } from "../../stores/config";
import { RenderStringWithEntities } from "../../common.functions";

class Explistingpage extends Component {
  render() {
    const { data, fns, searchCount, showCount, userId } = this.props;
    return (
      <React.Fragment>
        <Container fluid={true} className="featuredexphold">
          <Row className="sorting">
            <Col sm={6} className="sort-list-number">
              <h6>
                <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
                <Link to="#" onClick={fns.resetExperiences}>
                  Back To Listing /{" "}
                  <span className="txt-orange">{searchCount} Results</span>
                </Link>
              </h6>
            </Col>
            {/* <Col sm={6} className="align-right">
							<h4>Sort by:</h4>
							<Form>
								<DropdownButton
									title="Popularity"
									className="icon-angle-down sort-dropdown"
								>
									<Dropdown.Item href="#/action-1">Popularity</Dropdown.Item>
									<Dropdown.Item href="#/action-2">Ratings</Dropdown.Item>
								</DropdownButton>
							</Form>
						</Col> */}
          </Row>

          <Row className="featuredexp">
            {searchCount > 0 ? (
              data.showList.map(item => {
                return (
                  <ExpBox
                    key={item.id}
                    item={item}
                    userId={userId}
                    updateToWishList={fns.updateToWishList}
                  />
                );
              })
            ) : (
              <div className="NoExpToShow">Coming soon...</div>
            )}
          </Row>

          <Row>
            {searchCount > showCount && (
              <Button
                variant=""
                className="show-more mr-auto ml-auto"
                onClick={e => fns.handleShow(e)}
              >
                {data.showText}
                <FontAwesomeIcon icon={faArrowRight} className="arrorright" />
              </Button>
            )}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default Explistingpage;

const ExpBox = props => {
  const { item, userId, updateToWishList } = props;
  let wish_class = "";
  let wish_disabled = false;
  if (Number(item.iswishlisted) === 1) {
    wish_class = " wishlisted";
    wish_disabled = true;
  }
  return (
    <Col xs="12" md="4" key={item.id} className="expcard">
      <ScrollAnimation
        animateIn="fadeIn"
        delay={200}
        duration={3}
        animateOnce={true}
      >
        <Card>
          <div className="imgwrap expimg">
            <Link
              to={{
                pathname: `/Expdetail/${item.route_url}`,
                state: { exp_id: item.id }
              }}
            >
              <Card.Img variant="top" src={MediaBaseURL + item.img_path} />
            </Link>
            {userId && (
              <button
                type="button"
                className={"img-like" + wish_class}
                disabled={wish_disabled}
                onClick={() => {
                  updateToWishList(item.id);
                }}
              >
                <FontAwesomeIcon icon={faHeart} className="like-heart" />
              </button>
            )}
          </div>
          <Card.Body>
            <Card.Title>{item.title}</Card.Title>
            <Card.Text className="limit-line-2">
              <RenderStringWithEntities input={item.short_desc} />
            </Card.Text>

            <Link
              className="border-btn"
              to={{
                pathname: `/Expdetail/${item.route_url}`,
                state: { exp_id: item.id }
              }}
            >
              Reserve
            </Link>
          </Card.Body>
        </Card>
      </ScrollAnimation>
    </Col>
  );
};
