import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUser,
	faMapMarkerAlt,
	faCalendarAlt,
	faCheckCircle
} from "@fortawesome/pro-light-svg-icons";
import map from "../../images/map.jpg";
import "./ReservationConfirmed.css";

class ReservationConfirmed extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row className="equal-space message-box reservation-map">
						<Col md={6} sm={12}>
							<h3 className="txt-orange textequalspace">
								Your Reservation At The Story Of Bengaluru Oota Company Is
								Confirmed!
								<FontAwesomeIcon icon={faCheckCircle} className="checkcircle" />
							</h3>
							<p className="d-flex textequalspace">
								<FontAwesomeIcon icon={faUser} className="solidicon" />2 Guest (
								Rs.6000/- Per Head )
							</p>
							<p className="d-flex textequalspace">
								<FontAwesomeIcon icon={faCalendarAlt} className="solidicon" />
								Tuesday July 30, 2019
							</p>
							<p className="d-flex textequalspace">
								<FontAwesomeIcon icon={faMapMarkerAlt} className="solidicon" />
								<p className="d-block">
									<b className="txtblack">
										Tasting Room At Bangaluru Oota Company
									</b>
									<spna className="d-block">
										No.5, Campbridge Cross Road, Cambridge Layout,
										Bangaluru-560008, India
									</spna>
								</p>
							</p>
						</Col>
						<Col md={6} sm={12} className="reservation-map-img">
							<img src={map} alt="map" className="img-fluid" />
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default ReservationConfirmed;
