import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./Expdetailexpect.css";
import { RenderStringWithEntities } from "../../../common.functions";

class Expdetailexpect extends Component {
  render() {
    const {
      data: { txt_left, txt_right }
    } = this.props;
    return (
      <React.Fragment>
        <Container fluid={true} id="Expdetailexpect">
          <ScrollAnimation
            animateIn="fadeIn"
            delay={50}
            duration={1}
            animateOnce={true}
          >
            {/* <Row className="Expdetailexpectwrap author-wrap">
							<Col sm={12}>
								<h2>About author </h2>
							</Col>
							<Col sm={12} md={8}>
								<RenderStringWithEntities input={txt_left} />
							</Col>
							<Col sm={12} md={8}>
								<RenderStringWithEntities input={txt_right} />
							</Col>
						</Row> */}
            <Row className="Expdetailexpectwrap">
              <Col sm={12}>
                <h2>What to expect? </h2>
              </Col>
              <Col sm={12} md={8}>
                <RenderStringWithEntities input={txt_left} />
              </Col>
              <Col sm={12} md={8}>
                <RenderStringWithEntities input={txt_right} />
              </Col>
            </Row>
            <Row className="Expdetailexpectwrap drop-line">
              <Col sm={12}>
                <h2>Not convinced? Drop us a line</h2>
              </Col>
              <Col sm={12} md={8}>
                <p>
                  If you feel you need more information or clarity or just want
                  to hear from a human just drop us a{" "}
                  <a href="mailto:hello@thesoulcompany.in">Hello</a> or check
                  out our <a href="/Faq">FAQ</a> page
                </p>
              </Col>
            </Row>
          </ScrollAnimation>
        </Container>
      </React.Fragment>
    );
  }
}
export default Expdetailexpect;
