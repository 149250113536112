import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart, faShareAlt } from "@fortawesome/pro-light-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";
import Bookbnow from "./Booknow/Booknow";
import "./Expdetailstory.css";
import { numberToINR } from "../../../common.functions";
import { RenderStringWithEntities } from "../../../common.functions";

class Expdetailstory extends Component {
  getGuestsRange(min, max) {
    let guests = [];
    let gst_label = "";
    for (let i = Number(min); i <= Number(max); i++) {
      gst_label = i > 1 ? " guests" : " guest";
      guests.push({ id: i.toString(), name: i.toString() + gst_label });
    }
    return guests;
  }

  render() {
    const { data: dt } = this.props;
    let guests_range = this.getGuestsRange(dt.min_people, dt.max_people);
    let period = 0,
      periodSufix = "";
    if (Number(dt.hourly)) {
      period = dt.no_of_hrs;
      periodSufix = "hrs of";
    } else {
      period = Number(dt.no_of_day);
      periodSufix = period > 1 ? "day" : "day";
    }

    return (
      <React.Fragment>
        <Container test="expdetailstory" fluid={true} id="expdeatilstory">
          <Row className="expdeatilstorywrap">
            <Col sm={8} className="wrap-detail">
              <ScrollAnimation
                animateIn="fadeIn"
                delay={100}
                duration={1}
                animateOnce={true}
              >
                <h2>
                  <RenderStringWithEntities input={dt.title} />
                </h2>
                <h5 className="storyprice">
                  {dt.price_request || numberToINR(dt.price)}{" "}
                  {`per head | ${period} ${periodSufix} Experience`}
                  {/* <span className="expdetail-social-icon">
                  <FontAwesomeIcon icon={faHeart} className="heart-icon" />
                  <FontAwesomeIcon icon={faShareAlt} className="share-icon" />
                </span> */}
                </h5>
                {/* <div className="offerprice">
									<p>Cost for group of 4</p>
									<h3 className="txt-orange">{dt.price_request || numberToINR(
										dt.four_group_price
									)}</h3>
								</div> */}
                <p>
                  <RenderStringWithEntities input={dt.description} />
                </p>
              </ScrollAnimation>
            </Col>
            <Col sm={4} className="expdetailstorybook expdetailstorybookwrap">
              <Bookbnow
                data={{
                  id: dt.id,
                  route_url: dt.route_url,
                  title: dt.title,
                  short_desc: dt.short_desc,
                  price: dt.price,
                  exp_pricing: dt.exp_pricing,
                  price_request: dt.price_request,
                  four_group_price: dt.four_group_price,
                  guests_range,
                  excluded_days: dt.excluded_days,
                  dates_unavailable: dt.dates_unavailable,
                  start_date: dt.start_date,
                  end_date: dt.end_date,
                  no_of_day: dt.no_of_day
                }}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default Expdetailstory;
