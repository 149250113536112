import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import "./Followinsta.css";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

const ACCESS_TOKEN = "13784092087.46dec7f.9078083ddd22489c9319e613ad2ea81a";

const CardBox = props => {
	const { item, index } = props;
	return (
		<Card style={{ width: "100%", display: "inline-block" }}>
			<ScrollAnimation
				animateIn="fadeIn"
				delay={(index + 1) * 100}
				duration={index + 1}
				animateOnce={true}
			>
				<div className="imgwrap instaimg">
					<Card.Img variant="top" src={item.image} />
				</div>
				<Card.Body>
					<div>
						<Card.Title>{item.tag}</Card.Title>
						<span className="date">{item.date}</span>
					</div>
					<div className="clear"></div>
					<Card.Text>{item.text}</Card.Text>
				</Card.Body>
			</ScrollAnimation>
		</Card>
	);
};

function NextArrow(props) {
	const { onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faArrowRight}
			className="arrow-right"
			onClick={onClick}
		/>
	);
}

function PrevArrow(props) {
	const { onClick } = props;
	return (
		<FontAwesomeIcon
			icon={faArrowLeft}
			className="arrow-left"
			onClick={onClick}
		/>
	);
}

class Followinsta extends React.Component {
	constructor() {
		super();
		this.state = {
			feeds: []
		};
		this.fetchInstaFeeds = this.fetchInstaFeeds.bind(this);
	}

	componentDidMount() {
		this.fetchInstaFeeds();
	}

	formatDate(date) {
		let dateObj = new Date(Number(date.padEnd(13, "0")));
		return dateObj.toLocaleDateString("en-GB", {
			day: "numeric",
			month: "long",
			year: "numeric"
		});
	}

	fetchInstaFeeds() {
		trackPromise(
			axios
				.get(
					`https://api.instagram.com/v1/users/self/media/recent/?access_token=${ACCESS_TOKEN}`
				)
				.then(response => {
					const { meta, data } = response.data;
					let date = "",
						feeds = [];
					switch (meta.code) {
						case 200:
							feeds = data.map(item => {
								date = this.formatDate(item.created_time);
								return {
									id: item.id,
									image: item.images.standard_resolution.url,
									text: item.caption.text,
									date,
									tag: "#TheSoulCompany"
								};
							});
							this.setState({ feeds });
							break;
						default:
							console.log("Default block in Instagram feeds");
					}
				})
				.catch(function(error) {
					console.error("Experiences By Vertical error: ", error);
				})
		);
	}

	render() {
		var settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: this.state.feeds.length > 4 ? 4 : this.state.feeds.length,
			slidesToScroll: 1,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow:
							this.state.feeds.length > 3 ? 3 : this.state.feeds.length,
						slidesToScroll: 3,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow:
							this.state.feeds.length > 2 ? 2 : this.state.feeds.length,
						slidesToScroll: 2,
						initialSlide: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		return (
			<React.Fragment>
				<Container fluid={true} className="section-05 container-grid-insta">
					<Row className="justify-content-md-center">
						<Col xs="12">
							<h2>Follow Us On Instagram</h2>
							<ScrollAnimation
								animateIn="line"
								delay={100}
								duration={3}
								animateOnce={true}
							>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row>
						<Col>
							<Slider {...settings}>
								{this.state.feeds.map((item, i) => (
									<CardBox key={item.id} item={item} index={i} />
								))}
							</Slider>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default Followinsta;
