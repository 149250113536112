import React, { Component } from "react";
import MyprofileNav from "../component/CommonComponent/MyprofileNav/MyprofileNav";
import Search from "../component/Search/Search";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";

class Searchpage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Search - The Soul Company"
          description="Search experiences"
          keywords="experiences, search"
          og_title="Search - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Search experiences"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <MyprofileNav />
        <Search />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Searchpage;
