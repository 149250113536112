import React, { useEffect, Component } from "react";
import { Alert, Modal, Button } from "react-bootstrap";
import ReactGA from "react-ga";

export const numberToINR = number => {
  number = Number(number);
  return number.toLocaleString("en-IN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR"
  });
};

export const RenderStringWithEntities = props => {
  const { input } = props;
  let htmlDecode = input => {
    let e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };
  return <span dangerouslySetInnerHTML={{ __html: htmlDecode(input) }} />;
};

export const formatDateObject = (input, options) => {
  options = options || { dateStyle: "full", timeZone: "Asia/Kolkata" };
  return input.toLocaleDateString("en-IN", options);
};

export const CustomAlert = props => {
  const {
    show,
    title,
    message = "set message",
    closeAlert,
    duration = 2500,
    variant = "custom"
  } = props;

  useEffect(() => {
    let closeTimeout = null;
    if (show) {
      closeTimeout = window.setTimeout(() => {
        closeAlert();
      }, duration);
    }

    return () => {
      if (closeTimeout) window.clearTimeout(closeTimeout);
    };
  }, [show]);

  return (
    <Alert variant={variant} show={show}>
      {title && <Alert.Heading>{title}</Alert.Heading>}
      <p>{message}</p>
    </Alert>
  );
};

export const CustomConfirmBox = props => {
  const {
    m_show,
    m_title = "set title",
    m_message = "set message",
    m_close,
    m_confirm,
    m_data = null
  } = props;

  return (
    <Modal
      show={m_show}
      onHide={m_close}
      size="sm"
      dialogClassName="custom-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="div">{m_title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{<RenderStringWithEntities input={m_message} />}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cmn-btn-inverse"
          onClick={() => {
            m_confirm(m_data);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ga_trackPage = (pagePath, ga_options = {}) => {
  ReactGA.set({
    page: pagePath,
    ...ga_options
  });
  ReactGA.pageview(pagePath);
};

export const ga_Tracker = (Compnt, ga_options = {}) => {
  const trackPage = pagePath => {
    ReactGA.set({
      page: pagePath,
      ...ga_options
    });
    ReactGA.pageview(pagePath);
  };

  class GA_Component extends Component {
    componentDidMount() {
      trackPage(this.props.location.pathname);
    }

    render() {
      return <Compnt {...this.props} />;
    }
  }
  return GA_Component;
};

export class TSCmeta extends Component {
  componentDidMount() {
    this.updateMetaInfo();
  }

  componentDidUpdate() {
    this.updateMetaInfo();
  }

  updateMetaInfo() {
    document.getElementsByTagName("title")[0].innerText = this.props.title;
    const metaElements = document.getElementsByTagName("meta");
    metaElements.forEach(element => {
      if (element.getAttribute("name") === "description") {
        element.setAttribute("content", this.props.description);
      } else if (element.getAttribute("name") === "keywords") {
        element.setAttribute("content", this.props.keywords);
      } else if (element.getAttribute("property") === "og:title") {
        element.setAttribute("content", this.props.og_title);
      } else if (element.getAttribute("property") === "og:url") {
        element.setAttribute("content", this.props.og_url);
      } else if (element.getAttribute("property") === "og:description") {
        element.setAttribute("content", this.props.og_description);
      } else if (element.getAttribute("property") === "og:image") {
        element.setAttribute("content", this.props.og_image);
      } else if (element.getAttribute("property") === "og:type") {
        element.setAttribute("content", this.props.og_type);
      }
    });
  }

  render() {
    return null;
  }
}
