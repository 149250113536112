import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./EmailVerifedSuccessful.css";

class EmailVerifedSuccessful extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmpassword: "",
      redirect: false,
      disableResendotp: true,
      booking: {
        msg: ""
      },
      resend_otp: {
        status: "",
        data: "",
        msg: ""
      }
    };
    this.userid = this.props.data.userid;
  }
  render() {
    const { userid } = this.props.data;
    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space logoutpage">
            <Col className=" message-box mx-auto" md={10} sm={12}>
              {userid > 0 && (
                <>
                  <div className=" mx-auto col-md-7 col-sm-12">
                    <h3 className="txt-orange">Thank You</h3>
                    <p>Your email has been verified successfully!</p>
                    <Link to="/Login" className="cmn-btn">
                      Login
                    </Link>
                    <Link to="/" className="cmn-btn">
                      Home
                    </Link>
                  </div>
                </>
              )}
              {userid == 0 && (
                <>
                  <div className=" mx-auto col-md-7 col-sm-12">
                    <p>
                      Unable to verify your email because you might have have
                      verified earlier or token does not exist.
                    </p>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default EmailVerifedSuccessful;
