import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./TermsAndConditions.css";

class TermsAndConditions extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row>
						<Col sm="12">
							<h2>Terms Of Use</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row className="message-box equal-space termspolicieswrap">
						<ScrollAnimation
							animateIn="fadeIn"
							delay={100}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<p>
									Welcome to TheSoulCompany.in. TheSoulCompany.in and its
									affiliates provide their services to you subject to the
									following conditions. If you visit or shop at
									thesoulcompany.in, you accept these conditions. Please read
									them carefully. In addition, when you use any current or
									future TheSoulCompany.in service or visit or purchase from any
									business affiliated with TheSoulCompany.in, whether or not
									included in the TheSoulCompany.in Web site, you also will be
									subject to the guidelines and conditions applicable to such
									service or business.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={150}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies top-space" sm="12">
								<h3 className="txt-orange">Privacy</h3>
								<p>
									Please review our Privacy Policy, which also governs your
									visit to TheSoulCompany.in, to understand our practices.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={200}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Electronic Communications</h3>
								<p>
									When you visit TheSoulCompany.in or send e-mails to us, you
									are communicating with us electronically. You consent to
									receive communications from us electronically. We will
									communicate with you by e-mail or by posting notices on this
									site. You agree that all agreements, notices, disclosures and
									other communications that we provide to you electronically
									satisfy any legal requirement that such communications be in
									writing.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={250}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Copyright</h3>
								<p>
									All content included on this site, such as text, graphics,
									logos, button icons, images, audio clips, digital downloads,
									data compilations, and software, is the property of
									TheSoulCompany.in or its content suppliers. The compilation of
									all content on this site is the exclusive property of
									TheSoulCompany.in. All software used on this site is the
									property of TheSoulCompany.in or its software suppliers.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={300}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Trademarks</h3>
								<p>
									The Soul Company has filed its application for registration
									under the Trade and Merchandise Act and Rules at Chennai under
									different classes and awaiting registration. Any violation of
									our trademarks will be liable for legal action.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={350}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Site Access</h3>
								<p>
									TheSoulCompany.in grants you a limited permission to access
									and make personal use of this site and not to download (other
									than page caching) or modify it, or any portion of it. This
									permission does not include any resale or commercial use of
									this site or its contents; any collection and use of any
									service listings, descriptions, or prices; any derivative use
									of this site or its contents; any downloading or copying of
									account information for the benefit of another merchant; or
									any use of data mining, robots, or similar data gathering and
									extraction tools. This site or any portion of this site may
									not be reproduced, duplicated, copied, sold, resold, visited,
									or otherwise exploited for any commercial purpose without
									express written consent of TheSoulCompany.in. You may not use
									any proprietary information (including images, text, page
									layout, or form) of TheSoulCompany.in and our affiliates
									without express written consent. You may not use any Meta tags
									or any other "hidden text" utilizing TheSoulCompany.in's name
									or trademarks without the express written consent of
									TheSoulCompany.in. Any unauthorized use terminates the
									permission granted by TheSoulCompany.in. You are granted a
									limited, revocable, and nonexclusive right to create a
									hyperlink to the home page of TheSoulCompany.in so long as the
									link does not portray TheSoulCompany.in, its affiliates, or
									their products or services in a false, misleading, derogatory,
									or otherwise offensive matter. You may not use any
									TheSoulCompany.in logo or other proprietary graphic or
									trademark as part of the link without express written
									permission.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={400}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Your Account</h3>
								<p>
									If you use this site, you are responsible for maintaining the
									confidentiality of your account and password and for
									restricting access to your computer, and you agree to accept
									responsibility for all activities that occur under your
									account or password. TheSoulCompany.in and its affiliates
									reserve the right to refuse service, terminate accounts,
									remove or edit content, or cancel orders in their sole
									discretion.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={450}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">
									Reviews, Comments, Communications, And Other Content
								</h3>
								<p>
									Visitors may post reviews, comments, and other content; send
									e-Gift Certificates and other communications; and submit
									suggestions, ideas, comments, questions, or other information,
									so long as the content is not illegal, obscene, threatening,
									defamatory, invasive of privacy, infringing of intellectual
									property rights, or otherwise injurious to third parties or
									objectionable and does not consist of or contain software
									viruses, political campaigning, commercial solicitation, chain
									letters, mass mailings, or any form of "spam." You may not use
									a false e-mail address, impersonate any person or entity, or
									otherwise mislead as to the origin of a card or other content.
									TheSoulCompany.in reserves the right (but not the obligation)
									to remove or edit such content, but does not regularly review
									posted content. If you do post content or submit material, and
									unless we indicate otherwise, you grant TheSoulCompany.in and
									its affiliates a nonexclusive, royalty-free, perpetual,
									irrevocable, and fully sub licensable right to use, reproduce,
									modify, adapt, publish, translate, create derivative works
									from, distribute, and display such content throughout the
									world in any media. You grant TheSoulCompany.in and its
									affiliates and sub licensees the right to use the name that
									you submit in connection with such content, if they choose.
									You represent and warrant that you own or otherwise control
									all of the rights to the content that you post; that the
									content is accurate; that use of the content you supply does
									not violate this policy and will not cause injury to any
									person or entity; and that you will indemnify
									TheSoulCompany.in or its affiliates for all claims resulting
									from content you supply. TheSoulCompany.in has the right but
									not the obligation to monitor and edit or remove any activity
									or content. TheSoulCompany.in takes no responsibility and
									assumes no liability for any content posted by you or any
									third party.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={500}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Product Descriptions</h3>
								<p>
									TheSoulCompany.in and its affiliates attempt to be as accurate
									as possible. However, TheSoulCompany.in does not warrant that
									product descriptions or other content of this site is
									accurate, complete, reliable, current, or error-free. If an
									experience offered by TheSoulCompany.in itself is not as
									described, your sole remedy is seek a return, but it will be
									to the discretion of the company to fulfil the request, upon
									investigation of the issues faced.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={550}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Pricing</h3>
								<p>
									Except where noted otherwise, the List Price displayed for
									services on our website, whether in Foreign Currency or Indian
									Rupees represents the full price of the service. With respect
									to services sold on TheSoulCompany.in, we charge you only upon
									confirmation of the order from both your end and ours.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={600}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">
									Disclaimer Of Warranties And Limitation Of Liability
								</h3>
								<p>
									This site is provided by TheSoulCompany.in on an "as is" and
									"as available" basis. TheSoulCompany.in makes no
									representations or warranties of any kind, express or implied,
									as to the operation of this site or the information, content,
									materials, or products included on this site. You expressly
									agree that your use of this site is at your sole risk.
								</p>
								<p>
									To the full extent permissible by applicable law,
									TheSoulCompany.in disclaims all warranties, express or
									implied, including, but not limited to, implied warranties of
									merchantability and fitness for a particular purpose.
									TheSoulCompany.in does not warrant that this site, its
									servers, or e-mail sent from TheSoulCompany.in are free of
									viruses or other harmful components. TheSoulCompany.in will
									not be liable for any damages of any kind arising from the use
									of this site, including, but not limited to direct, indirect,
									incidental, punitive, and consequential damages.
								</p>
								<p>
									Whilst we try and ensure that the details, descriptions and
									prices of the products displayed on the Website are accurate,
									errors may occur. We therefore reserve the right to correct
									any errors, inaccuracies or omissions and to change or update
									information at any time without prior notice. We reserve the
									right to refuse to fulfil any order that you may place based
									on such erroneous, inaccurate or out-of-date information
									regarding pricing, payment terms, or cancellation policies.
								</p>
								<p>
									To the maximum extent permitted by applicable law, The Soul
									Company assumes no liability or responsibility for (i) any
									errors, mistakes, or inaccuracies of content displayed on the
									Website; (ii) any personal injury or property damage, of any
									nature whatsoever, resulting from your access to or use of our
									Website or any product; (iii) any unauthorized access to or
									use of our secure servers and/or any and all personal
									information stored therein; (iv) any interruption or cessation
									of transmission to or from the website; and/or (v) any bugs,
									viruses or the like that may be transmitted to or through our
									Website by any third party. Indemnification
								</p>
								<p>
									You agree to defend, indemnify and hold The Soul Company and
									its affiliates harmless from and against any and all claims,
									damages, costs and expenses, including attorneys' fees,
									arising from or related to your use of the site.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={650}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Applicable Law</h3>
								<p>
									By visiting TheSoulCompany.in, you agree that the laws of
									India, under the jurisdiction of the Bengaluru High Court,
									without regard to principles of conflict of laws, will govern
									these Conditions of Use and any dispute of any sort that might
									arise between you and TheSoulCompany.in or its affiliates.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={700}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Disputes</h3>
								<p>
									Any dispute relating in any way to your visit to
									TheSoulCompany.in or to services you purchase through
									TheSoulCompany.in shall be submitted to confidential
									arbitration in Bengaluru, India, except that, to the extent
									you have in any manner violated or threatened to violate
									TheSoulCompany.in's intellectual property rights,
									TheSoulCompany.in may seek injunctive or other appropriate
									relief in any court in India or internationally, and you
									consent to exclusive jurisdiction and venue in such courts.
									Arbitration under this agreement shall be conducted under the
									rules then prevailing under the Indian Arbitration Act. The
									arbitrator's award shall be binding and may be entered as a
									judgment in any court of competent jurisdiction. To the
									fullest extent permitted by applicable law, no arbitration
									under this Agreement shall be joined to an arbitration
									involving any other party subject to this Agreement, whether
									through class arbitration proceedings or otherwise. Site
									policies, modification, and severability Please review our
									other policies, such as our pricing policy, posted on this
									site. These policies also govern your visit to
									TheSoulCompany.in. We reserve the right to make changes to our
									site, policies, and these Terms of Use at any time. If any of
									these conditions shall be deemed invalid, void, or for any
									reason unenforceable, that condition shall be deemed severable
									and shall not affect the validity and enforceability of any
									remaining condition.
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={750}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<p>
									Soul Leisure Pvt. Ltd
									<br />
									No. 111, Jal Vayu Vihar
									<br />
									Kacharakanahalli, Bengaluru <br />
									Karnataka - 560043
									<br />
									Email –
									<a
										href="mailto:hello@thesoulcompany.in"
										target="_blank"
										rel="noopener noreferrer"
									>
										hello@thesoulcompany.in
									</a>
								</p>
							</Col>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeIn"
							delay={800}
							duration={3}
							animateOnce={true}
						>
							<Col className="termspolicies" sm="12">
								<h3 className="txt-orange">Grievances</h3>
								<p>
									Important : Grievance cell must be approached post contacting
									the "Customer Support" of The Soul Company where desired
									resolution were not served.
								</p>
								<p>
									All Grievances related to the purchase or services shall be
									addressed to :
								</p>
								<p>
									1. Write us to &nbsp;
									<a
										href="mailto:hello@thesoulcompany.in"
										target="_blank"
										rel="noopener noreferrer"
									>
										hello@thesoulcompany.in &nbsp;
									</a>
									(Working hours – 11AM – 5PM)
								</p>
							</Col>
						</ScrollAnimation>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default TermsAndConditions;
