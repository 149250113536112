import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import "./Wishlist.css";
import { MediaBaseURL } from "../../stores/config";
import { numberToINR, RenderStringWithEntities } from "../../common.functions";

class Wishlistexp extends Component {
  render() {
    const {
      ITEMS_PER_ROW,
      totalExps,
      showExps,
      showText,
      handleShow,
      removeFromWishList,
      noDataMsg
    } = this.props;
    return (
      <React.Fragment>
        <Container fluid={true} className="Wishlisthold">
          <Row className="justify-content-md-center">
            <Col xs="12">
              <h2>My Wishlist</h2>
              <ScrollAnimation
                animateIn="line"
                delay={100}
                duration={3}
                animateOnce={true}
              >
                <span></span>
              </ScrollAnimation>
            </Col>
            {totalExps ? (
              <Col sm="12">
                <span>{totalExps} Items</span>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <ScrollAnimation
            animateIn="fadeIn"
            delay={200}
            duration={3}
            animateOnce={true}
          >
            <Row className="Wishlistexp">
              {totalExps ? (
                showExps.map(item => {
                  return (
                    <ExpBox
                      item={item}
                      key={item.exp_id}
                      removeFromWishList={removeFromWishList}
                    />
                  );
                })
              ) : (
                <div className="WishListEmpty">{noDataMsg}</div>
              )}
            </Row>
            {totalExps > ITEMS_PER_ROW ? (
              <Row>
                <Button
                  variant=""
                  className="show-more mr-auto ml-auto"
                  onClick={handleShow}
                >
                  {showText}
                  <FontAwesomeIcon icon={faArrowRight} className="arrorright" />
                </Button>
              </Row>
            ) : (
              ""
            )}
          </ScrollAnimation>
        </Container>
      </React.Fragment>
    );
  }
}
export default Wishlistexp;

const ExpBox = props => {
  const { item, removeFromWishList } = props;
  let peoplePrefix = item.min_people > 1 ? "people" : "person";
  return (
    <Col xs="12" md="4" className="expcard">
      <Card>
        <div className="imgwrap">
          <Link
            to={{
              pathname: `/Expdetail/${item.route_url}`,
              state: { exp_id: item.exp_id }
            }}
          >
            <Card.Img variant="top" src={MediaBaseURL + item.img_path} />
          </Link>
          <button
            className="img-like"
            onClick={() => {
              removeFromWishList(item.id, item.title);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="like-dlt" />
          </button>
        </div>
        <Card.Body>
          <Card.Title>{item.title}</Card.Title>
          <Card.Text>
            <RenderStringWithEntities input={item.short_desc} />
          </Card.Text>
          <Card.Text className="price">{`${numberToINR(
            item.price
          )} per head | ${item.min_people} ${peoplePrefix}`}</Card.Text>
          <NavLink
            to={{
              pathname: `/Expdetail/${item.route_url}`,
              state: { exp_id: item.exp_id }
            }}
            className="border-btn"
          >
            Reserve
          </NavLink>
        </Card.Body>
      </Card>
    </Col>
  );
};
