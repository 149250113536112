import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./SubscribeThankYou.css";

class SubscribeThankYou extends Component {
  render() {
    const { message } = this.props;

    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space logoutpage">
            <Col className=" message-box mx-auto" md={10} sm={12}>
              <div className=" mx-auto col-md-7 col-sm-12">
                <h3 className="txt-orange">Thank You</h3>
                <p>{message}</p>
                <p>You will receive an email with a link to register</p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default SubscribeThankYou;
