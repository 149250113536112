import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import ResetPassword from "../component/RegisterComponent/ResetPassword/ResetPassword";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { APIbaseURL } from "../stores/config";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { TSCmeta } from "../common.functions";

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.state = {
      data: {
        id: ""
      }
    };
    this.randomToken = this.props.match.params.randomToken;
  }

  fetchUserDetailsByToken(tokenid) {
    trackPromise(
      axios
        .get(APIbaseURL + "/get_userid_by_token/" + tokenid)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState_success(data.data);
              break;
            case 400:
              break;
            default:
              console.log("Default block in User details");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  componentDidMount() {
    this.fetchUserDetailsByToken(this.randomToken);
  }

  setState_success(data) {
    this.setState({
      data
    });
  }

  render() {
    const {
      data: { id }
    } = this.state;

    return (
      <React.Fragment>
        <TSCmeta
          title="Reset Password - The Soul Company"
          description="Reset Password of The Soul Company"
          keywords="forgot password, reset password, regenerate password"
          og_title="Reset Password - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Reset Password of The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        {id && (
          <>
            <ResetPassword
              data={{
                token: this.randomToken,
                userid: id
              }}
            />
          </>
        )}
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default ResetPasswordPage;
