import React, { Component } from "react";
import {
  Col,
  Form,
  Button,
  Modal,
  DropdownButton,
  Dropdown,
  Row
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt
  //faWindowClose
} from "@fortawesome/pro-light-svg-icons";
// import ScrollAnimation from "react-animate-on-scroll";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./Booknow.css";
import Cookies from "js-cookie";
import store, { actions } from "../../../../stores/book_order.store";
import { Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

class Booknow extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      selectedGuests: { id: "0", name: "Guests" },
      selectedDays: [],
      invalidDaysSelection: false,
      invalidDaysMsg: "",
      showDate: false,
      modalShow: false,
      userid: Cookies.get("userid"),
      booking: {
        data: "",
        invalid_guests: false,
        invalid_dates: false
      },
      redirect_order_review: false,
      redirect_login: false,
      addScrollClass: "booking",
      resModalShow: false
    };
    this.state = { ...this.initialState };
    this.guestSelected = this.guestSelected.bind(this);
    this.documentIsClicked = this.documentIsClicked.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.bookExperience = this.bookExperience.bind(this);
    this.redirectToLogin = this.redirectToLogin.bind(this);
    this.handleResModal = this.handleResModal.bind(this);
    this.routeHistory = createBrowserHistory();
    this.pathToLogin = "";
  }

  applyStateFromStore() {
    const {
      experience_id,
      selectedGuests,
      book_dates: selectedDays
    } = store.getState().book_order;
    if (Number(experience_id) === Number(this.props.data.id)) {
      this.setState({ selectedGuests, selectedDays });
    }
  }

  UNSAFE_componentWillMount() {
    this.applyStateFromStore();
    this.unsubscribe = store.subscribe(() => {
      this.applyStateFromStore();
    });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.documentIsClicked);
    if (window.innerWidth >= 1024) {
      let expdetailHtml = document.querySelector("#Expdetailexpect");
      let expdeatilstory = document.querySelector(
        "#expdeatilstory .wrap-detail"
      );
      expdeatilstory.appendChild(expdetailHtml);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    document.removeEventListener("mousedown", this.documentIsClicked);
  }

  documentIsClicked(e) {
    if (
      !e.path.find(itm => itm.className === "date-input form-group col") &&
      !e.path.find(obj => obj.className === "DayPicker") &&
      this.state.showDate
    )
      this.setState({ showDate: false });
  }

  guestSelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({
      ...this.state,
      selectedGuests: selectedObj
    });
  }

  fullDate(partial_date, year_count) {
    return new Date(
      partial_date + " " + (new Date().getFullYear() + year_count)
    );
  }

  getDateTime(partial_date, year_count) {
    return new Date(this.fullDate(partial_date, year_count)).getTime();
  }

  formDisabledDatesArray(dt) {
    const {
      yearly = false,
      excluded_days,
      start_date,
      end_date,
      dates_unavailable
    } = dt;
    let s_date = "",
      e_date = "",
      date_now = new Date(),
      time_now = date_now.getTime();
    const disabledDays = [];

    const dates_un_arr = dates_unavailable.split(",");
    for (let i = 0; i < dates_un_arr.length; i++) {
      disabledDays.push(new Date(dates_un_arr[i]));
    }

    disabledDays.push(
      excluded_days === "1"
        ? { daysOfWeek: [1, 2, 3, 4, 5] }
        : { daysOfWeek: [0, 6] }
    );

    if (yearly === "1") {
      s_date = new Date();
      e_date = null;
    } else {
      if (this.getDateTime(start_date, 0) >= time_now) {
        s_date = this.fullDate(start_date, 0);
        if (this.getDateTime(start_date, 0) < this.getDateTime(end_date, 0)) {
          e_date = this.fullDate(end_date, 0);
        } else {
          e_date = this.fullDate(end_date, 1);
        }
      } else {
        if (this.getDateTime(start_date, 0) < this.getDateTime(end_date, 0)) {
          if (this.getDateTime(end_date, 0) < time_now) {
            s_date = this.fullDate(start_date, 1);
            e_date = this.fullDate(end_date, 1);
          } else {
            s_date = date_now;
            e_date = this.fullDate(end_date, 0);
          }
        } else {
          s_date = date_now;
          e_date = this.fullDate(end_date, 1);
        }
      }
    }
    disabledDays.push({ before: s_date, after: e_date });
    return disabledDays;
  }

  handleDayClick(day, optionsObj, e) {
    const { today, disabled } = optionsObj;
    let requiredDays = Number(this.props.data.no_of_day);
    let sufix = requiredDays > 1 ? "days" : "day";
    let selectedDays = [];
    let nextDay = {};
    if (disabled) {
      return;
    } else if (today) {
      this.setState({
        invalidDaysSelection: true,
        invalidDaysMsg: `Current day cannot be selected`
      });
      return;
    } else {
      nextDay = e.target.nextElementSibling;
      selectedDays.push(new Date(day));
      for (let i = 1; i < requiredDays; i++) {
        if (nextDay.classList.contains("DayPicker-Day--disabled")) {
          this.setState({
            invalidDaysSelection: true,
            invalidDaysMsg: `Experience requires ${requiredDays} continuouse ${sufix}`
          });
          return;
        }
        selectedDays.push(new Date(day.setDate(day.getDate() + 1)));
        nextDay = nextDay.nextElementSibling;
      }
    }
    this.setState({
      selectedDays,
      invalidDaysSelection: false,
      invalidDaysMsg: ""
    });
  }

  handleSelect() {
    this.setState(prevState => ({ showDate: !prevState.showDate }));
  }

  setModalShow(modalShow) {
    this.setState({
      modalShow
    });
  }

  handleResModal(bool) {
    this.setState({
      resModalShow: bool
    });
  }

  saveStateBeforeRedirect() {
    const { userid, selectedGuests, selectedDays = [] } = this.state;
    const { data } = this.props;
    let pricing_indx = data.exp_pricing.findIndex(
      item => item.count === selectedGuests.id
    );
    let group_price =
      pricing_indx !== -1 ? data.exp_pricing[pricing_indx].price : "";
    const payload = {
      user_id: userid,
      experience_id: data.id,
      route_url: data.route_url,
      experience_name: data.title,
      exp_short_desc: data.short_desc,
      no_of_person: selectedGuests.id,
      selectedGuests,
      book_dates: selectedDays,
      price: data.price,
      group_price,
      total_price: group_price,
      four_group_price: data.four_group_price,
      price_request: data.price_request
    };
    actions.setBookOrderStore(store.dispatch, payload);
  }

  bookExperience(modalShow) {
    const { selectedGuests, selectedDays } = this.state;
    if (Number(selectedGuests.id) === 0) {
      this.setState({
        booking: {
          ...this.state.booking,
          invalid_guests: true
        }
      });
    } else if (
      !selectedDays.length ||
      selectedDays.length !== Number(this.props.data.no_of_day)
    ) {
      this.setState({
        booking: {
          ...this.state.booking,
          invalid_guests: false,
          invalid_dates: true
        }
      });
    } else {
      this.setState({
        booking: {
          ...this.state.booking,
          invalid_guests: false,
          invalid_dates: false
        }
      });
      // this.setModalShow(modalShow);
      this.saveStateBeforeRedirect();
      this.setState({ redirect_order_review: true });
    }

    ReactGA.event({
      category: "Reserve_experience",
      action: "Reserve",
      label: "Reserve_button"
    });
  }

  redirectToLogin() {
    this.saveStateBeforeRedirect();
    this.pathToLogin = {
      pathname: "/Login",
      state: {
        redirectTo: {
          pathname: this.routeHistory.location.pathname,
          state: { exp_id: this.props.data.id }
        }
      }
    };
    console.log("pathToLogin: ", this.pathToLogin);
    this.setState({ redirect_login: true });
  }

  render() {
    const { data: dt } = this.props;
    const {
      booking: bk,
      userid,
      invalidDaysSelection,
      invalidDaysMsg
    } = this.state;
    let dropdownClasses = bk.invalid_guests
      ? "icon-angle-down in-valid"
      : "icon-angle-down";
    let dayPickClasses = bk.invalid_dates
      ? "date-input in-valid"
      : "date-input";

    if (this.state.redirect_order_review)
      return <Redirect to="/ExpOrderReview" />;
    else if (this.state.redirect_login)
      return <Redirect to={this.pathToLogin} />;
    else
      return (
        <React.Fragment>
          {/* <ScrollAnimation
            animateIn="fadeInRight"
            delay={100}
            duration={3}
            animateOnce={true}
            className="animatefullwidth"
          > */}

          <Form className={this.state.addScrollClass} noValidate>
            <Form.Row>
              <label>Please select number of guests and dates</label>
              {bk.invalid_guests && (
                <div className="err_text">{`Please select guests`}</div>
              )}
              {invalidDaysSelection && (
                <div className="invalid-dates err_text">{invalidDaysMsg}</div>
              )}
              {bk.invalid_dates && (
                <div className="err_text">
                  Please select {this.props.data.no_of_day}{" "}
                  {this.props.data.no_of_day > 1 ? "days" : "day"}
                </div>
              )}
              <DropdownButton
                title={this.state.selectedGuests.name}
                className={dropdownClasses}
                onSelect={this.guestSelected}
              >
                {dt.guests_range.map(item => (
                  <Dropdown.Item key={item.id} eventKey={JSON.stringify(item)}>
                    {item.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Group
                as={Col}
                controlId="formGriddate"
                className={dayPickClasses}
              >
                <input
                  readOnly
                  onClick={this.handleSelect}
                  className="choosedate"
                  value={
                    this.state.selectedDays.length
                      ? this.state.selectedDays.length > 1
                        ? `${this.state.selectedDays.length} days`
                        : `${this.state.selectedDays.length} day`
                      : "Choose Date"
                  }
                />
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="calender-icon"
                />
                {this.state.showDate && (
                  <DayPicker
                    initialMonth={new Date()}
                    disabledDays={this.formDisabledDatesArray(dt)}
                    selectedDays={this.state.selectedDays}
                    onDayClick={this.handleDayClick}
                  />
                )}
                {/* {this.state.showDate && (
                  <a
                    onClick={this.handleSelect}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faWindowClose}
                      className="close-icon"
                    />
                  </a>
                )} */}
              </Form.Group>

              {/* <h6 className="note">

							<span className="txt-orange">Note : </span>Our pricing mentioned
							is for a group of 4. If you require a booking for a smaller or
							larger group, please choose the group size and date and we will
							get back to you with prices and availability.
						</h6> */}

              <Button
                className="pre-book-btn border-btn"
                onClick={() => {
                  this.bookExperience(true);
                }}
                disabled={!userid}
              >
                Reserve
              </Button>

              {!userid && (
                <span className="err_text">
                  Please{" "}
                  <span
                    className="looks_like_link"
                    onClick={this.redirectToLogin}
                  >
                    Login
                  </span>{" "}
                  /
                  <Link className="looks_like_link" to="/Register">
                    {" "}
                    Register
                  </Link>{" "}
                  to Reserve
                </span>
              )}
            </Form.Row>
          </Form>
          {/* </ScrollAnimation> */}
          <Modal
            show={this.state.modalShow}
            onHide={() => this.setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Invite Code
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>You Need An Invite Code To Book This Experience</h4>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridCode">
                    <Form.Control type="text" placeholder="Enter Code" />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button className="cmn-btn">Continue</Button>
            </Modal.Footer>
          </Modal>

          <Modal
            className="reserve-popup"
            show={this.state.resModalShow}
            onHide={() => this.handleResModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Form className="reserve-form booking" noValidate>
                <Form.Row>
                  <label>Please select number of guests and dates</label>
                  {bk.invalid_guests && (
                    <div className="err_text">{`Please select guests`}</div>
                  )}
                  {invalidDaysSelection && (
                    <div className="invalid-dates err_text">
                      {invalidDaysMsg}
                    </div>
                  )}
                  {bk.invalid_dates && (
                    <div className="err_text">
                      Please select {this.props.data.no_of_day}{" "}
                      {this.props.data.no_of_day > 1 ? "days" : "day"}
                    </div>
                  )}
                  <DropdownButton
                    title={this.state.selectedGuests.name}
                    className={dropdownClasses}
                    onSelect={this.guestSelected}
                  >
                    {dt.guests_range.map(item => (
                      <Dropdown.Item
                        key={item.id}
                        eventKey={JSON.stringify(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <Form.Group
                    as={Col}
                    controlId="formGriddate"
                    className={dayPickClasses}
                  >
                    <input
                      readOnly
                      onClick={this.handleSelect}
                      className="choosedate"
                      value={
                        this.state.selectedDays.length
                          ? this.state.selectedDays.length > 1
                            ? `${this.state.selectedDays.length} days`
                            : `${this.state.selectedDays.length} day`
                          : "Choose Date"
                      }
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="calender-icon"
                    />
                    {this.state.showDate && (
                      <DayPicker
                        initialMonth={new Date()}
                        disabledDays={this.formDisabledDatesArray(dt)}
                        selectedDays={this.state.selectedDays}
                        onDayClick={this.handleDayClick}
                      />
                    )}
                    {/* {this.state.showDate && (
                  <a
                    onClick={this.handleSelect}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faWindowClose}
                      className="close-icon"
                    />
                  </a>
                )} */}
                  </Form.Group>

                  {/* <h6 className="note">

							<span className="txt-orange">Note : </span>Our pricing mentioned
							is for a group of 4. If you require a booking for a smaller or
							larger group, please choose the group size and date and we will
							get back to you with prices and availability.
						</h6> */}

                  <Button
                    className="pre-book-btn border-btn"
                    onClick={() => {
                      this.bookExperience(true);
                    }}
                    disabled={!userid}
                  >
                    Reserve
                  </Button>

                  {!userid && (
                    <span className="err_text">
                      Please{" "}
                      <span
                        className="looks_like_link"
                        onClick={this.redirectToLogin}
                      >
                        Login
                      </span>{" "}
                      /
                      <Link className="looks_like_link" to="/Register">
                        {" "}
                        Register
                      </Link>{" "}
                      to Reserve
                    </span>
                  )}
                </Form.Row>
              </Form>
            </Modal.Body>
          </Modal>
          <Row>
            <Col>
              <div className="show-mbl">
                <Button
                  className="pre-book-btn border-btn book-reserve"
                  onClick={() => this.handleResModal(true)}
                >
                  Reserve
                </Button>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      );
  }
}
export default Booknow;
