import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import ScrollAnimation from "react-animate-on-scroll";
import "react-fancybox/lib/fancybox.css";
import "./Expdetailgallery.css";
import { MediaBaseURL } from "../../../stores/config";
import "react-image-lightbox/style.css";

class Expdetailgallery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav1: null,
			nav2: null,
			showbox: false,
			curritem: {},
			curri: 0,
			slidestoshow: 1
		};

		this.handleImageClick = this.handleImageClick.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
	}
	componentDidMount() {
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2
		});
	}

	handleImageClick(curritem, i) {
		const { data } = this.props;
		const nsrcval = (i + 1) % data.length;
		const psrcval = (i + data.length - 1) % data.length;
		const nsrc = MediaBaseURL + data[nsrcval].main_image;
		const psrc = MediaBaseURL + data[psrcval].main_image;
		this.setState(prevState => ({
			showbox: !prevState.showbox,
			curritem: curritem,
			nsrc: nsrc,
			psrc: psrc,
			curri: i
		}));
		//alert();
	}
	handleCloseModal(e) {
		e.preventDefault();
		this.setState(prevState => ({
			showbox: !prevState.showbox,
			curritem: {},
			curri: 0
		}));
	}
	render() {
		const { data } = this.props;
		let slideval = this.state.slidestoshow;
		if (data.length > 3) {
			slideval = 3;
		} else {
			slideval = data.length;
		}
		var settings = {
			dots: false,
			arrows: true,
			infinite: true,
			speed: 500
		};
		return (
			<React.Fragment>
				<Container fluid={true} id="expdetailgallery">
					<ScrollAnimation
						animateIn="fadeIn"
						delay={200}
						duration={5}
						animateOnce={true}
					>
						<Row>
							<Col sm={12}>
								<h2>Gallery</h2>
							</Col>
							<Col sm={12}>
								<div className="gallery">
									{this.state.showbox && (
										<Lightbox
											nextSrc={this.state.nsrc}
											prevSrc={this.state.psrc}
											mainSrc={MediaBaseURL + this.state.curritem.main_image}
											onCloseRequest={e => this.handleCloseModal(e)}
											onMovePrevRequest={() => {
												const curval =
													(this.state.curri + data.length - 1) % data.length;
												this.setState(
													{
														curri: curval
													},
													() => {
														this.setState({
															curritem: data[this.state.curri]
														});
													}
												);
											}}
											onMoveNextRequest={() => {
												const curval = (this.state.curri + 1) % data.length;
												this.setState(
													{
														curri: curval
													},
													() => {
														this.setState({
															curritem: data[this.state.curri]
														});
													}
												);
											}}
										/>
									)}

									<Slider
										asNavFor={this.state.nav2}
										ref={slider => (this.slider1 = slider)}
										className="sync-slick"
										slidesToShow={slideval}
									>
										{data.map((item, i, arr) => {
											const pvitem = arr[i + 1];
											if (i == 0) {
												console.log(data[i].main_image);
											}

											return (
												<div
													key={i.toString()}
													onClick={() => this.handleImageClick(item, i)}
													className={"nsrc"}
												>
													<img
														src={MediaBaseURL + item.main_image}
														alt="Gallery"
													/>
													{/* <ReactFancyBox
													thumbnail={MediaBaseURL + item.main_image}
													image={MediaBaseURL + item.main_image}
												/> */}
												</div>
											);
										})}
									</Slider>
									{/* <Slider
										asNavFor={this.state.nav1}
										ref={slider => (this.slider2 = slider)}
										slidesToShow={data.length > 2 ? 3: data.length}
										swipeToSlide={true}
										focusOnSelect={true}
										centerMode={true}
										className="nav-slick"
										{...settings}
									>
										{data.map((item, i) => (
											<div key={i.toString()}>
												<img
													src={MediaBaseURL + item.thumbnail_image}
													alt="Gallery Thumb"
												/>
											</div>
										))}
									</Slider> */}
								</div>
							</Col>
						</Row>
					</ScrollAnimation>
				</Container>
			</React.Fragment>
		);
	}
}
export default Expdetailgallery;
