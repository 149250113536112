import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import Wishlist from "../component/Wishlist/Wishlist";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import axios from "axios";
import { APIbaseURL } from "../stores/config";
import { trackPromise } from "react-promise-tracker";
import { TSCmeta } from "../common.functions";
import Cookies from "js-cookie";
import { createBrowserHistory } from "history";
import { CustomAlert, CustomConfirmBox } from "../common.functions";

const ITEMS_PER_ROW = 3;
const SHOW_MORE = "Show More";
const SHOW_LESS = "Show Less";

class Wishlistpage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.routeHistory = createBrowserHistory();
    if (!Cookies.get("userid")) {
      this.routeHistory.replace({ pathname: "/login" });
      this.routeHistory.go(0);
    }
    this.wishlistExps = [];
    this.state = {
      userId: Cookies.get("userid"),
      showCount: 0,
      showExps: [],
      showText: "",
      alert_show: false,
      alert_message: "",
      alert_variant: "",
      confirm_show: false,
      confirm_message: "",
      confirm_data: null
    };
    this.handleShow = this.handleShow.bind(this);
    this.removeFromWishList = this.removeFromWishList.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.confirmRemoveFromWishlist = this.confirmRemoveFromWishlist.bind(this);
  }

  toggleAlert(alert_show, alert_message = "", alert_variant = "") {
    this.setState((prevState, prevProps) => {
      alert_show = alert_show ? alert_show : !prevState.alert_show;
      return { alert_show, alert_message, alert_variant };
    });
  }

  toggleConfirm(wishId, wishTitle) {
    let confirm_message = wishTitle
      ? `Do you want to remove "${wishTitle}" from your Wishlist?`
      : "";
    this.setState((prevState, prevProps) => {
      return {
        confirm_show: !prevState.confirm_show,
        confirm_message,
        confirm_data: wishId
      };
    });
  }

  handleShow(e) {
    let temp_count = 0;
    let temp_text = "";
    if (e && this.state.showCount === this.wishlistExps.length) {
      temp_count = ITEMS_PER_ROW;
      temp_text = SHOW_MORE;
    } else {
      if (
        e &&
        this.state.showCount + ITEMS_PER_ROW < this.wishlistExps.length
      ) {
        temp_count = this.state.showCount + ITEMS_PER_ROW;
        temp_text = SHOW_MORE;
      } else if (!e && this.state.showCount < this.wishlistExps.length) {
        temp_count = this.state.showCount
          ? this.state.showCount
          : ITEMS_PER_ROW;
        temp_text = SHOW_MORE;
      } else {
        temp_count = this.wishlistExps.length;
        temp_text = SHOW_LESS;
      }
    }
    this.setState({
      showCount: temp_count,
      showExps: this.wishlistExps.slice(0, temp_count),
      showText: temp_text
    });
  }

  fetchWishList() {
    trackPromise(
      axios
        .get(APIbaseURL + "/wishlist/" + this.state.userId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.wishlistExps = data.data;
              this.handleShow();
              break;
            case 400:
            case 409:
              this.wishlistExps = [];
              this.setState(
                {
                  noDataMsg: data.message
                },
                () => {
                  this.handleShow();
                }
              );
              break;
            default:
              console.log("Default block in Wishlist");
          }
        })
        .catch(function(error) {
          console.error("Wishlist error: ", error);
        })
    );
  }

  componentDidMount() {
    this.fetchWishList();
  }

  confirmRemoveFromWishlist(wishId) {
    this.toggleConfirm();
    this.removeFromWishList(wishId);
  }

  removeFromWishList(wishId) {
    trackPromise(
      axios
        .get(APIbaseURL + "/delete_wishlist/" + wishId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.toggleAlert(true, data.data, "suces");
              this.fetchWishList();
              break;
            case 400:
              this.toggleAlert(true, data.data, "failr");
              break;
            case 409:
              this.toggleAlert(true, data.message, "failr");
              break;
            default:
              console.log("Default case in Delete from Wishlist");
          }
        })
        .catch(function(error) {
          console.error("Delete from wishlist error: ", error);
        })
    );
  }

  render() {
    const {
      showExps,
      showText,
      noDataMsg,
      alert_show,
      alert_message,
      alert_variant,
      confirm_show,
      confirm_message,
      confirm_data
    } = this.state;
    return (
      <div>
        <TSCmeta
          title="Wishlist - The Soul Company"
          description="Wishlist of the user"
          keywords="wishlist, shortlisted, liked experiences, saved for later items"
          og_title="Wishlist - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Wishlist of the user"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <CustomAlert
          show={alert_show}
          message={alert_message}
          closeAlert={this.toggleAlert}
          variant={alert_variant}
        />
        <CustomConfirmBox
          m_show={confirm_show}
          m_title={"Confirm Delete!"}
          m_message={confirm_message}
          m_data={confirm_data}
          m_close={this.toggleConfirm}
          m_confirm={this.confirmRemoveFromWishlist}
        />
        <Wishlist
          ITEMS_PER_ROW={ITEMS_PER_ROW}
          totalExps={this.wishlistExps.length}
          showExps={showExps}
          showText={showText}
          handleShow={this.handleShow}
          removeFromWishList={this.toggleConfirm}
          noDataMsg={noDataMsg}
        />
        <Subscribe />
        <Footer />
      </div>
    );
  }
}
export default Wishlistpage;
