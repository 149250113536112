import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./Subscribe.css";
import { APIbaseURL } from "../../../stores/config";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

class Subscribe extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.validateField = this.validateField.bind(this);
		this.formSubmitted = this.formSubmitted.bind(this);
		this.state = {
			email: {
				value: "",
				isValid: false,
				error: ""
			},
			form: {
				isSubmitted: false
			},
			suc_msg: "",
			err_msg: ""
		};
	}

	handleChange(e, field) {
		let { email } = this.state;
		switch (field) {
			case "email":
				this.setState({ email: { ...email, value: e.target.value } }, () => {
					this.validateField(field);
				});
				break;
			default:
				console.log("Default case in handleChange");
		}
	}

	validateField(field) {
		let { email } = this.state;
		switch (field) {
			case "email":
				if (email.value) {
					if (email.value.length < 255) {
						if (email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
							this.setState({ email: { ...email, isValid: true, error: "" } });
						} else {
							this.setState({
								email: {
									...email,
									isValid: false,
									error: "Please enter valid email ID"
								}
							});
						}
					} else {
						this.setState({
							email: {
								...email,
								isValid: false,
								error: "Max 255 characters allowed"
							}
						});
					}
				} else {
					this.setState({
						email: { ...email, isValid: false, error: "No blank allowed" }
					});
				}
				break;
			default:
				console.log("Default case in validateField");
		}
	}

	formSubmitted(e) {
		e.preventDefault();
		this.setState({ form: { isSubmitted: true } });
		if (this.state.email.isValid) {
			this.sendRequest(this.state.email.value);
		}
	}

	sendRequest(email) {
		trackPromise(
			axios
				.post(
					APIbaseURL + "/subscribe_to_newsletter",
					JSON.stringify({ email: email })
				)
				.then(response => {
					switch (response.data.code) {
						case 200:
							this.setState({
								suc_msg: response.data.data,
								err_msg: "",
								form: { isSubmitted: false }
							});
							break;
						case 400:
							this.setState({
								suc_msg: "",
								err_msg: response.data.data,
								form: { isSubmitted: false }
							});
							break;
						default:
							console.log("Default case in Post Email");
					}
				})
				.catch(function(error) {
					console.error("Subscribe to Newsletter error: ", error);
				})
		);
	}

	render() {
		const { email, form, suc_msg, err_msg } = this.state;
		return (
			<React.Fragment>
				<Container className="section-06">
					<Row className="justify-content-md-center">
						<Col xs="12">
							<h2>Subscribe To Newsletter</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form id="newsletter" onSubmit={this.formSubmitted} noValidate>
								<Form.Group controlId="formBasicEmail">
									<Form.Control
										type="email"
										placeholder="Your Email"
										value={email.value}
										onChange={e => this.handleChange(e, "email")}
									/>
								</Form.Group>
								<Button className="submit-btn" type="submit">
									Send
								</Button>
								{form.isSubmitted && !email.isValid ? (
									<div className="errorMsg">
										{email.error || "Please enter valid email"}
									</div>
								) : (
									""
								)}
								{!form.isSubmitted &&
									((suc_msg && <div className="suc_text">{suc_msg}</div>) ||
										(err_msg && <div className="err_text">{err_msg}</div>))}
							</Form>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default Subscribe;
