import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import OrdersList from "../component/RegisterComponent/OrdersList/OrdersList";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import { TSCmeta } from "../common.functions";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import axios from "axios";
import { APIbaseURL } from "../stores/config";
import { trackPromise } from "react-promise-tracker";
import { CustomAlert, CustomConfirmBox } from "../common.functions";

const ITEMS_PER_ROW = 3;
const SHOW_MORE = "Show More";
const SHOW_LESS = "Show Less";

class OrdersPage extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.routeHistory = createBrowserHistory();
    if (!Cookies.get("userid")) {
      this.routeHistory.replace({ pathname: "/login" });
      this.routeHistory.go(0);
    }
    this.userId = Cookies.get("userid");
    this.orderList = [];
    this.initialState = {
      alteredList: this.orderList,
      orderStatusList: [],
      selectedStatus: { id: "0", name: "All" },
      sortByDateList: [
        { id: 1, name: "Booking Date" },
        { id: 2, name: "Experience Date" }
      ],
      selectedSortDate: { id: "0", name: "Select" },
      showCount: 0,
      showOrders: [],
      showText: "",
      noDataMsg: "",
      disableCancel: false,
      alert_show: false,
      alert_message: "",
      alert_variant: "",
      confirm_show: false,
      confirm_message: "",
      confirm_data: null
    };
    this.state = { ...this.initialState };
    this.statusSelected = this.statusSelected.bind(this);
    this.sortSelected = this.sortSelected.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.confirmCancelOrder = this.confirmCancelOrder.bind(this);
  }

  componentDidMount() {
    this.fetchOrderList();
  }

  fetchOrderList() {
    trackPromise(
      axios
        .get(APIbaseURL + "/order_list/" + this.userId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState_success(data.data);
              break;
            case 400:
              this.setState({
                noDataMsg: data.message
              });
              break;
            case 409:
              this.toggleAlert(true, data.message, "failr");
              break;
            default:
              console.log("Default block in Orderlist");
          }
        })
        .catch(function(error) {
          console.error("Orderlist error: ", error);
        })
    );
  }

  setState_success(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].disableCancel = this.setOrderDisableStatus(list[i].order_status);
      list[i].order_status_name = this.getOrderStatusName(list[i]);
      // list[i].price_request = Number(list[i].price_onrequest) ? 'Price on Request' : '';
      list[i].price_request = "";
    }
    this.orderList = list;
    this.setState(
      {
        alteredList: list,
        orderStatusList: this.getDistinctValues(list)
      },
      () => {
        this.handleShow();
      }
    );
  }

  setOrderDisableStatus(status) {
    switch (status) {
      case "2":
      case "3":
      case "6":
      case "7":
        return true;
      default:
        return false;
    }
  }

  getOrderStatusName(obj) {
    switch (obj.order_status) {
      case "4":
        return "Approved/Payment Pending";
      default:
        return obj.order_status_name;
    }
  }

  getDistinctValues(inputList) {
    let status_map = new Map();
    let outputList = [];
    for (let item of inputList) {
      this.pushDistinctObjects(
        status_map,
        item.order_status,
        item.order_status_name,
        outputList
      );
    }
    let sortedList = outputList.sort(this.sortArrayOfObjects("id"));
    sortedList.unshift(this.initialState.selectedStatus);
    return sortedList;
  }

  pushDistinctObjects(map, id, name, arr) {
    if (!map.has(id)) {
      map.set(id, true);
      arr.push({ id, name });
    }
  }

  sortArrayOfObjects(key, descending) {
    let sortOrder = descending ? -1 : 1;
    return function(a, b) {
      return (Number(a[key]) - Number(b[key])) * sortOrder;
    };
  }

  statusSelected(selected) {
    let selectedObj = JSON.parse(selected);
    let list = Number(selectedObj.id)
      ? this.orderList.filter(
          item => Number(item.order_status) === Number(selectedObj.id)
        )
      : this.orderList;
    this.setState(
      {
        alteredList: list,
        selectedStatus: selectedObj
      },
      () => {
        this.sortSelected();
      }
    );
  }

  sortSelected(selected) {
    let selectedObj = selected
      ? JSON.parse(selected)
      : this.state.selectedSortDate;
    let list = [];
    switch (Number(selectedObj.id)) {
      case 1:
        list = this.state.alteredList.sort(
          this.sortByDates("booking_date", true)
        );
        break;
      case 2:
        list = this.state.alteredList.sort(
          this.sortByDates("experience_dates", true)
        );
        break;
      default:
        list = this.state.alteredList;
    }
    this.setState(
      {
        alteredList: list,
        selectedSortDate: selectedObj
      },
      () => {
        this.handleShow();
      }
    );
  }

  sortByDates(key, descending) {
    let sortOrder = descending ? -1 : 1;
    return function(a, b) {
      return (
        (new Date(a[key].split(",")[0]) - new Date(b[key].split(",")[0])) *
        sortOrder
      );
    };
  }

  handleShow(e) {
    const { alteredList } = this.state;
    let temp_count = 0;
    let temp_text = "";
    if (e && this.state.showCount === alteredList.length) {
      temp_count = ITEMS_PER_ROW;
      temp_text = SHOW_MORE;
    } else {
      if (e && this.state.showCount + ITEMS_PER_ROW < alteredList.length) {
        temp_count = this.state.showCount + ITEMS_PER_ROW;
        temp_text = SHOW_MORE;
      } else if (!e) {
        temp_count = ITEMS_PER_ROW;
        temp_text = SHOW_MORE;
      } else {
        temp_count = alteredList.length;
        temp_text = SHOW_LESS;
      }
    }
    this.setState({
      showCount: temp_count,
      showOrders: alteredList.slice(0, temp_count),
      showText: temp_text
    });
  }

  toggleAlert(alert_show, alert_message = "", alert_variant = "") {
    this.setState((prevState, prevProps) => {
      alert_show = alert_show ? alert_show : !prevState.alert_show;
      return { alert_show, alert_message, alert_variant };
    });
  }

  toggleConfirm(orderId, orderNo) {
    let confirm_message = orderNo
      ? `Do you want to cancel Order No: "${orderNo}"?`
      : "";
    this.setState((prevState, prevProps) => {
      return {
        confirm_show: !prevState.confirm_show,
        confirm_message,
        confirm_data: orderId
      };
    });
  }

  confirmCancelOrder(orderId) {
    this.toggleConfirm();
    this.cancelOrder(orderId);
  }

  cancelOrder(orderId) {
    trackPromise(
      axios
        .get(APIbaseURL + "/cancel_order/" + orderId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.cancelOrder_success(orderId, data.message);
              break;
            case 400:
              this.toggleAlert(true, data.data, "failr");
              break;
            case 409:
              this.toggleAlert(true, data.message, "failr");
              break;
            default:
              console.log("Default block in Order Cancel");
          }
        })
        .catch(function(error) {
          console.error("Order Cancel error: ", error);
        })
    );
  }

  cancelOrder_success(orderId, message) {
    let temp_alteredList = [];
    let temp_showOrders = [];
    for (let i = 0; i < this.orderList.length; i++) {
      if (this.orderList[i].order_id === orderId) {
        this.orderList[i].order_status = "2";
        this.orderList[i].order_status_name = "Canceled";
        this.orderList[i].disableCancel = this.setOrderDisableStatus("2");
      }
    }
    temp_alteredList = this.state.alteredList.map(item => {
      if (item.order_id === orderId) {
        item.order_status = "2";
        item.order_status_name = "Canceled";
        item.disableCancel = this.setOrderDisableStatus("2");
      }
      return item;
    });
    temp_showOrders = this.state.showOrders.map(item => {
      if (item.order_id === orderId) {
        item.order_status = "2";
        item.order_status_name = "Canceled";
        item.disableCancel = this.setOrderDisableStatus("2");
      }
      return item;
    });
    this.setState(
      {
        alteredList: temp_alteredList,
        showOrders: temp_showOrders
      },
      () => {
        this.toggleAlert(true, message, "suces");
      }
    );
  }

  render() {
    const {
      showOrders,
      orderStatusList,
      selectedStatus,
      sortByDateList,
      selectedSortDate,
      alteredList,
      showText,
      noDataMsg,
      alert_show,
      alert_message,
      alert_variant,
      confirm_show,
      confirm_message,
      confirm_data
    } = this.state;
    return (
      <React.Fragment>
        <TSCmeta
          title="Orders - The Soul Company"
          description="Orders page of The Soul Company"
          keywords="Orders, Bookings"
          og_title="Orders - The Soul Company"
          og_url={this.PAGE_URL}
          og_description="Orders page of The Soul Company"
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <CustomAlert
          show={alert_show}
          message={alert_message}
          closeAlert={this.toggleAlert}
          variant={alert_variant}
        />
        <CustomConfirmBox
          m_show={confirm_show}
          m_title={"Confirm Cancel"}
          m_message={confirm_message}
          m_data={confirm_data}
          m_close={this.toggleConfirm}
          m_confirm={this.confirmCancelOrder}
        />
        <OrdersList
          orderStatusList={orderStatusList}
          selectedStatus={selectedStatus}
          sortByDateList={sortByDateList}
          selectedSortDate={selectedSortDate}
          statusSelected={this.statusSelected}
          sortSelected={this.sortSelected}
          totalItems={alteredList.length}
          itemsPerRow={ITEMS_PER_ROW}
          orderList={showOrders}
          showText={showText}
          handleShow={this.handleShow}
          noDataMsg={noDataMsg}
          cancelOrder={this.toggleConfirm}
        />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default OrdersPage;
