import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./AboutUsContent.css";

class AboutUsContent extends Component {
	render() {
		return (
			<React.Fragment>
				<Container>
					<Row>
						<Col>
							<h2>About Us</h2>
							<ScrollAnimation animateIn="line" delay={200} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
					</Row>
					<ScrollAnimation animateIn="fadeIn" delay={300} duration={3}>
						<Col className="about-text">
							<h3 className="txt-orange">
								We believe that people deserve to know the truest depths of
								themselves.
							</h3>
							<p>
								The Soul Company’s craft lies in creating journeys that compel
								self-transformation: be it a re-look at something small you’ve
								always taken for granted, or a big shift that reminds you that
								there’s no shame in basically, starting over.
							</p>
							<p>
								<i>
									Whether it’s just for one evening or for a week, we exist so
									that you can embark on a journey you never knew was possible:
									this could be an expert-guided expedition journey in to the
									thickest jungles of India, or a personal exploration of a top
									chef’s world. Regardless of where you go --to a neighborhood
									next door or to a town you barely knew existed – you’ll come
									back refreshed, re-invigorated, and transformed.
								</i>
							</p>
						</Col>
					</ScrollAnimation>
					<ScrollAnimation animateIn="fadeIn" delay={400} duration={3}>
						<Col className="about-text">
							<h3 className="txt-orange">The Soul Company Experts</h3>

							<p>
								Our experts are essentially, the most talented people we know.
							</p>
							<p>
								Across the fields of art, food, wildlife and history, they are
								not just amongst the most successful players in their
								industries, but thought-leaders who have given their territories
								a particular texture, and their seas a certain bounce.
							</p>
							<p>
								<i>
									It only took us a few drinks to convince them to share not
									only their impressive skills, but their lives too. For TSC,
									they open up their worlds.
								</i>
							</p>
							<p>
								Our collaborations are bent on breaking down the mystified
								consumer/business divide through personal storytelling and
								intimate interactions. Together, we get up close and personal,
								and travel behind the scenes to uncover what makes for the most
								compelling ideas, the most innovate thoughts, and richest
								biographies.
							</p>
						</Col>
					</ScrollAnimation>
				</Container>
			</React.Fragment>
		);
	}
}
export default AboutUsContent;
