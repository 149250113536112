import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
// import Searchfilter from "../component/ExpComponent/Searchfilter/Searchfilter";
import Expdetailtopimg from "../component/ExpComponent/Expdetailtopimg/Expdetailtopimg";
import Expdetailstory from "../component/ExpComponent/Expdetailstory/Expdetailstory";
import Expdetailexpect from "../component/ExpComponent/Expdetailexpect/Expdetailexpect";
import Expdetailgallery from "../component/ExpComponent/Expdetailgallery/Expdetailgallery";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import axios from "axios";
import { APIbaseURL } from "../stores/config";
import { trackPromise } from "react-promise-tracker";
import Bookbnow from "../component/ExpComponent/Expdetailstory/Booknow/Booknow";
import { ga_trackPage } from "../common.functions";
import { createBrowserHistory } from "history";
import { TSCmeta } from "../common.functions";

class Expdetail extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.state = {
      data: {
        exp_data: [],
        exp_gallary: [],
        exp_pricing: []
      }
    };
    this.routeHistory = createBrowserHistory();
    this.exp_route = this.props.match.params.id;
    this.expId = "";
  }

  componentDidMount() {
    if (
      this.routeHistory.location.state &&
      this.routeHistory.location.state.exp_id
    ) {
      this.expId = this.routeHistory.location.state.exp_id;
      this.fetchExpDetails();
    } else {
      this.fetchExpIdByExpRoute();
    }
  }

  componentDidUpdate() {
    this.trackThePage();
  }

  fetchExpIdByExpRoute() {
    trackPromise(
      axios
        .get(APIbaseURL + "/get_expid_from_url/" + this.exp_route)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.expId = data.data[0].id;
              this.fetchExpDetails();
              break;
            case 400:
              break;
            default:
              console.log("Default block in Exp_id by route_url");
          }
        })
        .catch(function(error) {
          console.error("Exp_id by route_url error: ", error);
        })
    );
  }

  fetchExpDetails() {
    trackPromise(
      axios
        .get(APIbaseURL + "/experience_details/" + this.expId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState_success(data.data);
              break;
            case 400:
              break;
            default:
              console.log("Default block in Experience details");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  getGuestsRange(min, max) {
    let guests = [];
    let gst_label = "";
    for (let i = Number(min); i <= Number(max); i++) {
      gst_label = i > 1 ? " guests" : " guest";
      guests.push({ id: i.toString(), name: i.toString() + gst_label });
    }
    return guests;
  }

  setState_success(data) {
    this.setState(
      {
        data
      },
      () => {
        this.trackThePage();
      }
    );
  }

  trackThePage() {
    if (this.state.data.exp_data[0]) ga_trackPage(this.props.location.pathname);
  }

  render() {
    let guests_range = [],
      price_request = "";
    const {
      data: { exp_data, exp_gallary, exp_pricing }
    } = this.state;

    if (exp_data[0]) {
      // price_request = Number(exp_data[0].price_onrequest) ? 'Price on Request' : '';
      guests_range = this.getGuestsRange(
        exp_data[0].min_people,
        exp_data[0].max_people
      );
    }

    return (
      <div>
        <TSCmeta
          title={exp_data[0] ? exp_data[0].m_title : ""}
          description={exp_data[0] ? exp_data[0].m_desc : ""}
          keywords={exp_data[0] ? exp_data[0].m_keywords : ""}
          og_title={exp_data[0] ? exp_data[0].m_title : ""}
          og_url={this.PAGE_URL}
          og_description={exp_data[0] ? exp_data[0].m_desc : ""}
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />

        {/* <Searchfilter /> */}
        {exp_data[0] && (
          <>
            <Expdetailtopimg
              data={{ banner_img_path: exp_data[0].banner_img_path }}
            />
            <Expdetailstory
              data={{
                id: this.expId,
                route_url: exp_data[0].route_url,
                title: exp_data[0].title,
                price: exp_data[0].price,
                exp_pricing,
                price_request,
                four_group_price: exp_data[0].four_group_price,
                no_of_day: exp_data[0].no_of_day,
                hourly: exp_data[0].hourly,
                no_of_hrs: exp_data[0].no_of_hrs,
                description: exp_data[0].description,
                short_desc: exp_data[0].short_desc,
                min_people: exp_data[0].min_people,
                max_people: exp_data[0].max_people,
                excluded_days: exp_data[0].excluded_days,
                dates_unavailable: exp_data[0].dates_unavailable,
                start_date: exp_data[0].start_date,
                end_date: exp_data[0].end_date
              }}
            />
            <Expdetailexpect
              data={{
                txt_left: exp_data[0].expect_left,
                txt_right: exp_data[0].expect_right
              }}
            />
          </>
        )}
        {exp_gallary && <Expdetailgallery data={[...exp_gallary]} />}
        <Subscribe />
        <div className="hideindesk">
          {exp_data[0] && (
            <Bookbnow
              data={{
                id: this.expId,
                route_url: exp_data[0].route_url,
                title: exp_data[0].title,
                short_desc: exp_data[0].short_desc,
                price: exp_data[0].price,
                exp_pricing,
                price_request,
                four_group_price: exp_data[0].four_group_price,
                guests_range,
                excluded_days: exp_data[0].excluded_days,
                dates_unavailable: exp_data[0].dates_unavailable,
                start_date: exp_data[0].start_date,
                end_date: exp_data[0].end_date,
                no_of_day: exp_data[0].no_of_day
              }}
            />
          )}
        </div>
        <Footer />
      </div>
    );
  }
}
export default Expdetail;
