import React, { Component } from "react";
import Mainnavbar from "../component/CommonComponent/Mainnavbar/Mainnavbar";
import Homevideo from "../component/HomeComponent/Homevideo/Homevideo";
import Ourjourney from "../component/HomeComponent/Ourjourney/Ourjourney";
import Featuredexp from "../component/HomeComponent/Featuredexp/Featuredexp";
import Homewhy from "../component/HomeComponent/Homewhy/Homewhy";
import Followinsta from "../component/CommonComponent/Followinsta/Followinsta";
import Subscribe from "../component/CommonComponent/Subscribe/Subscribe";
import Footer from "../component/CommonComponent/Footer/Footer";
import store from "../stores/home.store";
import { asynchActions, types as actionTypes } from "../stores/home.store";
import Cookies from "js-cookie";
import { CustomAlert } from "../common.functions";
import { TSCmeta } from "../common.functions";

class Home extends Component {
  constructor(props) {
    super(props);
    this.PAGE_URL = window.location.href;
    this.state = {
      userId: Cookies.get("userid")
    };
    this.updateToWishList = this.updateToWishList.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState(store.getState());
    this.unsubscribe = store.subscribe(() => {
      this.setState(store.getState());
    });
  }

  componentDidMount() {
    asynchActions.getVerticals(store.dispatch);
    asynchActions.getFeaturedExp(store.dispatch, this.state.userId);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateToWishList(expId) {
    asynchActions.addExpToWishList(store.dispatch, this.state.userId, expId);
  }

  render() {
    return (
      <React.Fragment>
        <TSCmeta
          title="Cultural Experiences | Authentic Experience | The Soul Company"
          description="The Soul Company is so deeply committed to delivering a “wow” experience that everyone loves."
          keywords="Cultural Experiences, Authentic Experience, Premier Luxury Lifestyle Experience, Art Expert, Food Expert, Travel Experiences"
          og_title="Cultural Experiences | Authentic Experience | The Soul Company"
          og_url={this.PAGE_URL}
          og_description="The Soul Company is so deeply committed to delivering a “wow” experience that everyone loves."
          og_image="https://www.thesoulcompany.in/tsc/uploads/images/webpage_300x200.png"
          og_type="website"
        />
        <Mainnavbar />
        <CustomAlert
          show={this.state.alert_show}
          message={this.state.alert_message}
          closeAlert={() => {
            store.dispatch({
              type: actionTypes.TOGGLE_ALERT,
              message: "",
              variant: ""
            });
          }}
          variant={this.state.alert_variant}
        />
        <Homevideo />
        <Ourjourney dataList={this.state.verticals} />
        <Featuredexp
          dataList={this.state.experiences}
          userId={this.state.userId}
          updateToWishList={this.updateToWishList}
        />
        <Homewhy />
        <Followinsta />
        <Subscribe />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Home;
