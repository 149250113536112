const Newsdata = [
	{
		id: 1,
		imageUrl: require("../images/blogs/blog1.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Food",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 2,
		imageUrl: require("../images/blogs/blog2.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Food",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 3,
		imageUrl: require("../images/blogs/blog3.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Food",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 4,
		imageUrl: require("../images/blogs/blog4.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Art",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 5,
		imageUrl: require("../images/blogs/blog5.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Art",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 6,
		imageUrl: require("../images/blogs/blog6.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith. ",
		blogsmallnewstitle: "Art",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 7,
		imageUrl: require("../images/blogs/blog7.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Wildlife",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 8,
		imageUrl: require("../images/blogs/blog8.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Wildlife",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 9,
		imageUrl: require("../images/blogs/blog8.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith. ",
		blogsmallnewstitle: "Wildlife",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 10,
		imageUrl: require("../images/blogs/blog7.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Wildlife",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 11,
		imageUrl: require("../images/blogs/blog8.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith.",
		blogsmallnewstitle: "Wildlife",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	},
	{
		id: 12,
		imageUrl: require("../images/blogs/blog8.jpg"),
		newstitle:
			"Lorem ipsum of our church is to bring about God’s existence and faith. ",
		blogsmallnewstitle: "Wildlife",
		newsdate: "Times Of India  -  31st July 2019",
		newssummary:
			"Lorem ipsum dolor sit amet, consectetur of adipiscing elit, sed do eiusmod tempor labore et dolore magna aliqua. Ut enim ad..."
	}
];

export default Newsdata;
