import React, { Component } from "react";
import abtbanner from "../../images/About-Us.jpg";
import abtbannermbl from "../../images/About-Us_mbl.jpg";
import abtbannertab from "../../images/About-Us_tb.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "./AboutUsBanner.css";

class AboutUsBanner extends Component {
	render() {
		return (
			<React.Fragment>
				<Container fluid={true}>
					<Row className="no-space">
						<Col className="abtbanner no-space">
							<img
								src={abtbanner}
								className="hidden-xs"
								alt="About The Soul Company"
							/>
							<img
								src={abtbannertab}
								className="visible-tab"
								alt="About The Soul Company"
							/>
							<img
								src={abtbannermbl}
								className="visible-xs"
								alt="About The Soul Company"
							/>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default AboutUsBanner;
