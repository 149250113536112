import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faArrowRight } from "@fortawesome/pro-light-svg-icons";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./News.css";
import NewsData from "./Newsdata";

const NewsDataComponents = NewsData.map(NewsData => {
	return (
		<Col xs="12" md="3" key={NewsData.id} className="News">
			<Card>
				<Card.Body>
					<Card.Text className="newstitle">{NewsData.newstitle}</Card.Text>
					<Card.Text className="newsdate">{NewsData.newsdate}</Card.Text>
					<div className="imgwrap">
						<Card.Img variant="top" src={NewsData.imageUrl} />
					</div>
					<Card.Text className="brief">
						{NewsData.newssummary}
						<Link className="txt-orange" to="">
							Read More
						</Link>
					</Card.Text>
				</Card.Body>
			</Card>
		</Col>
	);
});

class News extends Component {
	constructor(props) {
		super(props);
		this.handleDayClick = this.handleDayClick.bind(this);
		this.state = {
			selectedDays: [],
			ftime: true,
			showDate: false,
			modalShow: false
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	handleDayClick(day, { selected }) {
		const { selectedDays } = this.state;
		if (selected) {
			const selectedIndex = selectedDays.findIndex(selectedDay =>
				DateUtils.isSameDay(selectedDay, day)
			);
			selectedDays.splice(selectedIndex, 1);
		} else {
			if (this.state.ftime) {
				selectedDays.push(new Date(day.getTime()));
				selectedDays.push(new Date(day.setDate(day.getDate() + 1)));
				selectedDays.push(new Date(day.setDate(day.getDate() + 1)));
				this.setState({ ftime: false });
			} else {
				selectedDays.push(new Date(day.getTime()));
			}
		}
		this.setState({ selectedDays });
	}
	handleSelect() {
		this.setState(prevState => ({
			showDate: !prevState.showDate
		}));
		console.log("REached here");
	}
	render() {
		let dateHtml = "";
		if (this.state.showDate) {
			dateHtml = (
				<DayPicker
					initialMonth={new Date(2019, 8)}
					disabledDays={[new Date(2019, 8, 12), new Date(2019, 8, 2)]}
					selectedDays={this.state.selectedDays}
					onDayClick={this.handleDayClick}
				/>
			);
		}
		return (
			<React.Fragment>
				<Container fluid={true} className="News-wrap container-grid">
					<Row>
						<Col sm="12">
							<h2>News</h2>
							<ScrollAnimation animateIn="line" delay={100} duration={3}>
								<span></span>
							</ScrollAnimation>
						</Col>
						<Col sm="12" className="sort-date">
							<h6 className="txt-orange">Sort By:</h6>
							<div className="date-selector">
								<input placeholder="Choose Date" onClick={this.handleSelect} />
								<FontAwesomeIcon
									icon={faCalendarAlt}
									className="calender-icon"
								/>
								{dateHtml}
							</div>
						</Col>
					</Row>
					<Row>
						<Row className="Newsdata">{NewsDataComponents}</Row>
					</Row>
					<Row className="showmore-News">
						<Button variant="" className="show-more mr-auto ml-auto">
							Show More
							<FontAwesomeIcon icon={faArrowRight} className="arrorright" />
						</Button>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}
export default News;
