import React, { Component } from "react";
import { Redirect } from "react-router";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ProgressBar,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import OtpInput from "react-otp-input";
import "./EditProfile.css";
import axios from "axios";
import { APIbaseURL } from "../../../stores/config";
import { trackPromise } from "react-promise-tracker";
import Cookies from "js-cookie";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.userId = Cookies.get("userid");
    this.state = {
      showOtp: false,
      redirect: false,
      countries: [],
      states: [],
      cities: [],
      pincodes: [],
      user: [],
      selectedCountry: { id: "0", country_name: "Select Country" },
      selectedState: { id: "0", state_name: "Select State" },
      selectedCity: { id: "0", city_name: "Select City" },
      selectedPincode: { id: "0", pincode: "Select Pincode" },
      nameError: ""
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.countrySelected = this.countrySelected.bind(this);
    this.stateSelected = this.stateSelected.bind(this);
    this.citySelected = this.citySelected.bind(this);
    this.pincodeSelected = this.pincodeSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeAddress1 = this.onChangeAddress1.bind(this);
    this.onChangeAddress2 = this.onChangeAddress2.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangePincode = this.onChangePincode.bind(this);
  }

  updateProfile = e => {
    this.onSubmit(e);
    e.preventDefault();
  };

  componentDidMount() {
    this.fetchCountryList();
    this.getUserDetails();
  }

  countrySelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({ selectedCountry: selectedObj }, () => {
      console.log("data country: ", this.state);
      const id = this.state.selectedCountry.id;
      this.fetchStateByCountryList(id);
    });
  }

  stateSelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({ selectedState: selectedObj }, () => {
      console.log("data state: ", this.state);
      const id = this.state.selectedState.id;
      this.fetchCityByStateList(id);
      this.state.user.state_name = this.state.selectedState.state_name;
      this.state.user.city_name = "Select City";
    });
  }

  citySelected(selected) {
    console.log("city selected: ", selected);
    let selectedObj = JSON.parse(selected);
    // this.setState({ selectedCity: selectedObj }, () => {
    //   console.log("data city: ", this.state.selectedCity);
    //   this.state.user.city_name = this.state.selectedCity.city_name;
    // });
    this.setState({
      user: {
        ...this.state.user,
        cityid: selectedObj.id,
        city_name: selectedObj.city_name
      }
    });
  }

  pincodeSelected(selected) {
    let selectedObj = JSON.parse(selected);
    this.setState({ selectedPincode: selectedObj }, () => {
      console.log("data pincode: ", this.state);
    });
  }

  getUserDetails() {
    trackPromise(
      axios
        .get(APIbaseURL + "/get_user_details/" + this.userId)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                user: data.data[0]
              });
              this.fetchStateByCountryList(this.state.user.countryid);
              this.fetchCityByStateList(this.state.user.stateid);
              break;
            case 400:
              break;
            default:
              console.log("Default block in Countries List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  fetchCountryList() {
    trackPromise(
      axios
        .get(APIbaseURL + "/countries")
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                countries: data.data
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in Countries List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  fetchStateByCountryList(selectedCountry) {
    trackPromise(
      axios
        .get(APIbaseURL + "/country_states/" + selectedCountry)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                states: data.data
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in States List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  fetchCityByStateList(selectedState) {
    trackPromise(
      axios
        .get(APIbaseURL + "/state_cities/" + selectedState)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                cities: data.data
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in Cities List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  fetchPinByCityList(selectedCity) {
    trackPromise(
      axios
        .get(APIbaseURL + "/city_pincodes/" + selectedCity)
        .then(response => {
          const { data } = response;
          switch (data.code) {
            case 200:
              this.setState({
                pincodes: data.data
              });
              break;
            case 400:
              break;
            default:
              console.log("Default block in Pincodes List");
          }
        })
        .catch(function(error) {
          console.error("Experiences List error: ", error);
        })
    );
  }

  onChangeUserName(e) {
    this.setState({
      username: e.target.value
    });
  }

  username() {
    alert(this.state.username);
    if (!this.state.username) {
      return this.state.user.username;
    }
    return this.state.value;
  }

  onChangeAddress1(e) {
    this.setState({
      address1: e.target.value
    });
  }
  onChangeAddress2(e) {
    this.setState({
      address2: e.target.value
    });
  }
  onChangeCountry(e) {
    this.setState({
      id: e.target.value
    });
  }
  onChangeState(e) {
    this.setState({
      id: e.target.value
    });
  }
  onChangeCity(e) {
    console.log("city: e: ", e);
    this.setState({
      id: e.target.value
    });
  }
  onChangePincode(e) {
    this.setState({
      pincode: e.target.value
    });
  }

  validate = () => {
    let nameError = "";

    if (!this.state.user.username) {
      nameError = "Name is required";
    } else if (this.state.user.username.length > 50) {
      nameError = "Name exceeds 50 character";
    }

    if (nameError) {
      this.setState({
        nameError
      });
      return false;
    }
    return true;
  };

  onSubmit(e) {
    e.preventDefault();
    const isValid = this.validate();

    if (this.state.selectedState.id == 0) {
      this.stateid = this.state.user.stateid;
    } else {
      this.stateid = this.state.selectedState.id;
    }

    // if (this.state.selectedCity.id == 0) {
    //   this.cityid = this.state.user.cityid;
    // } else {
    //   this.cityid = this.state.selectedCity.id;
    // }

    if (!this.state.username) {
      this.state.username = this.state.user.username;
    } else {
      this.state.username = this.state.username;
    }

    if (!this.state.address1) {
      this.state.address1 = this.state.user.address1;
    } else {
      this.state.address1 = this.state.address1;
    }

    if (!this.state.address2) {
      this.state.address2 = this.state.user.address2;
    } else {
      this.state.address2 = this.state.address2;
    }

    if (!this.state.pincode) {
      this.state.pincode = this.state.user.pincode;
    } else {
      this.state.pincode = this.state.pincode;
    }

    if (isValid) {
      const obj = {
        id: this.userId,
        username: this.state.username,
        address1: this.state.address1,
        address2: this.state.address2,
        countryid: this.state.user.countryid,
        stateid: this.stateid, //this.state.selectedState.id
        cityid: this.state.user.cityid, //this.state.selectedCity.id
        pincode: this.state.pincode
      };
      trackPromise(
        axios
          .post(APIbaseURL + "/update_profile", JSON.stringify(obj)) //JSON.stringify({ data: obj })
          .then(response => {
            const { data } = response;
            switch (data.code) {
              case 200:
                this.setState({
                  id: data.id,
                  redirect: true
                });
                break;
              case 400:
                break;
              default:
                console.log("Default block in User details");
            }
          })
          .catch(function(error) {
            console.error("Experiences List error: ", error);
          })
      );
    }
  }

  onChangeUserid(e) {
    this.setState({
      id: e.target.value
    });
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/EditProfileThankyou" />;
    }

    // this.state.user.city_name = this.state.selectedCity.city_name;

    return (
      <React.Fragment>
        <Container>
          <Row className="equal-space">
            <Col className=" message-box" md={10} sm={12}>
              <Col className="mx-auto register-form" md={10} sm={12}>
                <h3 className="txt-orange">Edit Profile</h3>
                <Form className="regform" onSubmit={this.onSubmit}>
                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridName">
                        <Form.Control
                          defaultValue={this.state.user.username}
                          type="text"
                          placeholder="Your Name"
                          //value={this.state.user.username || ""}
                          onChange={this.onChangeUserName}
                          ref={this.state.user.username}
                        />
                        <div className="errorMsg">{this.state.nameError}</div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Control
                          type="email"
                          placeholder="Your email address"
                          value={this.state.user.email || ""}
                          //onChange={this.onChangeEmail}
                          //onBlur={this.checkEmail}
                          disabled
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>

                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridMobile">
                        <Form.Control
                          type="text"
                          placeholder="Mobile number"
                          value={this.state.user.mobile || ""}
                          //onChange={this.onChangeMobile}
                          //onBlur={this.checkMobile}
                          disabled
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridaddress1">
                        <Form.Control
                          defaultValue={this.state.user.address1}
                          type="text"
                          placeholder="Address Line 1"
                          //value={this.state.user.address1 || ""}
                          onChange={this.onChangeAddress1}
                          ref={this.state.user.address1}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridaddress2">
                        <Form.Control
                          defaultValue={this.state.user.address2}
                          type="text"
                          placeholder="Address Line 2"
                          //value={this.state.user.address2 || ""}
                          onChange={this.onChangeAddress2}
                          ref={this.state.user.address2}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <DropdownButton
                        title={this.state.user.country_name}
                        className="icon-angle-down regdrpdwn"
                        onSelect={this.countrySelected}
                        value={this.state.selectedCountry.id}
                        onChange={this.onChangeCountry}
                        ref={this.state.user.countryid}
                      >
                        {this.state.countries.map(item => (
                          <Dropdown.Item
                            key={item.id}
                            eventKey={JSON.stringify(item)}
                          >
                            {item.country_name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Form.Row>
                    <Form.Row>
                      <DropdownButton
                        title={this.state.user.state_name}
                        className="icon-angle-down regdrpdwn"
                        onSelect={this.stateSelected}
                        value={this.state.selectedState.id}
                        onChange={this.onChangeState}
                        ref={this.state.user.stateid}
                      >
                        {this.state.states.map(item => (
                          <Dropdown.Item
                            key={item.id}
                            eventKey={JSON.stringify(item)}
                          >
                            {item.state_name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Form.Row>
                  </div>
                  <div className="d-flex">
                    <Form.Row>
                      <DropdownButton
                        title={this.state.user.city_name}
                        className="icon-angle-down regdrpdwn"
                        onSelect={this.citySelected}
                        value={this.state.user.cityid}
                      >
                        {this.state.cities.map(item => (
                          <Dropdown.Item
                            key={item.id}
                            eventKey={JSON.stringify(item)}
                          >
                            {item.city_name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        controlId="formGridPincode"
                        className="pincodeinput"
                      >
                        <Form.Control
                          defaultValue={this.state.user.pincode}
                          type="text"
                          placeholder="Enter Pincode"
                          //value={this.state.user.pincode || ""}
                          onChange={this.onChangePincode}
                          maxLength="6"
                          minLength="6"
                          //disabled={this.state.disablePincode}
                          ref={this.state.user.pincode}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>

                  <Form.Row>
                    <Form.Group>
                      <Button
                        className="cmn-btn"
                        type="submit"
                        onClick={this.updateProfile}
                      >
                        Save
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default EditProfile;
